import loadable from "@loadable/component";

import { ThemeProvider } from "@mui/material/styles";
import { GlobalStyles } from "./styles";
import { createTheme } from "./theme";
import { useSettings, useStore } from "./hooks";
import { useScrollReset } from "./hooks";
import { useRoutes } from "react-router-dom";
import { getRoutes } from "./routes";
import { SnackbarProvider as LegacySnackbarProvider } from "../src/lib/snackbar";
import { Suspense, memo } from "react";

import { observer } from "mobx-react-lite";
import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import SnackbarAlert from "./utils/SnackbarAlert";
import { LoadingScreen } from "./components/utils";

const ThemeLoader = loadable(() =>
	import(
		"./components/utils/ThemeLoader" /* webpackChunkName: "themeloader" */
	)
);

const TopProgressBar = loadable(() =>
	import("./utils/TopProgressBar" /* webpackChunkName: "topprogressbar" */)
);

const getFaviconEl = () => {
	return document.getElementById("favicon");
};

const App = () => {
	const { resource, auth, tenant } = useStore();
	const filteredRoutes = getRoutes(resource, auth);
	const content = useRoutes(filteredRoutes);
	const { settings } = useSettings();
	const SnackbarAlertMemo = memo(SnackbarAlert);
	useScrollReset();

	const theme = createTheme({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		roundedCorners: settings.roundedCorners,
		theme: settings.theme,
		light: tenant.details?.lightThemeColor,
		dark: tenant.details?.darkThemeColor,
	});

	useEffect(() => {
		tenant.getDetails();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (tenant.details?.feviconLogoPath) {
			const favicon = getFaviconEl(); // Accessing favicon element
			if (favicon) {
				favicon.href = tenant.details?.feviconLogoPath;
			}
		}
	}, [tenant.details?.feviconLogoPath]);

	return (
		<ThemeProvider theme={theme}>
			{tenant.hasDetails ? (
				<Suspense fallback={<LoadingScreen />}>
					<Helmet>
						<title>{tenant.details?.tenantName}</title>
						<meta
							name='description'
							content={tenant.details?.tenantName}
						/>
						<meta
							property='og:site_name'
							content={tenant.details?.tenantName}
						/>
						<meta
							property='og:title'
							content={tenant.details?.tenantName}
						/>
						<meta
							property='og:description'
							content={tenant.details?.tenantName}
						/>
						<meta
							property='og:image'
							itemprop='image'
							content={tenant.details?.headerLogoPath}
						/>
					</Helmet>
					<SnackbarProvider
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						autoHideDuration={5000}
						Components={{
							info: SnackbarAlertMemo,
							success: SnackbarAlertMemo,
							error: SnackbarAlertMemo,
							warning: SnackbarAlertMemo,
						}}
					/>
					<TopProgressBar />
					<GlobalStyles />
					<LegacySnackbarProvider>{content}</LegacySnackbarProvider>
				</Suspense>
			) : (
				<>
					<ThemeLoader />
				</>
			)}
		</ThemeProvider>
	);
};

export default observer(App);
