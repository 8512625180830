import { flow, getEnv, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const ICDItem = types.model({
	sNo: types.number,
	icd: types.string,
	id: types.string,
});

export const ICD = types
	.model({
		items: types.array(ICDItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		return {
			afterCreate() {
				self.loadInit();
			},
			loadInit: flow(function* loadInit() {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Common/getAllICDCode?limit=${100}&searchString=${""}&patientId=${0}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json;
						self.status = "done";
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						self.status = "error";
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					self.status = "error";
				}
			}),
			load: flow(function* load(
				snackbar,
				limit,
				search,
				callback,
				patientId
			) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Common/getAllICDCode?limit=${limit}&searchString=${
							search ? search : ""
						}&patientId=${patientId ? patientId : 0}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json;
						callback();
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						callback();
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
					self.status = "error";
				}
			}),
		};
	});
