
export const CourseGlobalUploadType = {
    Preparatory: 1,
    Additional: 2,
    PostSurvey: 3
}

export const CourseFeedbackTypes = {
    Day: 0,
    Course: 1,
    CoursePrepMaterial: 2,
    CourseAdditionalMaterial: 3,
    CourseMandatoryQuestion: 4,
    Session: 5,
    SessionUpload: 6,
    SubActivity: 7,
    CE: 8,
    CE01: 9,
    CE02: 10,
    CE03: 11,
    CE04: 12,
    CE05: 13,
    CE06a: 14,
    CE06b: 15,
    CE07: 16,
    CE08: 17,
    CE09: 18,
}

export const PartialOrCompleteFeedback = {
    Partial: 1,
    Complete: 2
}

export const UploadType = {
    CourseUpload: 1,
    SessionUpload: 2,
    AdditionalQuestionUpload: 3
}

export const ChatType = {
    Feedback: 1,
    Assistance: 2
}

export const ProposalOrActivity = {
    Activity: 2,
    Proposal: 1
}


export const TenantType = {
    0 : 'Unknown',
    1 : 'Clinic',
    2 : 'Research Institute',
    3 : 'ALL',
    4 : 'ARF',
    5 : 'LMS Organization'
}