import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const PatientDiagnosisItem = types.model({
	patientId: types.maybeNull(types.number),
	sNo: types.maybeNull(types.number),
	id: types.maybeNull(types.string),
	icdCodeId: types.maybeNull(types.string),
	icdCodeSNo: types.maybeNull(types.number),
	currently: types.maybeNull(types.boolean),
	greaterThan12: types.maybeNull(types.boolean),
	lessThan12: types.maybeNull(types.boolean),
	edit: types.optional(types.boolean, false),
});

export const PatientDiagnosis = types
	.model({
		mental: types.array(PatientDiagnosisItem),
		physical: types.array(PatientDiagnosisItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		status: types.maybeNull(
			types.enumeration("status", ["pending", "done", "error"])
		),
	})
	.actions((self) => {
		return {
			loadById: flow(function* loadById(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Patient/getPatientDiagnosisByPatientId?patientId=${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 0) {
							self.mental = data.mentalDiagnosisResponse;
							self.physical = data.physicalDiagnosisResponse;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			saveMental: flow(function* saveMental(data, snackbar) {
				if (data.id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Patient/updatePatientMentalDiagnosis`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
								return { status: 400 };
							} else {
								applySnapshot(
									self.mental.find(
										(item) => item.id === data.id
									),
									json
								);

								return { status: 200, json: json };
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
							return { status: 400 };
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						return { status: 500 };
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Patient/createPatientMentalDiagnosis`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
								return { status: 400 };
							} else {
								self.mental.push(json);
								return { status: 200, json: json };
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
							return { status: 400 };
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						return { status: 500 };
					}
				}
			}),

			savePhysical: flow(function* savePhysical(data, snackbar) {
				if (data.id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Patient/updatePatientPhysicalDiagnosis`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
								return { status: 400 };
							} else {
								applySnapshot(
									self.physical.find(
										(item) => item.id === data.id
									),
									json
								);

								return { status: 200, json: json };
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
							return { status: 400 };
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						return { status: 500 };
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Patient/createPatientPhysicalDiagnosis`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
								return { status: 400 };
							} else {
								self.physical.push(json);
								return { status: 200, json: json };
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
							return { status: 400 };
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						return { status: 500 };
					}
				}
			}),

			removeMental: flow(function* removeMental(id, snackbar) {
				try {
					const item = self.mental.find((item) => item.id === id);
					const response = yield fetchApi(
						`${ROOT_URL}/api/Patient/deleteMentalDiagnosisById`,
						getRequestOptions("POST", {
							sNo: item.sNo,
							id: item.id,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(item);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
						return { status: 200 };
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
						return { status: 400 };
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					return { status: 500 };
				}
			}),
			removePhysical: flow(function* removePhysical(id, snackbar) {
				try {
					const item = self.physical.find((item) => item.id === id);
					const response = yield fetchApi(
						`${ROOT_URL}/api/Patient/deletePhysicalDiagnosisById`,
						getRequestOptions("POST", {
							sNo: item.sNo,
							id: item.id,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(item);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
						return { status: 200 };
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
						return { status: 400 };
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					return { status: 500 };
				}
			}),

			updateContentType(contentType) {
				self.contentType = contentType;
			},

			addEmptyMentalItem() {
				self.mental.push({});
			},

			addEmptyPhysicalItem() {
				self.physical.push({});
			},
		};
	});
