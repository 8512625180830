import { applySnapshot, flow, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";
import { Track } from "./Track";

const SurveyItem = types.model({
	surveyId: types.maybeNull(types.number),
	dueDate: types.maybeNull(types.string),
});

const CourseSessionItem = types.model({
	sessionId: types.maybeNull(types.number),
	sessionName: types.maybeNull(types.string),
	sessionSNo: types.maybeNull(types.string),
	scheduleDateTime: types.maybeNull(types.string),
	sessionSurvey: types.array(SurveyItem),
});

const BatchDaysScheduleItem = types.model({
	courseDaysId: types.maybeNull(types.number),
	courseDaysName: types.maybeNull(types.string),
	courseDaysSNo: types.maybeNull(types.string),
	scheduleDateTime: types.maybeNull(types.string),
	courseSession: types.array(CourseSessionItem),
});

const BatchItem = types.model({
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	batchName: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseName: types.maybeNull(types.string),
	batchDaySchedule: types.maybeNull(types.array(BatchDaysScheduleItem)),
});

const CourseAssignClinicianItem = types.model({
	id: types.maybeNull(types.number),
	sNo: types.maybeNull(types.string),
	courseName: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	isMultiDay: types.maybeNull(types.number),
	noOfDays: types.maybeNull(types.number),
	isCECourse: types.maybeNull(types.boolean),
	isProposal: types.maybeNull(types.boolean),
	isWebCast: types.maybeNull(types.boolean),
	courseStatus: types.maybeNull(types.number),
	purchasedCourseBatch: types.array(BatchItem),
	quantity: types.maybeNull(types.number),
});

const CoursePreviewInstructor = types.model({
	instructorId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	instructorName: types.maybeNull(types.string),
	instructorCV: types.maybeNull(types.string),
	instructorRating: types.maybeNull(types.number),
	instructorReview: types.maybeNull(types.number),
	students: types.maybeNull(types.number),
	courses: types.maybeNull(types.number),
});

const CoursePreviewMainImage = types.model({
	activityPreviewId: types.maybeNull(types.number),
	activityPreviewImageContentType: types.maybeNull(types.string),
	activityPreviewImageFile: types.maybeNull(types.string),
	activityPreviewImageUrl: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const CourseAssignPatientItem = types.model({
	id: types.maybeNull(types.number),
	sNo: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseName: types.maybeNull(types.string),
	courseTitle1: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	isMultiDay: types.maybeNull(types.number),
	noOfDays: types.maybeNull(types.number),
	isCECourse: types.maybeNull(types.boolean),
	isProposal: types.maybeNull(types.boolean),
	isWebCast: types.maybeNull(types.boolean),
	courseStatus: types.maybeNull(types.number),
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	batchName: types.maybeNull(types.string),
	batchDaySchedule: types.maybeNull(types.array(BatchDaysScheduleItem)),
	coursePreviewInstructor: types.maybeNull(
		types.array(CoursePreviewInstructor)
	),
	coursePreviewMainImage: types.maybeNull(CoursePreviewMainImage),
});

const CourseAssignClinicItem = types.model({
	id: types.maybeNull(types.number),
	sNo: types.maybeNull(types.string),
	clinicianId: types.array(types.number),
	courseName: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	isMultiDay: types.maybeNull(types.number),
	noOfDays: types.maybeNull(types.number),
	isCECourse: types.maybeNull(types.boolean),
	isProposal: types.maybeNull(types.boolean),
	isWebCast: types.maybeNull(types.boolean),
	courseStatus: types.maybeNull(types.number),
	purchasedCourseBatch: types.array(BatchItem),
	quantity: types.maybeNull(types.number),
});

const CourseAssignPresenterItem = types.model({
	id: types.maybeNull(types.number),
	sNo: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseName: types.maybeNull(types.string),
	courseTitle1: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	isMultiDay: types.maybeNull(types.number),
	noOfDays: types.maybeNull(types.number),
	isCECourse: types.maybeNull(types.boolean),
	isProposal: types.maybeNull(types.boolean),
	isWebCast: types.maybeNull(types.boolean),
	courseStatus: types.maybeNull(types.number),
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	batchName: types.maybeNull(types.string),
	batchDaySchedule: types.maybeNull(types.array(BatchDaysScheduleItem)),
	coursePreviewInstructor: types.maybeNull(
		types.array(CoursePreviewInstructor)
	),
	coursePreviewMainImage: types.maybeNull(CoursePreviewMainImage),
});
const TrackPreviewMainImage = types.model({
	id: types.maybeNull(types.number),
	sNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewImageContentType: types.maybeNull(types.string),
	trackPreviewImageFile: types.maybeNull(types.string),
	trackPreviewImageUrl: types.maybeNull(types.string),
	trackPreviewSNo: types.maybeNull(types.string)
});
const TrackAssignForUser = types.model({
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	trackName: types.maybeNull(types.string),
	trackDescription: types.maybeNull(types.string),
	trackType: types.maybeNull(types.number),
	isCETrack: types.maybeNull(types.boolean),
	isPurchased: types.maybeNull(types.boolean),
	trackTitle1: types.maybeNull(types.string),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewMainImage: types.maybeNull(TrackPreviewMainImage),
})
export const CourseAssign = types
	.model({
		items: types.array(CourseAssignClinicItem),
		clinicianItems: types.array(CourseAssignClinicianItem),
		patientItems: types.array(CourseAssignPatientItem),
		userItems: types.array(CourseAssignPatientItem),
		hostItems: types.array(CourseAssignPatientItem),
		assignTrackForUser:types.array(TrackAssignForUser),
		assignTrackForPatient:types.array(TrackAssignForUser),
		presenterItems: types.array(CourseAssignPresenterItem),
		presenterItemsForTrack: types.array(TrackAssignForUser),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.id === self.currentId
					);
				} else {
					return null;
				}
			},
			item(id) {
				if (id) {
					return self.items.find((item) => item.id === id);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getPurchasedCourseByClinicAdmin`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.items, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),

			loadCoursesForClinicians: flow(function* loadCoursesForClinicians(
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getPurchasedCourseForClinician`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.clinicianItems, json);
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			loadCoursesForPatients: flow(function* loadCoursesForPatients(
				id,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAssignedCourseToPatient?patientId=${id}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.patientItems = json;
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadTracksForPatient: flow(function* loadTracksForPatient(
				id,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAssignedTrackToPatient?patientId=${id}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.assignTrackForPatient = json;
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadCoursesForUsers: flow(function* loadCoursesForUsers(
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCourseForUser`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.userItems = json;
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadTracksForUser: flow(function* loadTracksForUser(
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getTrackForUser`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.assignTrackForUser = json;
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadAllGraphsForDashboard: flow(function* loadAllGraphsForDashboard(
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getPatientSurveyGraphForDashboardByPatientId`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.patientSurveyGraphData = json?.patientSurveyGraphData;
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadAllGraphsForCourseDashboard: flow(function* loadAllGraphsForCourseDashboard(
				trackId,
				trackSNo,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getPatientSurveyGraphForCourseDashboard?trackId=${trackId}&trackSNo=${trackSNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.patientSurveyGraphData = json?.patientSurveyGraphData;
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			getCourseForHost: flow(function* getCourseForHost(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCourseForHost`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.hostItems = json;
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			getCourseForPresenter: flow(function* getCourseForPresenter(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCourseForPresenter`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.presenterItems = json;
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			getTrackForPresenter: flow(function* getTrackForPresenter(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getTrackForPresenter`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.presenterItemsForTrack = json;
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			assignCourseToClinicianByClinicAdmin: flow(
				function* assignCourseToClinicianByClinicAdmin(
					id,
					sNo,
					clinicianId,
					quantity,
					snackbar
				) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/assignCourseToClinicianByClinicAdmin`,
							getRequestOptions("POST", {
								id: id,
								sNo: sNo,
								clinicianId: clinicianId,
								quantity: quantity,
							})
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							applySnapshot(
								self.items.find((item) => item.id === id)
									.clinicianId,
								[
									...self.items.find((item) => item.id === id)
										.clinicianId,
									clinicianId,
								]
							);
							snackbar.showMessage(
								"Course Assigned!!",
								null,
								null,
								{
									severity: "success",
								}
							);
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),

			unassignCourseToClinicianByClinicAdmin: flow(
				function* unassignCourseToClinicianByClinicAdmin(
					id,
					sNo,
					clinicianId,
					snackbar
				) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/unassignCourseToClinicianByClinicAdmin`,
							getRequestOptions("POST", {
								id: id,
								sNo: sNo,
								clinicianId: clinicianId,
							})
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							applySnapshot(
								self.items.find((item) => item.id === id)
									.clinicianId,
								[
									...self.items
										.find((item) => item.id === id)
										.clinicianId.filter(
											(item) => item !== clinicianId
										),
								]
							);
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),

			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
		};
	});
