import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { blobToBase64, jsonToFormData } from "../../helpers";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const LicenseItem = types.model({
	id: types.maybeNull(types.number),
	userId: types.maybeNull(types.number),
	professionalClassificationId: types.maybeNull(types.number),
	professionalClassification: types.maybeNull(types.string),
	otherProfessionalClassification: types.maybeNull(types.string),
	firstProfessionalClassificationId: types.maybeNull(types.number),
	firstProfessionalClassification: types.maybeNull(types.string),
	firstOtherProfessionalClassification: types.maybeNull(types.string),
	secondProfessionalClassificationId: types.maybeNull(types.number),
	secondProfessionalClassification: types.maybeNull(types.string),
	secondOtherProfessionalClassification: types.maybeNull(types.string),
	licenseNumber: types.maybeNull(types.string),
	dateOfLicense: types.maybeNull(types.string),
});

const CertificationItem = types.model({
	userId: types.maybeNull(types.number),
	id: types.maybeNull(types.number),
	certificationId: types.maybeNull(types.number),
	certification: types.maybeNull(types.string),
	certified: types.maybeNull(types.boolean),
	dateOfCertification: types.maybeNull(types.string),
	training: types.maybeNull(types.boolean),
	dateOfTraining: types.maybeNull(types.string),
});

const DegreeItem = types.model({
	id: types.maybeNull(types.number),
	degreeId: types.maybeNull(types.number),
	institution: types.maybeNull(types.string),
	areaOfStudy: types.maybeNull(types.string),
	yearAwarded: types.maybeNull(types.string),
	otherDegree: types.maybeNull(types.string),
	userId: types.maybeNull(types.number),
});

const GeneralItem = types.model({
	dob: types.maybeNull(types.string),
	firstName: types.maybeNull(types.string),
	id: types.maybeNull(types.string),
	lastName: types.maybeNull(types.string),
	middleName: types.maybeNull(types.string),
	notification: types.maybeNull(types.number),
	phone: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	prefix: types.maybeNull(types.string),
	sNo: types.maybeNull(types.number),
	userId: types.maybeNull(types.number),
	tenantId: types.maybeNull(types.number),
	clinicId: types.maybeNull(types.number),
});

const ProfileTabs = types.model({
	id: types.maybeNull(types.number),
	tabName: types.maybeNull(types.string),
});

const BiographyItem = types.model({
	biography: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
	userId: types.maybeNull(types.number),
});

const CVItem = types.model({
	userId: types.maybeNull(types.number),
	cvContentType: types.maybeNull(types.string),
	cvFileName: types.maybeNull(types.string),
	cvUrl: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const DemographicsItem = types.model({
	patientId: types.maybeNull(types.number),
	biologicalSexSNo: types.maybeNull(types.number),
	biologicalSexId: types.maybeNull(types.string),
	genderSNo: types.maybeNull(types.number),
	genderId: types.maybeNull(types.string),
	relationshipStatusSNo: types.maybeNull(types.number),
	relationshipStatusId: types.maybeNull(types.string),
	occupationalHistorySNo: types.maybeNull(types.number),
	occupationalHistoryId: types.maybeNull(types.string),
	employeeDuration: types.maybeNull(types.string),
	raceSNo: types.maybeNull(types.number),
	raceId: types.maybeNull(types.string),
	raceOther: types.maybeNull(types.string),
	livingSituationSNo: types.maybeNull(types.number),
	livingSituationId: types.maybeNull(types.string),
	livingSituationOther: types.maybeNull(types.string),
	inSchool: types.maybeNull(types.boolean),
	educationHistorySNo: types.maybeNull(types.number),
	educationHistoryId: types.maybeNull(types.string),
	educationHistoryHighestLevelSNo: types.maybeNull(types.number),
	educationHistoryHighestLevelId: types.maybeNull(types.string),
	inArmedForce: types.maybeNull(types.boolean),
	militaryBranchId: types.maybeNull(types.number),
	militaryHistoryLength: types.maybeNull(types.string),
	militaryHistoryDateEnlisted: types.maybeNull(types.string),
	currentMilitaryStatusId: types.maybeNull(types.number),
	militaryDischargeId: types.maybeNull(types.number),
});

const AddressItem = types.model({
	address1: types.maybeNull(types.string),
	address2: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	countryId: types.maybeNull(types.number),
	stateId: types.maybeNull(types.number),
	userId: types.maybeNull(types.number),
	zip: types.maybeNull(types.string),
});

const BillingAddressItem = types.model({
	billingAddressID: types.maybeNull(types.number),
	addressLine1: types.maybeNull(types.string),
	addressLine2: types.maybeNull(types.string),
	state: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	postalCode: types.maybeNull(types.string),
	country: types.maybeNull(types.string),
});

const CardDettailsItem = types.model({
	bin: types.maybeNull(types.string),
	cardID: types.maybeNull(types.string),
	cardholderName: types.maybeNull(types.string),
	expMonth: types.maybeNull(types.number),
	expYear: types.maybeNull(types.number),
	last4: types.maybeNull(types.string),
});

export const Profile = types
	.model({
		general: types.maybeNull(GeneralItem),
		licenses: types.array(LicenseItem),
		certifications: types.array(CertificationItem),
		degrees: types.array(DegreeItem),
		headshot: types.maybeNull(types.string),
		bio: types.maybeNull(BiographyItem),
		address: types.maybeNull(AddressItem),
		billingAddress: types.array(BillingAddressItem),
		tabs: types.array(ProfileTabs),
		cv: types.maybeNull(CVItem),
		demographics: types.maybeNull(DemographicsItem),
		cardDetails: types.array(CardDettailsItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			get totalNewMesagges() {
				return self.items.filter((item) => !item.isRead).length;
			},
			get newMessages() {
				return self.items.filter((item) => !item.isRead);
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getUserProfile`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.general = data;
						self.status = "done";
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadProfileTabs: flow(function* loadProfileTabs(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/user/getProfileTab`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.tabs = data;
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadLicenses: flow(function* loadLicenses(userId, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getLicenseByUserId?id=${userId}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.licenses = data;
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadCertification: flow(function* loadCertification(
				userId,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getCertificationByUserId?id=${userId}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.certifications = data;
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadDegree: flow(function* loadDegree(userId, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getDegreeByUserId?id=${userId}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.degrees = data;
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadBiography: flow(function* loadBiography(userId, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getBiographyById?id=${userId}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 0) {
							self.bio = data;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadAddress: flow(function* loadAddress(userId, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getAddressByUserId?id=${userId}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 0) {
							self.address = data;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage("API_ERROR_MESSAGE", null, null, {
						severity: "error",
					});
				}
			}),
			loadBillingAddress: flow(function* loadBillingAddress(
				userId,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/user/billingaddress?userID=${userId}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.billingAddress = data;
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadDemographics: flow(function* loadDemographics(
				userId,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getPatientDemographicById?id=${userId}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 0) {
							self.demographics = data;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadCV: flow(function* loadCV(userId, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getCVById?userId=${userId}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 0) {
							self.cv = data;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			save: flow(function* save(data, snackbar, callback) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/updateUserProfile`,
						getRequestOptions("POST", { ...self.general, ...data })
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						} else {
							self.general = json;
							snackbar.showMessage(
								"Profile Data Updated Successfully!!",
								null,
								null,
								{
									severity: "success",
								}
							);
							callback(json);
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			acknowledge: flow(function* acknowledge(data, snackbar, callback) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/account/acknowledgeLegalDocument`,
						getRequestOptions("POST", data)
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						} else {
							callback(json);
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			saveAddress: flow(function* saveAddress(data, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/updateUserAddress`,
						getRequestOptions("POST", { ...self.address, ...data })
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						} else {
							self.address = json;
							snackbar.showMessage(
								"Profile Address Updated Successfully!!",
								null,
								null,
								{
									severity: "success",
								}
							);
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			saveDemographics: flow(function* saveDemographics(data, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/updatePatientDemographic`,
						getRequestOptions("POST", {
							...self.demographics,
							...data,
						})
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						} else {
							self.demographics = json;
							snackbar.showMessage(
								"Profile Demographics Updated Successfully!!",
								null,
								null,
								{
									severity: "success",
								}
							);
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			uploadCV: flow(function* uploadCV(data, snackbar, callback) {
				try {
					const fd = new FormData();
					fd.append("fileUpload.formFile", data);
					fd.append("userId", self.general.userId);

					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/addUserCV`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.cv = json;
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),

			saveLicense: flow(function* saveLicense(data, snackbar) {
				if (data.id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/UserProfile/updateUserLicense`,
							getRequestOptions("POST", {
								...data,
								userId: data.userId ? data.userId : self.general.userId,
							})
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.licenses.find(
										(license) => license.id === data.id
									),
									json
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/UserProfile/addUserLicense`,
							getRequestOptions("POST", {
								...data,
								userId: data.userId ? data.userId : self.general.userId,
							})
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.licenses.push(json);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveCertification: flow(function* saveCertification(
				data,
				snackbar
			) {
				if (data.id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/UserProfile/updateUserCertification`,
							getRequestOptions("POST", {
								...data,
								userId: self.general.userId,
							})
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.certifications.find(
										(cert) => cert.id === data.id
									),
									json
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/UserProfile/addUserCertification`,
							getRequestOptions("POST", {
								...data,
								userId: self.general.userId,
							})
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.certifications.push(json);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveDegree: flow(function* saveDegree(data, snackbar) {
				if (data.id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/UserProfile/updateUserDegree`,
							getRequestOptions("POST", {
								...data,
								userId: self.general.userId,
							})
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.degrees.find(
										(degree) => degree.id === data.id
									),
									json
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/UserProfile/addUserDegree`,
							getRequestOptions("POST", {
								...data,
								userId: self.general.userId,
							})
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.degrees.push(json);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveBiography: flow(function* saveBiography(data, snackbar) {
				if (self.bio?.id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/UserProfile/updateUserBiography`,
							getRequestOptions("POST", {
								...self.bio,
								...data,
								userId: self.general.userId,
							})
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(self.bio, json);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/UserProfile/addUserBiography`,
							getRequestOptions("POST", {
								...data,
								userId: self.general.userId,
							})
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.bio = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			addHeadshot: flow(function* addHeadshot(data, snackbar, callback) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/addUserHeadshot`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
							callback();
						} else {
							self.loadHeadshot(snackbar);
							snackbar.showMessage(
								"Headshot Successfully Uploaded!!",
								null,
								null,
								{ severity: "success" }
							);
							callback();
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
						callback();
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),
			loadHeadshot: flow(function* loadHeadshot(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getHeadShotStreamLoginUser`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						const base64 = yield blobToBase64(blob);
						self.headshot = base64;
					} else {
						self.headshot = null;
					}
				} catch (error) {
					self.headshot = null;
				}
			}),

			removeHeadshot: flow(function* removeHeadshot(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/deleteUserHeadShotLoginUserId`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							self.headshot = null;
						}
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeCV: flow(function* removeCV(cv, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/deleteUserCVById?id=${cv.id}`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							destroy(cv);
						}
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeLicense: flow(function* removeLicense(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/deleteUserLicenseById?id=${id}`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							destroy(
								self.licenses.find(
									(license) => license.id === id
								)
							);
						}
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeCertification: flow(function* removeCertification(
				id,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/deleteUserCertificationById?id=${id}`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							destroy(
								self.certifications.find(
									(cert) => cert.id === id
								)
							);
						}
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeDegree: flow(function* removeDegree(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/deleteUserDegreeById?id=${id}`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							destroy(
								self.degrees.find((degree) => degree.id === id)
							);
						}
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadSaveCardDetails: flow(function* loadSaveCardDetails(
				userId,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/cart/checkout/cards?userID=${userId}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.cardDetails = data || [];
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			disableCardDetails: flow(function* disableCardDetails(
				cardID,
				snackbar
			) {
				try {
					self.disableCardStatus = "pending";
					const response = yield fetchApi(
						`${ROOT_URL}/api/square/disablecustomercard?cardID=${cardID}`,
						getRequestOptions("POST", null)
					);
					const data = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						!data?.error
					) {
						self.disableCardStatus = "done";
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
						self.disableCardStatus = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.disableCardStatus = "error";
				}
			}),
		};
	});
