import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { CONTENT_TYPE, ROOT_URL, API_ERROR_MESSAGE } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const LeadResearcherItem = types.model({
	sNo: types.identifierNumber,
	id: types.maybeNull(types.string),
	researcherSNo: types.maybeNull(types.number),
	researcherId: types.maybeNull(types.string),
	name: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	researchInstitutionSNo: types.maybeNull(types.number),
	researchInstitutionId: types.maybeNull(types.string),
	isPrimary: types.maybeNull(types.boolean),
});

export const LeadResearcher = types
	.model({
		items: types.array(LeadResearcherItem),
		current: types.maybeNull(
			types.reference(types.late(() => LeadResearcherItem))
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
	})
	.actions((self) => {
		return {
			loadLeadResearcherByInstitutionId: flow(
				function* loadLeadResearcherByInstitutionId(sNo, id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/getLeadResearcherByInstitutionId?SNo=${sNo}&Id=${id}`,
							getRequestOptions("GET", null)
						);
						self.items = yield response.json();
					} catch (error) {
						console.error(
							"Failed to fetchApi lead researchers",
							error
						);
					}
				}
			),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(
				id,
				researchInstitutionSNo,
				researchInstitutionId
			) {
				if (id) {
					self.current = self.items.find((item) => item.sNo === id);
				} else {
					if (self.items.find((item) => item.sNo === -1)) {
						self.current = self.items.find(
							(item) => item.sNo === -1
						);
					} else {
						self.items.push({
							sNo: -1,
							researchInstitutionSNo: researchInstitutionSNo,
							researchInstitutionId: researchInstitutionId,
						});
						self.current = self.items.find(
							(item) => item.sNo === -1
						);
					}
				}
			},
			updateLeadResearcherListItem(data) {
				applySnapshot(
					self.items.find((item) => item.sNo === data.sNo),
					data
				);
			},
			addLeadResearcherListItem(data) {
				self.items.push(data);
			},
			save: flow(function* save(data, snackbar, closeDialog) {
				if (self.current?.sNo !== -1) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/updateLeadResearcher`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							if (json.status !== 400) {
								self.updateLeadResearcherListItem(json);
								self.loadLeadResearcherByInstitutionId(
									self.current.researchInstitutionSNo,
									self.current.researchInstitutionId
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);

								self.updateContentType(CONTENT_TYPE.LIST);
								closeDialog();
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/addLeadResearcher`,
							getRequestOptions("POST", {
								...data,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 400) {
								self.addLeadResearcherListItem(json);
								self.loadLeadResearcherByInstitutionId(
									self.current.researchInstitutionSNo,
									self.current.researchInstitutionId
								);
								snackbar.showMessage(
									"Record Successfully Created!!",
									null,
									null,
									{ severity: "success" }
								);
								self.updateContentType(CONTENT_TYPE.LIST);
								closeDialog();
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			remove: flow(function* remove(id, snackbar) {
				const item = self.items.find((item) => item.sNo === id);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ResearchInstitution/deleteLeadResearcherById`,
						getRequestOptions("POST", {
							id: item.id,
							sNo: item.sNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 400) {
							self.deleteLeadResearcher(json.sNo);
							snackbar.showMessage(
								"Record Successfully Deleted!!",
								null,
								null,
								{
									severity: "success",
								}
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			deleteLeadResearcher(id) {
				self.current = null;
				destroy(self.items.find((item) => item.sNo === id));
			},
		};
	});
