import { applySnapshot, flow, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const SubscriptionItems = types.model({
	appSubscriptionPlanID: types.number,
	amount: types.maybeNull(types.number),
	frequency: types.maybeNull(types.number),
	subscriptionName: types.maybeNull(types.string),
	appSubscriptionID: types.number,
	categoryName: types.string,
	clientType: types.maybeNull(types.number),
	isSkipTrial: types.maybeNull(types.boolean),
	isExcluded: types.maybeNull(types.boolean),
	clientID: types.maybeNull(types.number),
	duration: types.maybeNull(types.number),
	trialDuration: types.maybeNull(types.number),
	trialFrequency: types.maybeNull(types.number),
});

const SubscriptionCategories = types.model({
	appSubscriptionID: types.number,
	subscriptionName: types.string,
});

export const Subscription = types
	.model({
		status: types.optional(types.string, "done"),
		items: types.maybeNull(types.array(SubscriptionItems)),
		categories: types.maybeNull(types.array(SubscriptionCategories)),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		current: types.maybeNull(
			types.reference(types.late(() => SubscriptionItems))
		),
	})
	.actions((self) => {
		return {
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				if (id) {
					self.current = self.items.find(
						(item) => item.appSubscriptionID === id
					);
				} else {
					self.current = null;
				}
			},
			getSubscriptions: flow(function* getSubscriptions(
				clientType,
				snackbar,
				callback = () => {}
			) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/getSubscriptions?clientType=${
							clientType || 0
						}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						const category = [];
						const items = [];
						json?.forEach((currentCategory) => {
							category.push({
								appSubscriptionID:
									currentCategory?.appSubscriptionID,
								subscriptionName:
									currentCategory?.subscriptionName,
							});

							const subscriptions =
								currentCategory?.appSubscriptionPlans?.map(
									(currentSubscription) => ({
										appSubscriptionID:
											currentCategory?.appSubscriptionID,
										categoryName:
											currentCategory?.subscriptionName,
										appSubscriptionPlanID:
											currentSubscription?.appSubscriptionPlanID,
										amount: currentSubscription
											?.subscriptionPhases?.[
											currentSubscription
												?.subscriptionPhases?.length ==
											1
												? 0
												: 1
										]?.amount,
										frequency:
											currentSubscription
												?.subscriptionPhases?.[
												currentSubscription
													?.subscriptionPhases
													?.length == 1
													? 0
													: 1
											]?.cadence,
										subscriptionName:
											currentSubscription?.subscriptionName,
										duration:
											currentSubscription
												?.subscriptionPhases?.[
												currentSubscription
													?.subscriptionPhases
													?.length == 1
													? 0
													: 1
											]?.periods,
										clientType:
											currentSubscription?.clientType ||
											null,
										isSkipTrial:
											currentSubscription?.isSkipTrial ||
											false,
										isExcluded:
											currentSubscription?.isExcluded ||
											false,
										clientID:
											currentSubscription?.clientID ||
											null,
										trialDuration:
											currentSubscription
												?.subscriptionPhases?.[0]
												?.periods,
										trialFrequency:
											currentSubscription
												?.subscriptionPhases?.[0]
												?.cadence,
									})
								);
							items?.push(...subscriptions);
						});
						self.items = items || [];
						self.categories = category || [];
						self.status = "done";
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			addSubscription: flow(function* addSubscription(
				data,
				snackbar,
				callback = () => {}
			) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/addAppSubscriptionPlan`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						// self.items= json || [];
						self.status = "done";
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			updateClientSubscriptions: flow(function* updateClientSubscriptions(
				data,
				snackbar,
				callback = () => {}
			) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/updateClientSubscriptions`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.status = "done";
						const itemIndex = self.items.findIndex(
							(item) =>
								item.appSubscriptionPlanID ===
								data.appSubscriptionPlanID
						);
						if (itemIndex > -1)
							self.items[itemIndex].isExcluded = data?.isExcluded;
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					console.log("error", error);
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
		};
	});
