import { types, flow, applySnapshot, destroy, getEnv } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { blobToBase64, jsonToFormData } from "../../helpers";
import { getRequestOptions } from "../../utils/RequestOptions";
import FileSaver from "file-saver";
import { fetchApi } from "../../utils/CustomFetch";

const CoursePreviewLearning = types.model({
	learningId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	learning: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewRequirement = types.model({
	requirementId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	requirement: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewOutline = types.model({
	courseOutlineId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseOutline: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewContinuingEducation = types.model({
	continuingEducationId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	continuingEducation: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewCourseObjective = types.model({
	courseObjectiveId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseObjective: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewTargetAudience = types.model({
	targetAudienceId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	targetAudience: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewAdditionalInformation = types.model({
	additionalInformationId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	additionalInformation: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewCourseMaterial = types.model({
	courseMaterialId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseMaterial: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewInstructor = types.model({
	instructorId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	instructorName: types.maybeNull(types.string),
	instructorCV: types.maybeNull(types.string),
	instructorRating: types.maybeNull(types.number),
	instructorReview: types.maybeNull(types.number),
	students: types.maybeNull(types.number),
	courses: types.maybeNull(types.number),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewSchedule = types.model({
	courseMaterialId: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	scheduleTime: types.maybeNull(types.string),
	scheduleDate: types.maybeNull(types.string),
	scheduleId: types.maybeNull(types.number),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewMainImage = types.model({
	activityPreviewId: types.maybeNull(types.number),
	activityPreviewImageContentType: types.maybeNull(types.string),
	activityPreviewImageFile: types.maybeNull(types.string),
	activityPreviewImageUrl: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const CoursePreviewVideo = types.model({
	activityPreviewId: types.maybeNull(types.number),
	activityPreviewVideoContentType: types.maybeNull(types.string),
	activityPreviewVideoLink: types.maybeNull(types.string),
	isLocationS3: types.maybeNull(types.boolean),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const CoursePreviewBrochure = types.model({
	activityPreviewId: types.maybeNull(types.number),
	brochureContentType: types.maybeNull(types.string),
	brochureFile: types.maybeNull(types.string),
	brochureURL: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	brochureId: types.maybeNull(types.number),
});

const BatchDayScheduleItem = types.model({
	courseDaysId: types.maybeNull(types.number),
	courseDaysSNo: types.maybeNull(types.string),
	courseDaysName: types.maybeNull(types.string),
	scheduleDateTime: types.maybeNull(types.string),
	scheduleTime: types.maybeNull(types.string),
});

const CourseScheduleItem = types.model({
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	batchName: types.maybeNull(types.string),
	isZoomEnabled: types.maybeNull(types.boolean),
	batchDaySchedule: types.array(BatchDayScheduleItem),
});

const CoursePreviewPresenter = types.model({
	presenterId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	prefix: types.maybeNull(types.string),
	firstName: types.maybeNull(types.string),
	middleName: types.maybeNull(types.string),
	lastName: types.maybeNull(types.string),
	biography: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const CoursePreviewDetails = types.model({
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	courseDescription: types.maybeNull(types.string),
	zoomHostEmail: types.maybeNull(types.string),
	previewTemplateId: types.maybeNull(types.number),
	imageTitle1: types.maybeNull(types.string),
	imageTitle2: types.maybeNull(types.string),
	imageTitle3: types.maybeNull(types.string),
	courseTitle1: types.maybeNull(types.string),
	courseTitle2: types.maybeNull(types.string),
	price: types.maybeNull(types.number),
	discountedPrice: types.maybeNull(types.number),
	language: types.maybeNull(types.number),
	skillLevel: types.maybeNull(types.number),
	registrationDeadline: types.maybeNull(types.string),
	isDisplayOnLandingPage: types.maybeNull(types.boolean),
	isDisplayOnMarketPlace: types.maybeNull(types.boolean),
	certificate: types.maybeNull(types.boolean),
	coursePreviewLearning: types.maybeNull(types.array(CoursePreviewLearning)),
	coursePreviewMainImage: types.maybeNull(CoursePreviewMainImage),
	coursePreviewVideo: types.maybeNull(CoursePreviewVideo),
	coursePreviewRequirement: types.maybeNull(
		types.array(CoursePreviewRequirement)
	),
	coursePreviewOutline: types.maybeNull(types.array(CoursePreviewOutline)),
	coursePreviewSchedule: types.maybeNull(types.array(CoursePreviewSchedule)),
	courseSchedule: types.maybeNull(types.array(CourseScheduleItem)),
	coursePreviewContinuingEducation: types.maybeNull(
		types.array(CoursePreviewContinuingEducation)
	),
	coursePreviewCourseObjective: types.maybeNull(
		types.array(CoursePreviewCourseObjective)
	),
	coursePreviewTargetAudience: types.maybeNull(
		types.array(CoursePreviewTargetAudience)
	),

	coursePreviewAdditionalInformation: types.maybeNull(
		types.array(CoursePreviewAdditionalInformation)
	),

	coursePreviewCourseMaterial: types.maybeNull(
		types.array(CoursePreviewCourseMaterial)
	),

	coursePreviewBrochure: types.maybeNull(CoursePreviewBrochure),

	coursePreviewInstructor: types.maybeNull(
		types.array(CoursePreviewInstructor)
	),

	coursePreviewPresenter: types.maybeNull(
		types.array(CoursePreviewPresenter)
	),

	tags: types.maybeNull(types.array(types.string)),
});

const PresenterHeadshotItem = types.model({
	presenterId: types.number,
	headshot: types.string,
});

export const CoursePreview = types
	.model({
		details: types.maybeNull(CoursePreviewDetails),
		presenterHeadshots: types.array(PresenterHeadshotItem),
	})
	.actions((self) => {
		return {
			load: flow(function* load(course, snackbar, callback) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Common/getCoursePreviewById?courseId=${course?.id}&courseSNo=${course?.sNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.details = json;
						if (json.coursePreviewPresenter?.length > 0) {
							json.coursePreviewPresenter.forEach((presenter) => {
								self.loadHeadshot(presenter);
							})
						}
						if (callback) {
							callback();
						}
						self.status = "done";
					} else {
						// snackbar.showMessage(json?.error, null, null, {
						// 	severity: "error",
						// });
						self.status = "error";
					}
				} catch (error) {
					// snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					// 	severity: "error",
					// });
					self.status = "error";
				}
			}),
			getCoursePreviewImageById: flow(function* getCoursePreviewImageById(
				snackbar,
				callback
			) {
				if (
					self.details?.activityPreviewId &&
					self.details?.coursePreviewMainImage?.id
				) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/getCoursePreviewImageByURL?activityPreviewImageUrl=${self.details?.coursePreviewMainImage?.activityPreviewImageUrl}&activityPreviewImageContentType=${self.details?.coursePreviewMainImage?.activityPreviewImageContentType}`,
							getRequestOptions("GET", null)
						);
						const blob = yield response.blob();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							var blob1 = new Blob([blob], { type: self.details?.coursePreviewMainImage?.activityPreviewImageContentType });
							var blobURL = URL.createObjectURL(blob1);
							callback(blobURL);
							//const base64 = yield blobToBase64(blob);
							//callback(base64);
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			uploadImage: flow(function* uploadImage(
				data,
				course,
				snackbar,
				callback
			) {
				if (self.details?.activityPreviewId) {
					try {
						const fd = new FormData();
						fd.append("fileUpload.formFile", data);
						fd.append(
							"activityPreviewId",
							self.details?.activityPreviewId
						);
						fd.append("CourseId", course.id);
						fd.append("CourseSNo", course.sNo);

						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewMainImage`,
							getRequestOptions("POST", fd, true)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.details.coursePreviewMainImage = json;
							getEnv(self).notifier.enqueue(
								"File Uploaded",
								"success"
							);
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				} else {
					try {
						const fd = new FormData();
						fd.append("fileUpload.formFile", data);
						fd.append("CourseId", course.id);
						fd.append("CourseSNo", course.sNo);

						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewMainImageWithoutId`,
							getRequestOptions("POST", fd, true)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.details = {
								activityPreviewId: json.activityPreviewId,
								coursePreviewMainImage: json,
							};
							getEnv(self).notifier.enqueue(
								"File Uploaded",
								"success"
							);
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				}
			}),

			removeImage: flow(function* removeImage(
				coursePreviewMainImage,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteCoursePreviewImage`,
						getRequestOptions("POST", coursePreviewMainImage)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							destroy(coursePreviewMainImage);
							getEnv(self).notifier.enqueue(
								"File Deleted",
								"success"
							);
						}
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			uploadVideo: flow(function* uploadVideo(
				data,
				file,
				course,
				snackbar,
				callback
			) {
				if (data?.isLocationS3) {
					try {
						const fd = new FormData();
						fd.append("fileUpload.formFile", file);
						fd.append(
							"activityPreviewId",
							self.details?.activityPreviewId
						);
						fd.append("CourseId", course.id);
						fd.append("CourseSNo", course.sNo);
						fd.append("IsLocationS3", true);

						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewVideo`,
							getRequestOptions("POST", fd, true)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.details = {
								...self.details,
								coursePreviewVideo: json,
							};
							getEnv(self).notifier.enqueue(
								"File Uploaded",
								"success"
							);
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				} else {
					try {
						const fd = new FormData();
						fd.append("VideoLink", data?.videoLink);
						fd.append("CourseId", course.id);
						fd.append("CourseSNo", course.sNo);
						fd.append("IsLocationS3", false);
						fd.append(
							"activityPreviewId",
							self.details?.activityPreviewId
						);

						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewVideo`,
							getRequestOptions("POST", fd, true)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.details = {
								...self.details,
								coursePreviewVideo: json,
							};
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				}
			}),

			removeVideo: flow(function* removeVideo(
				coursePreviewVideo,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteCoursePreviewVideo`,
						getRequestOptions("POST", coursePreviewVideo)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							destroy(coursePreviewVideo);
							getEnv(self).notifier.enqueue(
								"File Deleted",
								"success"
							);
						}
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			uploadBrochure: flow(function* uploadBrochure(
				file,
				course,
				callback
			) {
				try {
					const fd = new FormData();
					fd.append("fileUpload.FormFile", file);
					fd.append(
						"ActivityPreviewId",
						self.details?.activityPreviewId
					);
					fd.append("CourseId", course.id);
					fd.append("CourseSNo", course.sNo);

					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addBrochureUpload`,
						getRequestOptions("POST", fd, true)
					);

					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.details = {
							...self.details,
							coursePreviewBrochure: json,
						};
						getEnv(self).notifier.enqueue(
							"File Uploaded",
							"success"
						);
						callback();
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),

			removeBrochure: flow(function* removeBrochure(brochure) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteBrochure`,
						getRequestOptions("POST", brochure)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							destroy(brochure);
							getEnv(self).notifier.enqueue(
								"File Deleted",
								"success"
							);
						}
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			getFileAsDownload: flow(function* getFileAsDownload(
				course,
				callback,
				fileURL,
				fileContentType
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getBrochureAsDownload?fileURL=${fileURL}&fileContentType=${fileContentType}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						FileSaver.saveAs(blob, `Brochure`);
						callback();
					} else {
						getEnv(self).notifier.enqueue(
							"Unable to download File",
							"error"
						);
						callback();
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),

			save: flow(function* save(data, snackbar) {
				if (self.details?.activityPreviewId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreview`,
							getRequestOptions("POST", {
								...self.details,
								...data,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(self.details, json);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/createCoursePreview`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							self.details = json;
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveDescription: flow(function* saveDescription(data, snackbar) {
				if (data?.activityPreviewId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewDescription`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveLearning: flow(function* saveLearning(data, snackbar) {
				if (data?.learningId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewLearning`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.coursePreviewLearning.find(
										(item) =>
											item.learningId === data.learningId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewLearning`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.coursePreviewLearning) {
								self.details?.coursePreviewLearning.push(json);
							} else {
								applySnapshot(self.details, {
									...self.details,
									coursePreviewLearning: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeLearning: flow(function* removeLearning(data, snackbar) {
				if (data?.learningId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCoursePreviewLearning`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.coursePreviewLearning.find(
										(item) =>
											item.learningId === data.learningId
									)
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveRequirement: flow(function* saveRequirement(data, snackbar) {
				if (data?.requirementId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewRequirement`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.coursePreviewRequirement.find(
										(item) =>
											item.requirementId ===
											data.requirementId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewRequirement`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);

							if (self.details?.coursePreviewRequirement) {
								self.details?.coursePreviewRequirement.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									coursePreviewRequirement: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeRequirement: flow(function* removeRequirement(
				data,
				snackbar
			) {
				if (data?.requirementId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCoursePreviewRequirement`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.coursePreviewRequirement.find(
										(item) =>
											item.requirementId ===
											data.requirementId
									)
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editLearningItem(item, edit) {
				applySnapshot(
					self.details?.coursePreviewLearning.find(
						(learning) => learning.learningId === item.learningId
					),
					{
						...self.details?.coursePreviewLearning.find(
							(learning) =>
								learning.learningId === item.learningId
						),
						edit: edit,
					}
				);
			},

			editRequirementItem(item, edit) {
				applySnapshot(
					self.details?.coursePreviewRequirement.find(
						(requirement) =>
							requirement.requirementId === item.requirementId
					),
					{
						...self.details?.coursePreviewRequirement.find(
							(requirement) =>
								requirement.requirementId === item.requirementId
						),
						edit: edit,
					}
				);
			},

			saveOutline: flow(function* saveOutline(data, snackbar) {
				if (data?.courseOutlineId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewOutline`,
							getRequestOptions("POST", {
								...data,
								outlineId: data?.courseOutlineId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.coursePreviewOutline.find(
										(item) =>
											item.courseOutlineId ===
											data.courseOutlineId
									),
									{
										...json,
										courseOutline: json.outline,
										courseOutlineId: json.outlineId,
									}
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewOutline`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.coursePreviewOutline) {
								self.details?.coursePreviewOutline.push({
									...json,
									courseOutline: json.outline,
									courseOutlineId: json.outlineId,
								});
							} else {
								applySnapshot(self.details, {
									...self.details,
									coursePreviewOutline: [
										{
											...json,
											courseOutline: json.outline,
											courseOutlineId: json.outlineId,
										},
									],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeOutline: flow(function* removeOutline(data, snackbar) {
				if (data?.courseOutlineId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCoursePreviewOutline`,
							getRequestOptions("POST", {
								...data,
								outlineId: data?.courseOutlineId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.coursePreviewOutline.find(
										(item) =>
											item.courseOutlineId ===
											data.courseOutlineId
									)
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editOutlineItem(item, edit) {
				applySnapshot(
					self.details?.coursePreviewOutline.find(
						(courseOutline) =>
							courseOutline.courseOutlineId ===
							item.courseOutlineId
					),
					{
						...self.details?.coursePreviewOutline.find(
							(courseOutline) =>
								courseOutline.courseOutlineId ===
								item.courseOutlineId
						),
						edit: edit,
					}
				);
			},

			saveContinuingEducation: flow(function* saveContinuingEducation(
				data,
				snackbar
			) {
				if (data?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewContinuingEducation`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.coursePreviewContinuingEducation.find(
										(item) =>
											item.continuingEducationId ===
											data.continuingEducationId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewContinuingEducation`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (
								self.details?.coursePreviewContinuingEducation
							) {
								self.details?.coursePreviewContinuingEducation.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									coursePreviewContinuingEducation: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeContinuingEducation: flow(function* removeContinuingEducation(
				data,
				snackbar
			) {
				if (data?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCoursePreviewContinuingEducation`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.coursePreviewContinuingEducation.find(
										(item) =>
											item.continuingEducationId ===
											data.continuingEducationId
									)
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editContinuingEducationItem(item, edit) {
				applySnapshot(
					self.details?.coursePreviewContinuingEducation.find(
						(continuingEducation) =>
							continuingEducation.continuingEducationId ===
							item.continuingEducationId
					),
					{
						...self.details?.coursePreviewContinuingEducation.find(
							(continuingEducation) =>
								continuingEducation.continuingEducationId ===
								item.continuingEducationId
						),
						edit: edit,
					}
				);
			},

			saveCourseObjective: flow(function* saveCourseObjective(
				data,
				snackbar
			) {
				if (data?.courseObjectiveId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewCourseObjective`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.coursePreviewCourseObjective.find(
										(item) =>
											item.courseObjectiveId ===
											data.courseObjectiveId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewCourseObjective`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.coursePreviewCourseObjective) {
								self.details?.coursePreviewCourseObjective.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									coursePreviewCourseObjective: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeCourseObjective: flow(function* removeCourseObjective(
				data,
				snackbar
			) {
				if (data?.courseObjectiveId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCoursePreviewCourseObjective`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.coursePreviewCourseObjective.find(
										(item) =>
											item.courseObjectiveId ===
											data.courseObjectiveId
									)
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editCourseObjectiveItem(item, edit) {
				applySnapshot(
					self.details?.coursePreviewCourseObjective.find(
						(courseObjective) =>
							courseObjective.courseObjectiveId ===
							item.courseObjectiveId
					),
					{
						...self.details?.coursePreviewCourseObjective.find(
							(courseObjective) =>
								courseObjective.courseObjectiveId ===
								item.courseObjectiveId
						),
						edit: edit,
					}
				);
			},

			saveTargetAudience: flow(function* saveTargetAudience(
				data,
				snackbar
			) {
				if (data?.targetAudienceId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewTargetAudience`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.coursePreviewTargetAudience.find(
										(item) =>
											item.targetAudienceId ===
											data.targetAudienceId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewTargetAudience`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.coursePreviewTargetAudience) {
								self.details?.coursePreviewTargetAudience.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									coursePreviewTargetAudience: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeTargetAudience: flow(function* removeTargetAudience(
				data,
				snackbar
			) {
				if (data?.targetAudienceId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCoursePreviewTargetAudience`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.coursePreviewTargetAudience.find(
										(item) =>
											item.targetAudienceId ===
											data.targetAudienceId
									)
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editTargetAudienceItem(item, edit) {
				applySnapshot(
					self.details?.coursePreviewTargetAudience.find(
						(targetAudience) =>
							targetAudience.targetAudienceId ===
							item.targetAudienceId
					),
					{
						...self.details?.coursePreviewTargetAudience.find(
							(targetAudience) =>
								targetAudience.targetAudienceId ===
								item.targetAudienceId
						),
						edit: edit,
					}
				);
			},

			saveAdditionalInformation: flow(function* saveAdditionalInformation(
				data,
				snackbar
			) {
				if (data?.additionalInformationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewAdditionalInformation`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.coursePreviewAdditionalInformation.find(
										(item) =>
											item.additionalInformationId ===
											data.additionalInformationId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewAdditionalInformation`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (
								self.details?.coursePreviewAdditionalInformation
							) {
								self.details?.coursePreviewAdditionalInformation.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									coursePreviewAdditionalInformation: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeAdditionalInformation: flow(
				function* removeAdditionalInformation(data, snackbar) {
					if (data?.additionalInformationId) {
						try {
							const response = yield fetchApi(
								`${ROOT_URL}/api/Course/deleteCoursePreviewAdditionalInformation`,
								getRequestOptions("POST", data)
							);
							const json = yield response.json();
							if (response.status === 200) {
								if (json.status === 400) {
									snackbar.showMessage(
										json.error,
										null,
										null,
										{
											severity: "error",
										}
									);
								} else {
									destroy(
										self.details?.coursePreviewAdditionalInformation.find(
											(item) =>
												item.additionalInformationId ===
												data.additionalInformationId
										)
									);
									snackbar.showMessage(
										"Record Successfully Updated!!",
										null,
										null,
										{ severity: "success" }
									);
								}
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} catch (e) {
							snackbar.showMessage(
								API_ERROR_MESSAGE,
								null,
								null,
								{
									severity: "error",
								}
							);
						}
					}
				}
			),

			editAdditionalInformationItem(item, edit) {
				applySnapshot(
					self.details?.coursePreviewAdditionalInformation.find(
						(additionalInformation) =>
							additionalInformation.additionalInformationId ===
							item.additionalInformationId
					),
					{
						...self.details?.coursePreviewAdditionalInformation.find(
							(additionalInformation) =>
								additionalInformation.additionalInformationId ===
								item.additionalInformationId
						),
						edit: edit,
					}
				);
			},

			saveCourseMaterial: flow(function* saveCourseMaterial(
				data,
				snackbar
			) {
				if (data?.courseMaterialId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewCourseMaterial`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.coursePreviewCourseMaterial.find(
										(item) =>
											item.courseMaterialId ===
											data.courseMaterialId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewCourseMaterial`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.coursePreviewCourseMaterial) {
								self.details?.coursePreviewCourseMaterial.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									coursePreviewCourseMaterial: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeCourseMaterial: flow(function* removeCourseMaterial(
				data,
				snackbar
			) {
				if (data?.courseMaterialId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCoursePreviewCourseMaterial`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.coursePreviewCourseMaterial.find(
										(item) =>
											item.courseMaterialId ===
											data.courseMaterialId
									)
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editCourseMaterialItem(item, edit) {
				applySnapshot(
					self.details?.coursePreviewCourseMaterial.find(
						(courseMaterial) =>
							courseMaterial.courseMaterialId ===
							item.courseMaterialId
					),
					{
						...self.details?.coursePreviewCourseMaterial.find(
							(courseMaterial) =>
								courseMaterial.courseMaterialId ===
								item.courseMaterialId
						),
						edit: edit,
					}
				);
			},

			saveInstructor: flow(function* saveInstructor(data, snackbar) {
				if (data?.presenterId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewPresenter`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.coursePreviewPresenter.find(
										(item) =>
											item.presenterId ===
											data.presenterId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewInstructor`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.coursePreviewPresenter) {
								self.details?.coursePreviewPresenter.push(json);
							} else {
								applySnapshot(self.details, {
									...self.details,
									coursePreviewPresenter: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeInstructor: flow(function* removeInstructor(data, snackbar) {
				if (data?.presenterId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCoursePreviewPresenter`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.coursePreviewPresenter.find(
										(item) =>
											item.presenterId ===
											data.presenterId
									)
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editInstructorItem(item, edit) {
				applySnapshot(
					self.details?.coursePreviewPresenter.find(
						(instructor) =>
							instructor.presenterId === item.presenterId
					),
					{
						...self.details?.coursePreviewPresenter.find(
							(instructor) =>
								instructor.presenterId === item.presenterId
						),
						edit: edit,
					}
				);
			},

			saveSchedule: flow(function* saveSchedule(data, snackbar) {
				if (data?.scheduleId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewSchedule`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.coursePreviewSchedule.find(
										(item) =>
											item.scheduleId === data.scheduleId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCoursePreviewSchedule`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.coursePreviewSchedule) {
								self.details?.coursePreviewSchedule.push(json);
							} else {
								applySnapshot(self.details, {
									...self.details,
									coursePreviewSchedule: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeSchedule: flow(function* removeSchedule(data, snackbar) {
				if (data?.scheduleId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCoursePreviewSchedule`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.coursePreviewSchedule.find(
										(item) =>
											item.scheduleId === data.scheduleId
									)
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editScheduleItem(item, edit) {
				applySnapshot(
					self.details?.coursePreviewSchedule.find(
						(schedule) => schedule.scheduleId === item.scheduleId
					),
					{
						...self.details?.coursePreviewSchedule.find(
							(schedule) =>
								schedule.scheduleId === item.scheduleId
						),
						edit: edit,
					}
				);
			},
			addHeadshot: flow(function* addHeadshot(data, callback) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/AddPresenterHeadshot`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.loadHeadshot(data);
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
			loadHeadshot: flow(function* loadHeadshot(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/GetPresenterHeadShotBase64?presenterId=${data?.presenterId}&courseId=${data?.courseId}&courseSNo=${data?.courseSNo}`,
						getRequestOptions("GET", null)
					);
					const base64 = yield response.text();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (
							self.presenterHeadshots.some(
								(headshot) =>
									headshot.presenterId === data.presenterId
							)
						) {
							self.presenterHeadshots.find(
								(headshot) =>
									headshot.presenterId === data.presenterId
							).headshot = 'data:image/png;base64,' + base64;
						} else {
							self.presenterHeadshots.push({
								presenterId: data.presenterId,
								headshot: 'data:image/png;base64,' + base64,
							});
						}
					}
				} catch (error) { }
			}),
		};
	});
