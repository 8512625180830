import { flow, getEnv, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const CountryItem = types.model({
	countryId: types.identifierNumber,
	countryName: types.string,
	countryCode: types.string,
});

export const Country = types
	.model({
		items: types.array(CountryItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		return {
			// afterCreate() {
			// 	self.load();
			// },
			load: flow(function* load() {
				if (self?.items?.length) return;
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getAllCountry`,
						getRequestOptions("GET", null)
					);
					self.items = yield response.json();
					self.status = "done";
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					self.status = "error";
				}
			}),
		};
	});
