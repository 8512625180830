import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const FactorItem = types.model({
	id: types.maybeNull(types.number),
	factorName: types.maybeNull(types.string),
	surveyId: types.maybeNull(types.number),
	factorFormulaId: types.maybeNull(types.number),
	communityNorm: types.maybeNull(types.number),
	clinicalNorm: types.maybeNull(types.number),
	factorColor: types.maybeNull(types.string),
});

export const Factor = types
	.model({
		items: types.array(FactorItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		return {
			load: flow(function* load(id, snackbar, callback) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getAllFactor?surveyId=${id}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json;
						self.status = "done";
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
					callback();
				}
			}),
			save: flow(function* save(data, snackbar, callback) {
				if (data.id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/updateFactor`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							applySnapshot(
								self.items.find((item) => item.id === data.id),
								json
							);
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/createFactor`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.items.push(json);
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				}
			}),
			remove: flow(function* remove(factorId, snackbar) {
				try {
					let factor = self.items.find(
						(item) => item.id === factorId
					);
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/deleteFactorById`,
						getRequestOptions("POST", {
							id: factor.id,
							surveyId: factor.surveyId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(factor);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
		};
	});
