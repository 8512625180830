import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, CONTENT_TYPE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const SurveyRangeItem = types.model({
	rangeName: types.maybeNull(types.string),
	surveyRangeId: types.maybeNull(types.number),
	surveyId: types.maybeNull(types.number),
	rangeStart: types.maybeNull(types.number),
	rangeEnd: types.maybeNull(types.number),
});

export const SurveyRange = types
	.model({
		items: types.array(SurveyRangeItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.id === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			loadById: flow(function* loadById(id, snackbar) {
				self.items = [];
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getSurveyRangeById?surveyId=${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === 200) {
						self.items = data;
					} else {
						snackbar.showMessage(data.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
			empty() {
				self.items = [];
			},
			save: flow(function* save(range, snackbar) {
				if (range.surveyRangeId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/updateSurveyRange`,
							getRequestOptions("POST", range)
						);
						const json = yield response.json();

						if (response.status === 200) {
							applySnapshot(
								self.items.find(
									(item) =>
										item.surveyRangeId ===
										range.surveyRangeId
								),
								json
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/addSurveyRange`,
							getRequestOptions("POST", range)
						);
						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							self.items.push(json);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			remove: flow(function* remove(surveyRangeId, snackbar) {
				try {
					let surveyRange = self.items.find(
						(surveyRange) =>
							surveyRange.surveyRangeId === surveyRangeId
					);
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/deleteSurveyRangeById`,
						getRequestOptions("POST", {
							surveyRangeId: surveyRange.surveyRangeId,
							surveyId: surveyRange.surveyId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(surveyRange);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
		};
	});
