import {
	types,
	flow,
	applySnapshot,
	destroy,
	getSnapshot,
} from "mobx-state-tree";
import { API_ERROR_MESSAGE, CONTENT_TYPE, ROOT_URL } from "../../constants";
import { getNextThursday } from "../../utils/DateOperations";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const SurveyItem = types.model({
	surveyId: types.maybeNull(types.number),
	surveyName: types.maybeNull(types.string),
	frequencySNo: types.optional(types.number, 1),
	frequencyId: types.optional(
		types.string,
		"fce49ef9-cd17-42a5-bd16-923a01ecfe7a"
	),
});

const SessionItem = types.model({
	sessionDate: types.maybeNull(types.string),
});

const PatientGroupItem = types.model({
	sNo: types.maybeNull(types.identifierNumber),
	id: types.maybeNull(types.string),
	name: types.maybeNull(types.string),
	patientGroupSNo: types.maybeNull(types.number),
	patientGroupId: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	startDate: types.maybeNull(types.string),
	endDate: types.maybeNull(types.string),
	patientGroupName: types.maybeNull(types.string),
	patientCount: types.maybeNull(types.number),
	survey: types.maybeNull(types.array(SurveyItem)),
	sessions: types.maybeNull(types.array(SessionItem)),
});

const PatientGroupCategoryItem = types.model({
	categoryId: types.number,
	categoryName: types.string,
	surveys: types.array(SurveyItem),
});

export const PatientGroup = types
	.model({
		items: types.array(PatientGroupItem),
		current: types.maybeNull(
			types.reference(types.late(() => PatientGroupItem))
		),
		categories: types.maybeNull(types.array(PatientGroupCategoryItem)),
		fetch_all_status: types.maybeNull(
			types.enumeration("State", ["pending", "done", "error"])
		),
		fetch_categories_status: types.maybeNull(
			types.enumeration("State", ["pending", "done", "error"])
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
	})
	.views((self) => {
		return {
			selectedDates() {
				let selectedDates = [];

				self.current.sessions?.forEach((session) => {
					selectedDates.push(new Date(session.sessionDate));
				});

				return selectedDates;
			},
			getSessions(start, end) {
				let selectedDates = [];
				const startDate = start;
				const endDate = end;
				let previousDate = new Date(startDate);
				while (
					new Date(previousDate).toISOString() <=
					new Date(endDate).toISOString()
				) {
					selectedDates.push(previousDate);
					previousDate = getNextThursday(previousDate);
				}
				return selectedDates;
			},
			getSurveysByCategoryId(categoryId) {
				if (categoryId) {
					return self.categories.find(
						(category) => category.categoryId === categoryId
					)?.surveys;
				}
			},
		};
	})
	.actions((self) => {
		let controllerCategory;

		return {
			// afterCreate() {
			// 	self.loadPatientGroups();
			// 	self.loadCategories();
			// },
			updateSurveysByCategory(categoryId) {
				const surveys = self.categories.find(
					(category) => category.categoryId === categoryId
				)?.surveys;
				self.current.survey = getSnapshot(surveys);
				// applySnapshot(self.current.survey, getSnapshot(surveys));
			},
			updateFrequency(sNo, frequency, survey) {
				const survey_item = self.items
					.find((item) => item.sNo === sNo)
					.survey.find((s) => s.surveyId === survey.surveyId);
				if (survey_item) {
					self.items
						.find((item) => item.sNo === sNo)
						.survey.find(
							(s) => s.surveyId === survey.surveyId
						).frequencyId = frequency.id;

					self.items
						.find((item) => item.sNo === sNo)
						.survey.find(
							(s) => s.surveyId === survey.surveyId
						).frequencySNo = frequency.sNo;

					applySnapshot(
						self.current,
						self.items.find((item) => item.sNo === sNo)
					);
				}
			},
			updateSurvey(sNo, survey, row) {
				const survey_item = self.items
					.find((item) => item.sNo === sNo)
					.survey.find((s) => s.surveyId === row.surveyId);
				if (survey_item) {
					self.items
						.find((item) => item.sNo === sNo)
						.survey.find(
							(s) => s.surveyId === row.surveyId
						).surveyId = survey.id;

					applySnapshot(
						self.current,
						self.items.find((item) => item.sNo === sNo)
					);
				}
			},
			addNewSurvey(sNo) {
				if (
					!self.items
						.find((item) => item.sNo === sNo)
						.survey.find((s) => s.surveyId === 0)
				) {
					self.items
						.find((item) => item.sNo === sNo)
						.survey.push({ surveyName: null, surveyId: 0 });
				}
			},
			loadPatientGroups: flow(function* loadPatientGroups() {
				self.fetch_all_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ClinicianGroupTherapy/getGroupTherapy`,
						getRequestOptions("GET", null)
					);

					const json = yield response.json();
					if (json.length > 0) {
						if (json[0].status === 0) {
							self.items = json;
						}
					}
					self.fetch_all_status = "done";
				} catch (error) {
					console.error("Failed to fetchApi patient groups", error);
					self.fetch_all_status = "error";
				}
			}),
			loadById: flow(function* loadById(id, sNo, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ClinicianGroupTherapy/GetClinicianGroupTherapyById?SNo=${sNo}&Id=${id}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();

					if (response.status === 200) {
						applySnapshot(
							self.items.find((item) => item.sNo === sNo),
							{
								...self.items.find((item) => item.sNo === sNo),
								...json,
							}
						);
						self.current = self.items.find(
							(item) => item.sNo === sNo
						);
						return 200;
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
						return 400;
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					return 500;
				}
			}),
			loadCategories: flow(function* loadCategories() {
				controllerCategory = AbortController && new AbortController();

				self.fetch_categories_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/PatientGroupCategory/getAllPatientGroupCategory`,
						getRequestOptions("GET", null)
					);

					let data = yield response.json();

					data.map((item) => {
						item.surveys.map((survey) => {
							survey.surveyId = survey.id;
							return survey;
						});
						return item;
					});

					self.categories = data;
					self.fetch_categories_status = "done";
				} catch (error) {
					console.error(
						"Failed to fetchApi patient group categories",
						error
					);
					self.fetch_categories_status = "error";
				}
			}),
			reloadPatientGroups() {
				self.loadPatientGroups();
			},
			reloadCategories() {
				self.loadCategories();
			},
			beforeDestroy() {
				if (controllerCategory) controllerCategory.abort();
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updatePatientGroupListItem(data) {
				applySnapshot(
					self.items.find((item) => item.sNo === data.sNo),
					data
				);
			},
			addPatientGroupListItem(data) {
				self.items.push(data);
			},
			save: flow(function* save(data, snackbar) {
				if (self.current?.sNo !== -1) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ClinicianGroupTherapy/UpdateClinicianGroupTherapy`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							if (json.status === 0) {
								self.updatePatientGroupListItem(json);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);

								self.updateContentType(CONTENT_TYPE.LIST);
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ClinicianGroupTherapy/CreateClinicianGroupTherapy`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 0) {
								self.addPatientGroupListItem(json);
								snackbar.showMessage(
									"Record Successfully Created!!",
									null,
									null,
									{ severity: "success" }
								);
								self.updateContentType(CONTENT_TYPE.LIST);
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			updateCurrentItem(id) {
				if (id) {
					self.current = self.items.find((item) => item.sNo === id);
				} else {
					if (self.items.find((item) => item.sNo === -1)) {
						self.current = self.items.find(
							(item) => item.sNo === -1
						);
					} else {
						self.items.push({
							sNo: -1,
						});
						self.current = self.items.find(
							(item) => item.sNo === -1
						);
					}
				}
			},
			remove: flow(function* remove(id) {
				const item = self.items.find((item) => item.sNo === id);
				try {
					yield fetchApi(
						`${ROOT_URL}/api/PatientGroup/deletePatientGroupById`,
						getRequestOptions("POST", {
							id: item.id,
							sNo: item.sNo,
						})
					);
					self.deletePatientGroup(id);
				} catch (e) {
					console.error("Uh oh, failed to save: ", e);
				}
			}),
			deletePatientGroup(id) {
				self.current = null;
				destroy(self.items.find((item) => item.sNo === id));
			},
		};
	});
