import { types, flow, applySnapshot, destroy } from "mobx-state-tree";
import { API_ERROR_MESSAGE, CONTENT_TYPE, ROOT_URL } from "../../constants";
import { getNextThursday } from "../../utils/DateOperations";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const PatientGroupItem = types.model({
	sNo: types.maybeNull(types.identifierNumber),
	id: types.maybeNull(types.string),
	name: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	startDate: types.maybeNull(types.string),
	endDate: types.maybeNull(types.string),
	noofSessions: types.maybeNull(types.number),
	structuredContinous: types.maybeNull(
		types.enumeration("structuredContinous", ["structured", "continuous"])
	),
	internalGlobal: types.maybeNull(
		types.enumeration("internalGlobal", ["internal", "global"])
	),
	deleted: types.maybeNull(types.boolean),
	createdBy: types.maybeNull(types.number),
	patientGroupCategoryCategoryId: types.maybeNull(types.number),
	patientGroupCategory: types.maybeNull(types.string),
});

const PatientGroupCategoryItem = types.model({
	categoryId: types.number,
	categoryName: types.string,
});

export const PatientGroupSA = types
	.model({
		items: types.array(PatientGroupItem),
		current: types.maybeNull(
			types.reference(types.late(() => PatientGroupItem))
		),
		categories: types.maybeNull(types.array(PatientGroupCategoryItem)),
		fetch_all_status: types.maybeNull(
			types.enumeration("State", ["pending", "done", "error"])
		),
		fetch_categories_status: types.maybeNull(
			types.enumeration("State", ["pending", "done", "error"])
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
	})
	.views((self) => {
		return {
			selectedDates() {
				let selectedDates = [];
				const startDate = self.current.startDate;
				const endDate = self.current.endDate;
				let previousDate = new Date(startDate);
				while (
					new Date(previousDate).toISOString() <=
					new Date(endDate).toISOString()
				) {
					selectedDates.push(previousDate);
					previousDate = getNextThursday(previousDate);
				}
				return selectedDates;
			},
		};
	})
	.actions((self) => {
		let controllerPatientGroup;
		let controllerCategory;

		return {
			// afterCreate() {
			// 	self.loadPatientGroups();
			// 	self.loadCategories();
			// },
			loadPatientGroups: flow(function* loadPatientGroups() {
				controllerPatientGroup =
					AbortController && new AbortController();
				self.fetch_all_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/PatientGroup/getAllPatientGroup`,
						{
							signal:
								controllerPatientGroup &&
								controllerPatientGroup.signal,
						}
					);
					self.items = yield response.json();
					self.fetch_all_status = "done";
				} catch (error) {
					console.error("Failed to fetchApi patient groups", error);
					self.fetch_all_status = "error";
				}
			}),
			loadById: flow(function* loadById(id, sNo, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/PatientGroup/getPatientGroupById?SNo=${sNo}&Id=${id}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();

					if (response.status === 200) {
						applySnapshot(
							self.items.find((item) => item.sNo === sNo),
							{
								...self.items.find((item) => item.sNo === id),
								...json,
							}
						);
						self.current = self.items.find(
							(item) => item.sNo === sNo
						);
						return 200;
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
						return 400;
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					return 500;
				}
			}),
			loadCategories: flow(function* loadCategories() {
				controllerCategory = AbortController && new AbortController();

				self.fetch_categories_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/PatientGroup/getAllPatientGroupCategory`,
						{
							signal:
								controllerCategory && controllerCategory.signal,
						}
					);
					self.categories = yield response.json();
					self.fetch_categories_status = "done";
				} catch (error) {
					console.error(
						"Failed to fetchApi patient group categories",
						error
					);
					self.fetch_categories_status = "error";
				}
			}),
			reloadPatientGroups() {
				if (controllerPatientGroup) controllerPatientGroup.abort();
				self.loadPatientGroups();
			},
			reloadCategories() {
				if (controllerCategory) controllerCategory.abort();
				self.loadCategories();
			},
			beforeDestroy() {
				if (controllerPatientGroup) controllerPatientGroup.abort();
				if (controllerCategory) controllerCategory.abort();
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updatePatientGroupListItem(data) {
				applySnapshot(
					self.items.find((item) => item.sNo === data.sNo),
					data
				);
			},
			addPatientGroupListItem(data) {
				self.items.push(data);
			},
			save: flow(function* save(data, snackbar) {
				if (self.current?.sNo !== -1) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/PatientGroup/updatePatientGroup`,
							{
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify({
									...self.current,
									...data,
								}),
							}
						);
						const json = yield response.json();

						if (response.status === 200) {
							self.updatePatientGroupListItem(json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);

							self.updateContentType(CONTENT_TYPE.LIST);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/PatientGroup/createPatientGroup`,
							{
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							}
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.addPatientGroupListItem(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							self.updateContentType(CONTENT_TYPE.LIST);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			updateCurrentItem(id) {
				if (id) {
					self.current = self.items.find((item) => item.sNo === id);
				} else {
					if (self.items.find((item) => item.sNo === -1)) {
						self.current = self.items.find(
							(item) => item.sNo === -1
						);
					} else {
						self.items.push({
							structuredContinous: "structured",
							internalGlobal: "internal",
							sNo: -1,
						});
						self.current = self.items.find(
							(item) => item.sNo === -1
						);
					}
				}
			},
			remove: flow(function* remove(sNo) {
				const id = self.items.find((item) => item.sNo === sNo).id;

				try {
					yield fetchApi(
						`${ROOT_URL}/api/PatientGroup/deletePatientGroupById`,
						{
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify({ sNo: sNo, id: id }),
						}
					);
					self.deletePatientGroup(sNo);
				} catch (e) {
					console.error("Uh oh, failed to save: ", e);
				}
			}),
			deletePatientGroup(id) {
				self.current = null;
				destroy(self.items.find((item) => item.sNo === id));
			},
		};
	});
