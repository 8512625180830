import { applySnapshot, destroy, flow, getEnv, types } from "mobx-state-tree";
import {
	CONTENT_TYPE,
	ROOT_URL,
	API_ERROR_MESSAGE,
	RESPONSE_STATUS,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import {
	getStructuredAppleSubsDetails,
	getStructuredClientSubsDetails,
} from "../../utils/Utilities";
import { jsonToFormData } from "../../helpers";
import { fetchApi } from "../../utils/CustomFetch";

const ClientSubscriptionPlans = types.model({
	appSubscriptionPlanID: types.maybeNull(types.number),
	isSelectedForPurchase: types.maybeNull(types.boolean),
	amount: types.maybeNull(types.number),
	frequency: types.maybeNull(types.number),
	subscriptionName: types.maybeNull(types.string),
	appSubscriptionID: types.maybeNull(types.number),
	appSubscriptionIDs: types.maybeNull(types.array(types.number)),
	categoryName: types.maybeNull(types.string),
	duration: types.maybeNull(types.number),
	clientType: types.maybeNull(types.number),
	isSkipTrial: types.maybeNull(types.boolean),
	clientID: types.maybeNull(types.number),
	trialDuration: types.maybeNull(types.number),
	trialFrequency: types.maybeNull(types.number),
	isPaymentDone: types.maybeNull(types.boolean),
});

const AppleClientSubscriptionPlans = types.model({
	appleHealthSubscriptionPlanID: types.maybeNull(types.number),
	isSelectedForPurchase: types.maybeNull(types.boolean),
	amount: types.maybeNull(types.number),
	frequency: types.maybeNull(types.number),
	subscriptionName: types.maybeNull(types.string),
	appleHealthGroupID: types.maybeNull(types.number),
	groupName: types.maybeNull(types.string),
	duration: types.maybeNull(types.number),
	// clientType: types.maybeNull(types.number),
	isSkipTrial: types.maybeNull(types.boolean),
	tenantID: types.maybeNull(types.number),
	isPaymentDone: types.maybeNull(types.boolean),
});

const ClinicItem = types.model({
	clinicId: types.identifierNumber,
	clinicianCount: types.maybeNull(types.number),
	clinicName: types.maybeNull(types.string),
	locationId: types.maybeNull(types.number),
	tenantId: types.maybeNull(types.number),
	locationName: types.maybeNull(types.string),
	address1: types.maybeNull(types.string),
	address2: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	stateId: types.maybeNull(types.number),
	countryId: types.maybeNull(types.number),
	zip: types.maybeNull(types.string),
	practiceTypeId: types.maybeNull(types.number),
	clinicContactId: types.maybeNull(types.number),
	primaryContactName: types.maybeNull(types.string),
	primaryContactPhone: types.maybeNull(types.string),
	primaryContactEmail: types.maybeNull(types.string),
	// appSubscriptionPlanIDS: types.maybeNull(types.array(types.number)),
	clientSubscriptionPlans: types.maybeNull(
		types.array(ClientSubscriptionPlans)
	),
	appleHealthAtttibuteIds: types.maybeNull(types.array(types.number)),
	appleClientSubscriptionPlan: types.maybeNull(
		types.array(AppleClientSubscriptionPlans)
	),
});

const SubscriptionPahseItem = types.model({
	cadence: types.number,
	amount: types.number,
	ordinal: types.number,
	periods: types.number,
});

const SubscriptionPlanItem = types.model({
	subscriptionName: types.string,
	appSubscriptionPlanID: types.number,
	isSkipTrial: types.boolean,
	isExcluded: types.maybeNull(types.boolean),
	subscriptionPhases: types.array(SubscriptionPahseItem),
});

const SubscriptionItem = types.model({
	subscriptionName: types.string,
	appSubscriptionID: types.number,
	appSubscriptionPlans: types.array(SubscriptionPlanItem),
});

const TenantItem = types.model({
	mainLogoPath: types.maybeNull(types.string),
	faviconLogoPath: types.maybeNull(types.string),
	headerLogoPath: types.maybeNull(types.string),
	lightThemeColor: types.maybeNull(types.string),
	darkThemeColor: types.maybeNull(types.string),
	adminEmail: types.maybeNull(types.string),
});

export const Clinic = types
	.model({
		items: types.array(ClinicItem),
		currentId: types.maybeNull(types.number),
		subscriptions: types.array(SubscriptionItem),
		tenant: types.maybeNull(TenantItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		fetch_all_status: types.optional(
			types.enumeration("State", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.clinicId === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			loadClinics: flow(function* loadClinics() {
				self.fetch_all_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getAllClinic`,
						getRequestOptions("GET", null)
					);
					const clinics = yield response.json();
					self.items = JSON.parse(JSON.stringify(clinics))?.map(
						(clinic) => {
							if (clinic?.clientSubscriptionPlans) {
								clinic.clientSubscriptionPlans =
									getStructuredClientSubsDetails(
										clinic.clientSubscriptionPlans
									);
							}
							if (
								clinic?.appleClientSubscriptionPlanResponse
									?.length
							) {
								clinic.appleClientSubscriptionPlan =
									getStructuredAppleSubsDetails(
										clinic?.appleClientSubscriptionPlanResponse
									);
							}
							return clinic;
						}
					);
					self.fetch_all_status = "done";
				} catch (error) {
					console.error("Failed to fetchApi patient groups", error);
					self.fetch_all_status = "error";
				}
			}),
			updateClinicListItem(data) {
				applySnapshot(
					self.items.find((item) => item.clinicId === data.clinicId),
					data
				);
			},
			addClinicListItem(data) {
				self.items.push(data);
			},
			save: flow(function* save(data, snackbar, loadLocations) {
				if (self.currentId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinic/updateClinic`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							json.clientSubscriptionPlans =
								getStructuredClientSubsDetails(
									json.clientSubscriptionPlans
								);
							json.appleClientSubscriptionPlan =
								getStructuredAppleSubsDetails(
									json?.appleClientSubscriptionPlanResponse
								);
							self.updateClinicListItem(json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
							loadLocations(json.clinicId);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinic/createClinic`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							json.clientSubscriptionPlans =
								getStructuredClientSubsDetails(
									json.clientSubscriptionPlans
								);
							json.appleClientSubscriptionPlan =
								getStructuredAppleSubsDetails(
									json?.appleClientSubscriptionPlanResponse
								);
							self.addClinicListItem(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							loadLocations(json.clinicId);
							self.updateCurrentItem(json.clinicId);
							self.updateContentType(CONTENT_TYPE.EDIT);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			reloadClinics() {
				self.loadClinics();
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
			remove: flow(function* remove(id) {
				try {
					yield fetchApi(
						`${ROOT_URL}/api/Clinic/deleteClinicById?id=${id}`,
						getRequestOptions('POST', null)
					);
					self.deleteClinic(id);
				} catch (e) {
					console.error("Uh oh, failed to save: ", e);
				}
			}),
			deleteClinic(id) {
				self.updateCurrentItem(null);
				destroy(self.items.find((item) => item.clinicId === id));
			},
			createClinicwithadmin: flow(function* createClinicwithadmin(
				data,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/createClinicwithadmin`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === 200) {
						snackbar.showMessage(
							"Email Sent Successfully!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			getSubscriptions: flow(function* getSubscriptions(
				clientType,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getSubscriptions?clientType=${clientType}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === 200) {
						self.subscriptions = json;
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			loadTheme: flow(function* loadTheme(clientId, tenantId) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getTenantThemeColor?clientId=${clientId}&tenantId=${tenantId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.tenant = { ...self.tenant, ...json };
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			loadMainLogo: flow(function* loadMainLogo(clientId, tenantId) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getTenantMainLogo?clientId=${clientId}&tenantId=${tenantId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.tenant = { ...self.tenant, ...json };
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			loadHeaderLogo: flow(function* loadHeaderLogo(clientId, tenantId) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getTenantHeaderLogo?clientId=${clientId}&tenantId=${tenantId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.tenant = { ...self.tenant, ...json };
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			loadFaviconLogo: flow(function* loadFaviconLogo(
				clientId,
				tenantId
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getTenantFaviconLogo?clientId=${clientId}&tenantId=${tenantId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.tenant = { ...self.tenant, ...json };
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			saveTheme: flow(function* saveTheme(data, callback = () => {}) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantThemeColor`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.tenant = { ...self.tenant, ...json };
						getEnv(self).notifier.enqueue(
							"Theme Successfully Uploaded!!",
							"success"
						);
						callback();
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			uploadMainLogo: flow(function* uploadMainLogo(
				data,
				callback = () => {}
			) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantMainLogo`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.tenant = { ...self.tenant, ...json };
							getEnv(self).notifier.enqueue(
								"Main Logo Successfully Uploaded!!",
								"success"
							);
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
			uploadHeaderLogo: flow(function* uploadHeaderLogo(
				data,
				callback = () => {}
			) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantHeaderLogo`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.tenant = { ...self.tenant, ...json };
							getEnv(self).notifier.enqueue(
								"Header Logo Successfully Uploaded!!",
								"success"
							);
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
			uploadFavicon: flow(function* uploadFavicon(
				data,
				callback = () => {}
			) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantFaviconLogo`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.tenant = { ...self.tenant, ...json };
							getEnv(self).notifier.enqueue(
								"Favicon Logo Successfully Uploaded!!",
								"success"
							);
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
		};
	});
