import { flow, types } from "mobx-state-tree";
import { ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const ContentProducerItem = types.model({
	contentProducerId: types.number,
	contentProducerPrefix: types.maybeNull(types.string),
	contentProducerFirstName: types.maybeNull(types.string),
	contentProducerMiddleName: types.maybeNull(types.string),
	contentProducerLastName: types.maybeNull(types.string),
	contentProducerEmail: types.maybeNull(types.string),
});

export const ContentProducer = types
	.model({
		items: types.array(ContentProducerItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			name(item) {
				return `${
					item.contentProducerPrefix ? item.contentProducerPrefix : ""
				} ${
					item.contentProducerFirstName
						? item.contentProducerFirstName
						: ""
				} ${
					item.contentProducerMiddleName
						? item.contentProducerMiddleName
						: ""
				} ${
					item.contentProducerLastName
						? item.contentProducerLastName
						: ""
				}`;
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(tenantId) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ContentCreator/getAllContentProducer/${tenantId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					self.items = json;
					self.status = "done";
				} catch (error) {
					console.error("Failed to fetchApi states", error);
					self.status = "error";
				}
			}),
		};
	});
