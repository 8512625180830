import { useEffect } from "react";
import NProgress from "nprogress";
import {
	CircularProgress,
	Typography,
	Container,
} from "@mui/material";
import { useStore } from "../../hooks";
import { observer } from "mobx-react-lite";

const LoadingScreen = () => {
	useEffect(() => {
		NProgress.start();

		return () => {
			NProgress.done();
		};
	}, []);

	const { tenant } = useStore();

	return (
		<Container
			component='main'
			maxWidth='xs'
			sx={{
				minHeight: "100%",
				py: 1,
				alignItems: "center",
				display: "flex",
				flexDirection: "column",
				height: "100%",
				justifyContent: "center",
			}}>
			<img
				alt={tenant.details?.tenantName || ""}
				src={tenant.details?.mainLogoPath}
				height='210'
				width='auto'
			/>
			<CircularProgress
				style={{
					marginTop: "5vh",
					width: "30px",
					height: "30px",
				}}
			/>
			<Typography
				variant='body2'
				color='primary'
				style={{ marginTop: "1vh" }}>
				<i>Loading</i> Data
			</Typography>
		</Container>
	);
};

export default observer(LoadingScreen);
