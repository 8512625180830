import { flow, types } from "mobx-state-tree";
import { ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const ResearcherItem = types.model({
	researcherSNo: types.maybeNull(types.number),
	researcherId: types.maybeNull(types.string),
	name: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	researchInstitutionSNo: types.maybeNull(types.number),
	researchInstitutionId: types.maybeNull(types.string),
});

export const ResearcherNameList = types
	.model({
		items: types.array(ResearcherItem),
		current: types.maybeNull(
			types.reference(types.late(() => ResearcherItem))
		),
		fetch_all_status: types.optional(
			types.enumeration("State", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		return {
			load: flow(function* load(sNo, id) {
				self.fetch_all_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ResearchInstitution/getAllResearcherByInstitutionId?SNo=${sNo}&Id=${id}`,
						getRequestOptions("GET", null)
					);
					self.items = yield response.json();
					self.fetch_all_status = "done";
				} catch (error) {
					console.error("Failed to fetchApi prefix", error);
					self.fetch_all_status = "error";
				}
			}),
		};
	});
