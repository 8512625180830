import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const ResourceItem = types.model({
	id: types.number,
	title: types.string,
	parentId: types.maybeNull(types.number),
	path: types.maybeNull(types.string),
	icon: types.maybeNull(types.string),
	type: types.maybeNull(types.string),
	modal: types.maybeNull(types.string),
	permission: types.maybeNull(types.boolean),
	children: types.array(types.late(() => ResourceItem)),
});

export const Resource = types
	.model({
		items: types.array(ResourceItem),
	})
	.views((self) => {
		return {
			get details() {
				let tmpData = [];
				self.items.forEach((x) => {
					let children = [];
					x.children.forEach((child) => {
						let grandchildren = [];
						child.children.forEach((grandchild) => {
							if (grandchild.permission) {
								grandchildren.push({
									path: grandchild.path,
									title: grandchild.title,
									model: grandchild.modal,
									type: grandchild.type,
								});
							}
						});

						if (grandchildren.length > 0) {
							if (child.permission) {
								children.push({
									path: child.path,
									title: child.title,
									model: child.modal,
									type: child.type,
									children: grandchildren,
								});
							}
						} else {
							if (child.permission) {
								children.push({
									path: child.path,
									title: child.title,
									model: child.modal,
									type: child.type,
								});
							}
						}
					});
					tmpData.push({
						title: x.title,
						items: children,
						path: x.path,
					});
				});
				return tmpData;
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(userId, snackbar, callback = () => { }) {
				try {
					if (userId) {
						var requestOptions = getRequestOptions("GET", null);
						if (requestOptions.headers.Authorization.indexOf('null') < 0) {
							const response = yield fetchApi(
								`${ROOT_URL}/api/admin/getResourcesByUserId?userId=${userId}`,
								requestOptions
							);
							const json = yield response.json();

							if (response.status === 200) {
								self.items = json;
								callback(json);
							}
							else {
								callback();
								snackbar?.showMessage(json?.error, null, null, {
									severity: "error",
								});
							}
						}
					}
				} catch (e) {
					snackbar?.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
		};
	});
