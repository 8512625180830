import { flow, types } from "mobx-state-tree";
import { ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const PrefixItem = types.model({
	id: types.identifierNumber,
	name: types.string,
});

export const Prefix = types
	.model({
		items: types.array(PrefixItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			getByName(name) {
				return self.items.find((item) => item.name === name);
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load() {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Common/getPrefix`,
						getRequestOptions("GET", null)
					);
					self.items = yield response.json();
					self.status = "done";
				} catch (error) {
					console.error("Failed to fetchApi prefix", error);
					self.status = "error";
				}
			}),
		};
	});
