let token = "";

export const setRequestToken = (jwt) => {
	token = jwt;
};

export const getRequestOptions = (type, payload, formData) => {
	let requestOptions = {
		method: type,
		headers: {
			"Content-Type": "application/json",
			"Cache-Control": "no-cache",
			Authorization: `Bearer ${token}`,
		},
	};
	if (formData) {
		requestOptions = {
			method: type,
			credentials: "include",
			headers: {
				Authorization: `Bearer ${token}`,
			}
		};

		requestOptions.body = payload;
	} else {
		if (payload) {
			requestOptions.body = JSON.stringify(payload);
		}
	}

	requestOptions.mode = "cors";
	if (type === "GET") {
		requestOptions.referrerPolicy = "strict-origin-when-cross-origin";
	}
	else {
		requestOptions.credentials = "include";
	}
	return requestOptions;
};
