import { types, flow, getEnv, applySnapshot, destroy } from "mobx-state-tree";
import { getRequestOptions } from "../../utils/RequestOptions";
import {
	CONTENT_TYPE,
	ROOT_URL,
	API_ERROR_MESSAGE,
	RESPONSE_STATUS,
} from "../../constants";
import { fetchApi } from "../../utils/CustomFetch";

const ActivityAttendeeItem = types.model({
	attendeeId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	activityName: types.maybeNull(types.string),
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	scheduleName: types.maybeNull(types.string),
	activityType: types.maybeNull(types.string),
	attendeeName: types.maybeNull(types.string),
	attendeeEmail: types.maybeNull(types.string),
	attendeePhone: types.maybeNull(types.string),
	status: types.maybeNull(types.string),
	isDeactivate: types.maybeNull(types.boolean),
	orderId: types.maybeNull(types.string),
});

const ActivityAttendeeBatchItem = types.model({
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	scheduleName: types.maybeNull(types.string),
});

const ActivityAttendeeCourseItem = types.model({
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	activityName: types.maybeNull(types.string),
});

export const ActivityAttendee = types
	.model({
		items: types.array(ActivityAttendeeItem),
		courses: types.array(ActivityAttendeeCourseItem),
		batches: types.array(ActivityAttendeeBatchItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		currentId: types.maybeNull(types.number),
		filterData: types.optional(ActivityAttendeeItem, {})
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.lmsId === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/GetActivityAttendeeReport?courseId=${
							data.courseId ? data.courseId : ""
						}&courseSNo=${
							data.courseSNo ? data.courseSNo : ""
						}&batchId=${
							data.batchId ? data.batchId : ""
						}&batchSNo=${
							data.batchSNo ? data.batchSNo : ""
						}&activityType=${
							data.activityType ? data.activityType : ""
						}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.items, json);
						applySnapshot(self.filterData, data);
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			loadCourses: flow(function* loadCourses() {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/GetActivityListForAttendee`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.courses = json;
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			loadBatches: flow(function* loadBatches(courseId, courseSNo) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/GetBatchListForAttendee?courseId=${courseId}&courseSNo=${courseSNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.batches = json;
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			remove: flow(function* remove(recordData) {
				try {
					const attendee = self.items.find(
						(item) => item.attendeeId === recordData.id && item.batchId === recordData.batchId && item.courseId === recordData.courseId
					);
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/UnassignActivityFromAttendee`,
						getRequestOptions("POST", {
							attendeeId: recordData.id,
							courseId: attendee.courseId,
							courseSNo: attendee.courseSNo,
							batchId: attendee.batchId,
							batchSNo: attendee.batchSNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						
						destroy(attendee);
						getEnv(self).notifier.enqueue(
							"Record Successfully Deleted!!",
							"success"
						);
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			updateDeactivateAttendee: flow(function* updateDeactivateAttendee(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/updateDeactivateAttendee`,
						getRequestOptions("POST", data)
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json.error, "error");
						} else {
							getEnv(self).notifier.enqueue(
								"Record Successfully Updated!!",
								"success"
							);
							self.load(self.filterData);
						}
					} else {
						getEnv(self).notifier.enqueue(json.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			assignUnassignActivityFromAttendee: flow(function* assignUnassignActivityFromAttendee(payload, callback=()=>{}) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/AssignUnassignActivityFromAttendee`,
						getRequestOptions("POST", payload)
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						getEnv(self).notifier.enqueue(
							"Record Successfully Updated!!",
							"success"
						);
						self.load(self.filterData);
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
					callback();
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			clearBatches() {
				self.batches = [];
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
		};
	});
