import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const SurveyItem = types.model({
	createdDate: types.string,
	id: types.number,
	surveyName: types.string,
});

const SurveyGroupItem = types.model({
	categoryId: types.identifierNumber,
	categoryName: types.maybeNull(types.string),
	surveyCount: types.maybeNull(types.number),
	createdDate: types.maybeNull(types.string),
	postReminder: types.maybeNull(types.number),
	preReminder: types.maybeNull(types.number),
	surveys: types.array(SurveyItem),
});

const PatientSurveyGroupItem = types.model({
	surveyGroupId: types.maybeNull(types.number),
	surveyGroupName: types.maybeNull(types.string),
	postReminder: types.maybeNull(types.number),
	preReminder: types.maybeNull(types.number),
});

const PatientSurveyItem = types.model({
	surveyName: types.maybeNull(types.string),
	postReminder: types.maybeNull(types.number),
	preReminder: types.maybeNull(types.number),
	surveyId: types.maybeNull(types.number),
});

export const SurveyGroup = types
	.model({
		items: types.array(SurveyGroupItem),
		surveys: types.array(PatientSurveyItem),
		groups: types.array(PatientSurveyGroupItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		fetch_all_status: types.optional(
			types.enumeration("State", ["pending", "done", "error"]),
			"pending"
		),
		current: types.maybeNull(
			types.reference(types.late(() => SurveyGroupItem))
		),
	})
	.actions((self) => {
		return {
			load: flow(function* load() {
				self.fetch_all_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/PatientGroupCategory/getAllPatientGroupCategory`,
						getRequestOptions("GET", null)
					);
					self.items = yield response.json();
					self.fetch_all_status = "done";
				} catch (error) {
					console.error("Failed to fetchApi patient groups", error);
					self.fetch_all_status = "error";
				}
			}),
			getAllSurveyNSurveyGroupForClinician: flow(
				function* getAllSurveyNSurveyGroupForClinician(
					snackbar,
					callback
				) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/getAllSurveyNSurveyGroupForClinician`,
							getRequestOptions("GET", null)
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.surveys = data.surveys;
							self.groups = data.surveyGroups;
							callback();
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				}
			),

			loadById: flow(function* loadById(id) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/PatientGroupCategory/getPatientGroupCategoryById?id=${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					self.items.find((item) => item.categoryId === id).surveys =
						data.surveys;
					self.current = self.items.find(
						(item) => item.categoryId === id
					);

					return true;
				} catch (error) {
					console.error("Failed to fetchApi patient groups", error);
					return false;
				}
			}),
			reload() {
				self.load();
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateSurveyGroupListItem(data) {
				applySnapshot(
					self.items.find(
						(item) => item.categoryId === data.categoryId
					),
					//   data
					{ ...data, surveys: [] }
				);
			},
			addSurveyGroupListItem(data) {
				if (data.surveys) {
					self.items.push(data);
				} else {
					self.items.push({ ...data, surveys: [] });
				}
			},
			save: flow(function* save(data, snackbar) {
				if (self.current?.categoryId !== 0) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/PatientGroupCategory/updatePatientGroupCategory`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							self.updateSurveyGroupListItem(json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);

							self.updateContentType(CONTENT_TYPE.LIST);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/PatientGroupCategory/createPatientGroupCategory`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.addSurveyGroupListItem(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							self.updateContentType(CONTENT_TYPE.LIST);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			updateCurrentItem(id) {
				if (id) {
				} else {
					if (self.items.find((item) => item.categoryId === 0)) {
						self.current = self.items.find(
							(item) => item.categoryId === 0
						);
					} else {
						self.items.push({
							categoryId: 0,
						});
						self.current = self.items.find(
							(item) => item.categoryId === 0
						);
					}
				}
			},
			remove: flow(function* remove(id) {
				try {
					yield fetchApi(
						`${ROOT_URL}/api/PatientGroupCategory/deletePatientGroupCategoryById?id=${id}`,
						getRequestOptions("POST", {})
					);
					self.deleteSurveyGroup(id);
				} catch (e) {
					console.error("Uh oh, failed to save: ", e);
				}
			}),
			deleteSurveyGroup(id) {
				self.current = null;
				destroy(self.items.find((item) => item.categoryId === id));
			},
		};
	});
