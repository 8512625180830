import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const ProposalRequestLicenseItem = types.model({
	professionalClassificationId: types.maybeNull(types.number),
	otherProfessionalClassification: types.maybeNull(types.string),
	professionalClassification: types.maybeNull(types.string),
	firstProfessionalClassificationId: types.maybeNull(types.number),
	firstOtherProfessionalClassification: types.maybeNull(types.string),
	firstProfessionalClassification: types.maybeNull(types.string),
	secondProfessionalClassificationId: types.maybeNull(types.number),
	secondOtherProfessionalClassification: types.maybeNull(types.string),
	secondProfessionalClassification: types.maybeNull(types.string),
	licenseNumber: types.maybeNull(types.string),
	dateOfLicense: types.maybeNull(types.string),
});

const ProposalItem = types.model({
	proposalRequestId: types.maybeNull(types.number),
	proposalRequestSNo: types.maybeNull(types.string),
	lastModifedDate: types.maybeNull(types.string),
	prefix: types.maybeNull(types.string),
	firstName: types.maybeNull(types.string),
	middleName: types.maybeNull(types.string),
	lastName: types.maybeNull(types.string),
	profession: types.maybeNull(types.string),
	company: types.maybeNull(types.string),
	address1: types.maybeNull(types.string),
	address2: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	countryId: types.maybeNull(types.number),
	stateId: types.maybeNull(types.number),
	zip: types.maybeNull(types.string),
	contactPreference: types.maybeNull(types.number),
	email: types.maybeNull(types.string),
	phoneHome: types.maybeNull(types.string),
	phoneBusiness: types.maybeNull(types.string),
	phoneFax: types.maybeNull(types.string),
	biography: types.maybeNull(types.string),
	titleOfProgram: types.maybeNull(types.string),
	learningObjective: types.maybeNull(types.string),
	outline: types.maybeNull(types.string),
	narrative: types.maybeNull(types.string),
	gapAddressed: types.maybeNull(types.string),
	evidenceGapExists: types.maybeNull(types.string),
	educationalNeedsAddressed: types.maybeNull(types.string),
	proposalRequestStatus: types.maybeNull(types.number),
	proposalRequestLicenses: types.maybeNull(
		types.array(ProposalRequestLicenseItem)
	),
	isExistingUser: types.maybeNull(types.boolean),
});

export const Proposal = types
	.model({
		items: types.array(ProposalItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
		status: types.maybeNull(
			types.enumeration("status", ["pending", "done", "error"])
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.proposalRequestId === self.currentId
					);
				} else {
					return null;
				}
			},
			name(item) {
				return `${item.prefix ? item.prefix : ""} ${
					item.firstName ? item.firstName : ""
				} ${item.middleName ? item.middleName : ""} ${
					item.lastName ? item.lastName : ""
				}`;
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ProposalRequest/getAllProposalRequest`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === 200) {
						self.items = json;
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "done";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "done";
				}
			}),
			loadById: flow(function* loadById(id, sNo, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ProposalRequest/getProposalRequestById?proposalRequestId=${id}&proposalRequestSNo=${sNo}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find(
								(item) => item.proposalRequestId === id
							),
							data
						);
						self.currentId = id;
						self.reviewProposal(id, sNo, snackbar);
						self.updateContentType(CONTENT_TYPE.EDIT);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			loadBySNo: flow(function* loadBySNo(sNo, snackbar, callback) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ProposalRequest/getProposalRequestBySNo?proposalRequestSNo=${sNo}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						callback(data);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			reviewProposal: flow(function* reviewProposal(id, sNo, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ProposalRequest/reviewProposalRequestById`,
						getRequestOptions("POST", {
							proposalRequestId: id,
							proposalRequestSNo: sNo,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find(
								(item) => item.proposalRequestId === id
							),
							data
						);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			approveProposal: flow(function* approveProposal(
				id,
				sNo,
				type,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ProposalRequest/approveProposalRequestById`,
						getRequestOptions("POST", {
							proposalRequestId: id,
							proposalRequestSNo: sNo,
							proposalOrActivity: type,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find(
								(item) => item.proposalRequestId === id
							),
							data
						);

						snackbar.showMessage(
							"Proposal Approved!!",
							null,
							null,
							{ severity: "success" }
						);
						callback(data);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			rejectProposal: flow(function* rejectProposal(
				id,
				sNo,
				reason,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ProposalRequest/rejectProposalRequestById`,
						getRequestOptions("POST", {
							proposalRequestId: id,
							proposalRequestSNo: sNo,
							reasonForReject: reason,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find(
								(item) => item.proposalRequestId === id
							),
							data
						);
						self.updateContentType(CONTENT_TYPE.LIST);
						self.currentId = null;
						snackbar.showMessage(
							"Proposal Rejected!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
			save: flow(function* save(data, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ProposalRequest/AddProposalRequest`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === 200) {
						snackbar.showMessage("Proposal Sent!!", null, null, {
							severity: "success",
						});
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			remove: flow(function* remove(proposalId, snackbar) {
				try {
					let proposal = self.items.find(
						(item) => item.proposalRequestId === proposalId
					);
					const response = yield fetchApi(
						`${ROOT_URL}/api/ProposalRequest/deleteProposalRequestById`,
						getRequestOptions("POST", {
							proposalRequestId: proposal.proposalRequestId,
							proposalRequestSNo: proposal.proposalRequestSNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(proposal);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
		};
	});
