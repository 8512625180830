import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { SettingsProvider, StoreProvider } from './contexts';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { BrowserRouter } from 'react-router-dom';
import CheckAuth from './utils/CheckAuth';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


root.render(
  <StoreProvider>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SettingsProvider>
            <BrowserRouter>
              <CheckAuth>
                <App />
              </CheckAuth>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
