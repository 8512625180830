import { types, flow, getEnv, applySnapshot, destroy } from "mobx-state-tree";
import { getRequestOptions } from "../../utils/RequestOptions";
import {
	CONTENT_TYPE,
	ROOT_URL,
	API_ERROR_MESSAGE,
	RESPONSE_STATUS,
} from "../../constants";
import { fetchApi } from "../../utils/CustomFetch";

const CouponItem = types.model({
	id: types.maybeNull(types.number),
	code: types.maybeNull(types.string),
	amount: types.maybeNull(types.number),
	percentage: types.maybeNull(types.number),
	startDate: types.maybeNull(types.string),
	endDate: types.maybeNull(types.string),
	limitNumberOfTotalUses: types.maybeNull(types.number),
	requireMinimumOrderTotal: types.maybeNull(types.number),
	limitNumberOfUsagesPerUser: types.maybeNull(types.number)
});

export const Coupons = types
	.model({
		items: types.array(CouponItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		appliedStatus: types.maybeNull(types.string),
		discountedCartAmount : types.maybeNull(types.number),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.id === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load() {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/coupon/all`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = JSON.parse(JSON.stringify(json));
						self.status = "done";
					} else {
						self.status = "error";
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					self.status = "error";
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			loadById: flow(function* loadById(id, sNo, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/coupon/${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find((item) => item.id === id),
							data
						);
						self.currentId = id;
						self.updateContentType(CONTENT_TYPE.EDIT);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
			remove: flow(function* remove(couponId) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/coupon/${couponId}`,
						getRequestOptions("DELETE", {
							id: couponId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 200) {
						const coupon = self.items.find(
							(item) => item.id === couponId
						);
						destroy(coupon);
						getEnv(self).notifier.enqueue(
							"Record Successfully Deleted!!",
							"success"
						);
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			save: flow(function* save(data) {
				if (self.currentId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/coupon`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							applySnapshot(
								self.items.find(
									(item) => item.id === json.id
								),
								json
							);
							getEnv(self).notifier.enqueue(
								"Record Successfully Updated!!",
								"success"
							);
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
						}
					} catch (e) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/coupon`,
							getRequestOptions("POST", {
								...data,
								deleted: false,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.items.push(json);
							getEnv(self).notifier.enqueue(
								"Record Successfully Created!!",
								"success"
							);
							self.updateCurrentItem(json.id);
							self.updateContentType(CONTENT_TYPE.EDIT);
						} else {
							getEnv(self).notifier.enqueue(json.error, "error");
						}
					} catch (e) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				}
			}),
			apply: flow(function* apply(couponCode, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/applyCoupon/${couponCode}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (data.status === RESPONSE_STATUS.SUCCESS) {
						self.appliedStatus = "Applied";
						self.discountedCartAmount = data?.cartAmount;
						snackbar.showMessage(data?.error, null, null, {
							severity: "success",
						});
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			makeAppliedStatusBlank() {
				self.appliedStatus = "";
			},
			removeApplied: flow(function* removeCoupon(couponCode, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/removeCoupon/${couponCode}`,
						getRequestOptions("DELETE", null)
					);
					const data = yield response.json();
					if (data.status === RESPONSE_STATUS.SUCCESS) {
						self.appliedStatus = "Removed";
						self.discountedCartAmount = data?.cartAmount;
						snackbar.showMessage(data?.error, null, null, {
							severity: "success",
						});
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			})
		};
	});
