import { React, Fragment } from "react";
import { useStore } from "../../hooks";
import { observer } from "mobx-react-lite";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet-async";

const Logo = () => {
	const { tenant } = useStore();

	return (
		<Fragment>
			<Helmet>
				<title>{tenant.details?.tenantName}</title>
			</Helmet>
			<LazyLoadImage
				src={tenant.details?.mainLogoPath}
				height='210'
				width='auto'
				effect='blur'
				alt={tenant.details?.tenantName || ""}
			/>
		</Fragment>
	);
};
export default observer(Logo);
