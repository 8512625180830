import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { CONTENT_TYPE, ROOT_URL, API_ERROR_MESSAGE } from "../../constants";
import { fetchApi } from "../../utils/CustomFetch";
import { getRequestOptions } from "../../utils/RequestOptions";

const ResearchPartnerItem = types.model({
	clinicResearchPartnerId: types.identifierNumber,
	partnerContactName: types.maybeNull(types.string),
	partnerContactPhone: types.maybeNull(types.string),
	partnerContactEmail: types.maybeNull(types.string),
	clinicId: types.maybeNull(types.number),
});

export const ResearchPartner = types
	.model({
		items: types.array(ResearchPartnerItem),
		current: types.maybeNull(
			types.reference(types.late(() => ResearchPartnerItem))
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
	})
	.actions((self) => {
		return {
			loadResearchPartnersByCLinicId: flow(
				function* loadResearchPartnersByCLinicId(id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinic/getClinicResearchPartnerByClinicId?ClinicId=${id}`
						);
						self.items = yield response.json();
					} catch (error) {
						console.error(
							"Failed to fetchApi patient groups",
							error
						);
					}
				}
			),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id, clinicId) {
				if (id) {
					self.current = self.items.find(
						(item) => item.clinicResearchPartnerId === id
					);
				} else {
					if (
						self.items.find(
							(item) => item.clinicResearchPartnerId === -1
						)
					) {
						self.current = self.items.find(
							(item) => item.clinicResearchPartnerId === -1
						);
					} else {
						self.items.push({
							clinicResearchPartnerId: -1,
							clinicId: clinicId,
						});
						self.current = self.items.find(
							(item) => item.clinicResearchPartnerId === -1
						);
					}
				}
			},
			updateResearchPartnerListItem(data) {
				applySnapshot(
					self.items.find(
						(item) =>
							item.clinicResearchPartnerId ===
							data.clinicResearchPartnerId
					),
					data
				);
			},
			addResearchPartnerListItem(data) {
				self.items.push(data);
			},
			save: flow(function* save(data, snackbar, closeDialog) {
				if (self.current?.clinicResearchPartnerId !== -1) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinic/updateResearchPartner`,
							getRequestOptions('POST', {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							self.updateResearchPartnerListItem(json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);

							self.updateContentType(CONTENT_TYPE.LIST);
							closeDialog();
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinic/createResearchPartner`,
							getRequestOptions('POST', data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.addResearchPartnerListItem(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							self.updateContentType(CONTENT_TYPE.LIST);
							closeDialog();
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			remove: flow(function* remove(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/deleteResearchPartnerById?id=${id}`,
						getRequestOptions('POST', null)
					);
					const json = yield response.json();
					if (response.status === 200) {
						self.deleteResearchPartner(id);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			deleteResearchPartner(id) {
				self.current = null;
				destroy(
					self.items.find(
						(item) => item.clinicResearchPartnerId === id
					)
				);
			},
		};
	});
