import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const wishlistItems = types.model({
	wishlistItemID: types.number,
	previewId: types.number,
	title1: types.string,
	title2: types.string,
	price: types.number,
	discountedPrice: types.maybeNull(types.number),
	imageURL: types.maybeNull(types.string),
});

export const WishlistItems = types
	.model({
		items: types.array(wishlistItems),
	})
	.views((self) => {
		return {
			get other() {
				return self.items.find(
					(item) => item.name === "Other (specify):"
				)?.id;
			},
			isAlreadyAdded(wishlistItemId, previewId) {
				return self.items.some(
					(item) =>
						item.wishlistItemID === wishlistItemId ||
						item.previewId === previewId
				);
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(userId, snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/wishlist?userId=${userId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json;
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			addToWishlist: flow(function* addToWishlist(data, snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/wishlist/wishlistitem`,
						getRequestOptions("POST", data)
					);

					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.status = "done";
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			removeFromWishlist: flow(function* removeFromWishlist(
				wishlistItemID,
				snackbar
			) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/wishlist/wishlistitem?wishlistItemID=${wishlistItemID}`,
						getRequestOptions("DELETE", null)
					);
					if (response.status === 200) {
						self.status = "done";
						const items = JSON.parse(JSON.stringify(self.items));
						const itemIdx = items.findIndex(
							(currentItem) =>
								currentItem.wishlistItemID === wishlistItemID
						);
						self.items.splice(itemIdx, 1);
						self.totalQuantity -= items[itemIdx].quantity;
					} else {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
		};
	});
