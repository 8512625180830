import { applySnapshot, destroy, flow, getEnv, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const PatientDemographicsItem = types.model({
	patientId: types.maybeNull(types.number),
	canEdit: types.maybeNull(types.boolean),
	biologicalSexSNo: types.maybeNull(types.number),
	biologicalSexId: types.maybeNull(types.string),
	genderSNo: types.maybeNull(types.number),
	genderId: types.maybeNull(types.string),
	relationshipStatusSNo: types.maybeNull(types.number),
	relationshipStatusId: types.maybeNull(types.string),
	occupationalHistorySNo: types.maybeNull(types.number),
	occupationalHistoryId: types.maybeNull(types.string),
	employeeDuration: types.maybeNull(types.string),
	raceSNo: types.maybeNull(types.number),
	raceId: types.maybeNull(types.string),
	raceOther: types.maybeNull(types.string),
	livingSituationSNo: types.maybeNull(types.number),
	livingSituationId: types.maybeNull(types.string),
	livingSituationOther: types.maybeNull(types.string),
	inSchool: types.maybeNull(types.boolean),
	educationHistorySNo: types.maybeNull(types.number),
	educationHistoryId: types.maybeNull(types.string),
	educationHistoryHighestLevelSNo: types.maybeNull(types.number),
	educationHistoryHighestLevelId: types.maybeNull(types.string),
	inArmedForce: types.maybeNull(types.boolean),
	militaryBranchId: types.maybeNull(types.number),
	militaryHistoryLength: types.maybeNull(types.string),
	militaryHistoryDateEnlisted: types.maybeNull(types.string),
	currentMilitaryStatusId: types.maybeNull(types.number),
	militaryDischargeId: types.maybeNull(types.number),
});

const PatientItem = types.model({
	sNo: types.maybeNull(types.number),
	userId: types.maybeNull(types.number),
	userSNo: types.maybeNull(types.string),
	canEdit: types.maybeNull(types.boolean),
	id: types.maybeNull(types.string),
	patientPrefix: types.maybeNull(types.string),
	patientFirstName: types.maybeNull(types.string),
	patientMiddleName: types.maybeNull(types.string),
	patientLastName: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	dob: types.maybeNull(types.string),
	address1: types.maybeNull(types.string),
	address2: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	stateId: types.maybeNull(types.number),
	countryId: types.maybeNull(types.number),
	zip: types.maybeNull(types.string),
	completedSurvey: types.maybeNull(types.number),
	inCompleteSurvey: types.maybeNull(types.number),
	clinicianId: types.maybeNull(types.number),
	neurofeedback: types.maybeNull(types.boolean),
	activity: types.maybeNull(types.boolean),
	inResearch: types.maybeNull(types.boolean),
	individualTherapy: types.maybeNull(types.boolean),
	groupTherapy: types.maybeNull(types.boolean),
	coaching: types.maybeNull(types.boolean),
	primaryICDCodeSNo: types.maybeNull(types.number),
	primaryICDCodeId: types.maybeNull(types.string),
	secondaryICDCodeSNo: types.maybeNull(types.number),
	secondaryICDCodeId: types.maybeNull(types.string),
	tertiaryICDCodeSNo: types.maybeNull(types.number),
	tertiaryICDCodeId: types.maybeNull(types.string),
	patientDemographic: types.maybeNull(PatientDemographicsItem),
});

const PatientSurveyItem = types.model({
	patientId: types.maybeNull(types.number),
	surveyId: types.maybeNull(types.number),
	surveyName: types.maybeNull(types.string),
	dueDate: types.maybeNull(types.string),
	preReminder: types.maybeNull(types.number),
	postReminder: types.maybeNull(types.number),
});

const PatientSurveyGroupItem = types.model({
	patientId: types.maybeNull(types.number),
	patientSurveyGroupId: types.maybeNull(types.number),
	surveyGroupName: types.maybeNull(types.string),
	dueDate: types.maybeNull(types.string),
	preReminder: types.maybeNull(types.number),
	postReminder: types.maybeNull(types.number),
});

export const Patient = types
	.model({
		items: types.array(PatientItem),
		surveys: types.array(PatientSurveyItem),
		surveysGroups: types.array(PatientSurveyGroupItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
		status: types.maybeNull(
			types.enumeration("status", ["pending", "done", "error"])
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.userId === self.currentId
					);
				} else {
					return null;
				}
			},
			name(item) {
				return `${item.patientPrefix ? item.patientPrefix : ""} ${
					item.patientFirstName ? item.patientFirstName : ""
				} ${item.patientMiddleName ? item.patientMiddleName : ""} ${
					item.patientLastName ? item.patientLastName : ""
				}`;
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Patient/getAllPatients`,
						getRequestOptions("GET", null)
					);

					const json = yield response.json();
					if (json?.length > 0) {
						if (json[0].status === 200) {
							self.items = json.patients;
							self.status = "done";
						} else {
							snackbar.showMessage(json[0].error, null, null, {
								severity: "error",
							});
							self.status = "error";
						}
					} else {
						self.items = json?.patients;
						self.status = "done";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadById: flow(function* loadById(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Patient/GetPatientById?patientId=${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (self.items.some((item) => item.userId === id)) {
							applySnapshot(
								self.items.find((item) => item.userId === id),
								data
							);
						} else {
							self.items.push(data);
						}
						self.currentId = id;
						self.updateContentType(CONTENT_TYPE.EDIT);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadSurveysById: flow(function* loadSurveysById(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getAssignedSurveyByPatientId?patientId=${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.surveys = data;
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadSurveyGroupsById: flow(function* loadSurveyGroupsById(
				id,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getAssignSurveyGroupByPatientnId?patientId=${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.surveysGroups = data;
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			save: flow(function* save(data, snackbar) {
				if (data.id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Patient/updatePatient`,
							getRequestOptions("POST", {
								...data,
								patientId: data.userId,
							})
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.items.find(
										(item) => item.id === data.id
									),
									json
								);
								snackbar.showMessage(
									"Patient Details Updated Succesfully!!",
									null,
									null,
									{
										severity: "success",
									}
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Patient/invitePatients`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.items.push(json);
								self.updateContentType(CONTENT_TYPE.LIST);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveDemographics: flow(function* saveDemographics(data, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Patient/updatePatientDemographic`,
						getRequestOptions("POST", data)
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						} else {
							applySnapshot(
								self.items.find(
									(item) => item.userId === data.patientId
								).patientDemographic,
								json
							);
							snackbar.showMessage(
								"Patient Demographics Updated Succesfully!!",
								null,
								null,
								{
									severity: "success",
								}
							);
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			assignSurveyToPatient: flow(function* assignSurveyToPatient(
				data,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/assignSurveyToPatient`,
						getRequestOptions("POST", data)
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						} else {
							self.surveys.push(json);
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
					callback();
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),

			assignSurveyGroupToPatient: flow(
				function* assignSurveyGroupToPatient(data, snackbar, callback) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/assignSurveyGroupToPatient`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.surveysGroups.push(json);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
						callback();
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				}
			),

			removeSurvey: flow(function* removeSurvey(survey, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/deleteSurveyByPatientnSurveyId`,
						getRequestOptions("POST", {
							patientId: survey.patientId,
							surveyId: survey.surveyId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(survey);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			remove: flow(function* remove(patient) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Patient/deletePatient`,
						getRequestOptions("POST", {
							patientId: patient.userId,
							patientSNo: patient.userSNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(patient);
						getEnv(self).notifier.enqueue(
							"Record Successfully Deleted!!",
							"success"
						);
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
				if (!id) {
					self.surveys = [];
				}
			},
		};
	});
