import { types, flow, getEnv } from "mobx-state-tree";
import { getRequestOptions } from "../../utils/RequestOptions";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { jsonToFormData } from "../../helpers";
import { fetchApi } from "../../utils/CustomFetch";

const TenantDetailsItem = types.model({
	tenantId: types.number,
	tenantName: types.string,
	mainLogoPath: types.string,
	feviconLogoPath: types.string,
	headerLogoPath: types.string,
	lightThemeColor: types.string,
	darkThemeColor: types.string,
	adminEmail: types.maybeNull(types.string),
});

const TenantItem = types.model({
	tenantID: types.maybeNull(types.number),
	subDomain: types.maybeNull(types.string),
	tenantName: types.maybeNull(types.string),
	clientName: types.maybeNull(types.string),
	clientSNo: types.maybeNull(types.string),
	clientID: types.maybeNull(types.number),
	clientType: types.maybeNull(types.number),
});

export const Tenant = types
	.model({
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		details: types.maybeNull(TenantDetailsItem),
		items: types.array(TenantItem),
		hasDetails: types.optional(types.boolean, false),
		currentId: types.maybeNull(types.number),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		return {
			getDetails: flow(function* getDetails() {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/account/getTenantDetails`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.details = json;
						self.hasDetails = true;
					} else {
						// getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					// getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			updateCurrentItem(id) {
				self.currentId = id;
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},

			load: flow(function* load() {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/getAllTenants`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json;
						self.status = "done";
					} else {
						self.status = "error";
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					self.status = "error";
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			saveTheme: flow(function* saveTheme(data, callback) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantThemeColor`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						callback();
						window.location.reload();
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			uploadMainLogo: flow(function* uploadMainLogo(
				data,
				callback = () => {}
			) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantMainLogo`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.details.mainLogoPath = json.mainLogoPath;
							getEnv(self).notifier.enqueue(
								"Main Logo Successfully Uploaded!!",
								"success"
							);
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
			uploadHeaderLogo: flow(function* uploadHeaderLogo(
				data,
				callback = () => {}
			) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantHeaderLogo`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.details.headerLogoPath = json.headerLogoPath;
							getEnv(self).notifier.enqueue(
								"Header Logo Successfully Uploaded!!",
								"success"
							);
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
			uploadFavicon: flow(function* uploadFavicon(
				data,
				callback = () => {}
			) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantFaviconLogo`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.details.feviconLogoPath = json.faviconLogoPath;
							getEnv(self).notifier.enqueue(
								"Favicon Logo Successfully Uploaded!!",
								"success"
							);
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
		};
	});
