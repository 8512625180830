import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const CertificationsItem = types.model({
	id: types.identifierNumber,
	intervention: types.string,
});

const CertificationItem = types.model({
	clinicianCertificationInfoId: types.maybeNull(types.number),
	clinicianId: types.maybeNull(types.number),
	certificationId: types.number,
	certification: types.maybeNull(types.string),
	certified: types.maybeNull(types.boolean),
	dateOfCertification: types.maybeNull(types.string),
	training: types.maybeNull(types.boolean),
	dateOfTraining: types.maybeNull(types.string),
});

export const Certification = types
	.model({
		all: types.array(CertificationsItem),
		items: types.array(CertificationItem),
		currentId: types.maybeNull(types.number),
		disabledItems: types.optional(
			types.model({
				certified: types.optional(types.boolean, true),
				training: types.optional(types.boolean, true),
			}),
			{
				certified: true,
				training: true,
			}
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) =>
							item.clinicianCertificationInfoId === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			updateDisabledItemsOnEditAdd() {
				let tempDisabledItems = {
					certified: true,
					training: true,
				};
				if (self.current?.training) {
					tempDisabledItems.training = false;
				} else {
					tempDisabledItems.training = true;
				}
				if (self.current?.certified) {
					tempDisabledItems.certified = false;
				} else {
					tempDisabledItems.certified = true;
				}
				self.disabledItems = tempDisabledItems;
			},
			updateDisabledItems(value) {
				self.disabledItems = { ...self.disabledItems, ...value };
			},
			loadAll: flow(function* loadAll(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinician/getAllCertifications`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();

					if (response.status === 200) {
						self.all = json;
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			load: flow(function* load(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinician/getCertificationByClinicianId?id=${id}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();

					if (response.status === 200) {
						self.items = json;
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			remove: flow(function* remove(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinician/deleteClinicianCertificationById?id=${id}`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();
					if (response.status === 200) {
						self.deleteCertification(id);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			deleteCertification(id) {
				self.currentId = null;
				destroy(
					self.items.find(
						(item) => item.clinicianCertificationInfoId === id
					)
				);
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},

			updateCertificationListItem(data) {
				if (
					self.items.find(
						(item) =>
							item.clinicianCertificationInfoId ===
							data.clinicianCertificationInfoId
					)
				) {
					applySnapshot(
						self.items.find(
							(item) =>
								item.clinicianCertificationInfoId ===
								data.clinicianCertificationInfoId
						),
						data
					);
				}
			},
			save: flow(function* save(data, snackbar, clinicianId) {
				if (self.current) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinician/updateClinicianCertification`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							self.updateCertificationListItem(json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinician/addClinicianCertification`,
							getRequestOptions("POST", {
								...data,
								clinicianId: clinicianId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.addCertificationListItem(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			addCertificationListItem(data) {
				self.items.push(data);
			},
		};
	});
