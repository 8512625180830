import { types, flow, getEnv, applySnapshot, destroy } from "mobx-state-tree";
import { getRequestOptions } from "../../utils/RequestOptions";
import {
	CONTENT_TYPE,
	ROOT_URL,
	API_ERROR_MESSAGE,
	RESPONSE_STATUS,
} from "../../constants";
import { jsonToFormData } from "../../helpers";

import {
	getStructuredAppleSubsDetails,
	getStructuredClientSubsDetails,
} from "../../utils/Utilities";
import { fetchApi } from "../../utils/CustomFetch";

const ClientSubscriptionPlans = types.model({
	appSubscriptionPlanID: types.maybeNull(types.number),
	isSelectedForPurchase: types.maybeNull(types.boolean),
	amount: types.maybeNull(types.number),
	frequency: types.maybeNull(types.number),
	subscriptionName: types.maybeNull(types.string),
	appSubscriptionID: types.maybeNull(types.number),
	appSubscriptionIDs: types.maybeNull(types.array(types.number)),
	categoryName: types.maybeNull(types.string),
	duration: types.maybeNull(types.number),
	clientType: types.maybeNull(types.number),
	isSkipTrial: types.maybeNull(types.boolean),
	clientID: types.maybeNull(types.number),
	trialDuration: types.maybeNull(types.number),
	trialFrequency: types.maybeNull(types.number),
	isPaymentDone: types.maybeNull(types.boolean),
});

const AppleClientSubscriptionPlans = types.model({
	appleHealthSubscriptionPlanID: types.maybeNull(types.number),
	isSelectedForPurchase: types.maybeNull(types.boolean),
	amount: types.maybeNull(types.number),
	frequency: types.maybeNull(types.number),
	subscriptionName: types.maybeNull(types.string),
	appleHealthGroupID: types.maybeNull(types.number),
	groupName: types.maybeNull(types.string),
	duration: types.maybeNull(types.number),
	// clientType: types.maybeNull(types.number),
	isSkipTrial: types.maybeNull(types.boolean),
	tenantID: types.maybeNull(types.number),
	isPaymentDone: types.maybeNull(types.boolean),
});

const LmsItem = types.model({
	lmsId: types.maybeNull(types.number),
	lmsName: types.maybeNull(types.string),
	lmsContactId: types.maybeNull(types.number),
	tenantId: types.maybeNull(types.number),
	primaryContactName: types.maybeNull(types.string),
	primaryContactPhone: types.maybeNull(types.string),
	primaryContactEmail: types.maybeNull(types.string),
	clientSubscriptionPlans: types.maybeNull(
		types.array(ClientSubscriptionPlans)
	),
	appleHealthAtttibuteIds: types.maybeNull(types.array(types.number)),
	appleClientSubscriptionPlan: types.maybeNull(
		types.array(AppleClientSubscriptionPlans)
	),
});

const TenantItem = types.model({
	mainLogoPath: types.maybeNull(types.string),
	faviconLogoPath: types.maybeNull(types.string),
	headerLogoPath: types.maybeNull(types.string),
	lightThemeColor: types.maybeNull(types.string),
	darkThemeColor: types.maybeNull(types.string),
	adminEmail: types.maybeNull(types.string),
});

export const Lms = types
	.model({
		items: types.array(LmsItem),
		tenant: types.maybeNull(TenantItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.lmsId === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load() {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/LMS/getAllLMS`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = JSON.parse(JSON.stringify(json))?.map(
							(item) => {
								if (item?.clientSubscriptionPlans) {
									item.clientSubscriptionPlans =
										getStructuredClientSubsDetails(
											item.clientSubscriptionPlans
										);
								}
								if (
									item?.appleClientSubscriptionPlanResponse
										?.length
								) {
									item.appleClientSubscriptionPlan =
										getStructuredAppleSubsDetails(
											item?.appleClientSubscriptionPlanResponse
										);
								}
								return item;
							}
						);
						self.status = "done";
					} else {
						self.status = "error";
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					self.status = "error";
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
			remove: flow(function* remove(lmsId) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/LMS/deleteLMSById?id=${lmsId}`,
						getRequestOptions("POST", {
							id: lmsId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						const lms = self.items.find(
							(item) => item.lmsId === lmsId
						);
						destroy(lms);
						getEnv(self).notifier.enqueue(
							"Record Successfully Deleted!!",
							"success"
						);
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			save: flow(function* save(data) {
				if (self.currentId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/LMS/updateLms`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							json.clientSubscriptionPlans =
								getStructuredClientSubsDetails(
									json.clientSubscriptionPlans
								);
							json.appleClientSubscriptionPlan =
								getStructuredAppleSubsDetails(
									json?.appleClientSubscriptionPlanResponse
								);
							applySnapshot(
								self.items.find(
									(item) => item.lmsId === json.lmsId
								),
								json
							);
							getEnv(self).notifier.enqueue(
								"Record Successfully Updated!!",
								"success"
							);
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
						}
					} catch (e) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/LMS/createLms`,
							getRequestOptions("POST", {
								...data,
								deleted: false,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							json.clientSubscriptionPlans =
								getStructuredClientSubsDetails(
									json.clientSubscriptionPlans
								);
							json.appleClientSubscriptionPlan =
								getStructuredAppleSubsDetails(
									json?.appleClientSubscriptionPlanResponse
								);
							self.items.push(json);
							getEnv(self).notifier.enqueue(
								"Record Successfully Created!!",
								"success"
							);
							self.updateCurrentItem(json.lmsId);
							self.updateContentType(CONTENT_TYPE.EDIT);
						} else {
							getEnv(self).notifier.enqueue(json.error, "error");
						}
					} catch (e) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				}
			}),
			loadTheme: flow(function* loadTheme(clientId, tenantId) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getTenantThemeColor?clientId=${clientId}&tenantId=${tenantId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.tenant = { ...self.tenant, ...json };
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			loadMainLogo: flow(function* loadMainLogo(clientId, tenantId) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getTenantMainLogo?clientId=${clientId}&tenantId=${tenantId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.tenant = { ...self.tenant, ...json };
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			loadHeaderLogo: flow(function* loadHeaderLogo(clientId, tenantId) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getTenantHeaderLogo?clientId=${clientId}&tenantId=${tenantId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.tenant = { ...self.tenant, ...json };
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			loadFaviconLogo: flow(function* loadFaviconLogo(
				clientId,
				tenantId
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getTenantFaviconLogo?clientId=${clientId}&tenantId=${tenantId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.tenant = { ...self.tenant, ...json };
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			saveTheme: flow(function* saveTheme(data, callback = () => {}) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantThemeColor`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.tenant = { ...self.tenant, ...json };
						getEnv(self).notifier.enqueue(
							"Theme Successfully Uploaded!!",
							"success"
						);
						callback();
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			uploadMainLogo: flow(function* uploadMainLogo(
				data,
				callback = () => {}
			) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantMainLogo`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.tenant = { ...self.tenant, ...json };
							getEnv(self).notifier.enqueue(
								"Main Logo Successfully Uploaded!!",
								"success"
							);
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
			uploadHeaderLogo: flow(function* uploadHeaderLogo(
				data,
				callback = () => {}
			) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantHeaderLogo`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.tenant = { ...self.tenant, ...json };
							getEnv(self).notifier.enqueue(
								"Header Logo Successfully Uploaded!!",
								"success"
							);
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
			uploadFavicon: flow(function* uploadFavicon(
				data,
				callback = () => {}
			) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/addTenantFaviconLogo`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							self.tenant = { ...self.tenant, ...json };
							getEnv(self).notifier.enqueue(
								"Favicon Logo Successfully Uploaded!!",
								"success"
							);
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
		};
	});
