import { enqueueSnackbar } from "notistack";
import { API_ERROR_MESSAGE, RESPONSE_STATUS } from "../constants";

export const fetchApi = async (url, options) => {
	try {
		const response = await fetch(url, options);
		if (response.status === RESPONSE_STATUS.UNAUTHORISED) {
			enqueueSnackbar(response?.statusText, { variant: "error" });
			window.location.reload();
		}
		return response;
	} catch (error) {
		enqueueSnackbar(API_ERROR_MESSAGE, { variant: "error" });
	}
};
