import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const IntendedAudienceItem = types.model({
	id: types.number,
	intendedAudienceId: types.number,
	audience: types.string,
});

export const IntendedAudience = types
	.model({
		items: types.array(IntendedAudienceItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllIntendedAudience`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json.map((item) => {
							item.intendedAudienceId = item.id;
							return item;
						});
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
		};
	});
