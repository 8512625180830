import { flow, getEnv, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const TenantItem = types.model({
	tenantID: types.maybeNull(types.number),
	subDomain: types.maybeNull(types.string),
	tenantName: types.maybeNull(types.string),
	clientName: types.maybeNull(types.string),
	clientSNo: types.maybeNull(types.string),
	clientID: types.maybeNull(types.number),
	clientType: types.maybeNull(types.number),
});

export const Invite = types
	.model({
		status: types.maybeNull(
			types.enumeration("status", ["pending", "done", "error"])
		),
		tenants: types.array(TenantItem),
	})
	.actions((self) => {
		return {
			send: flow(function* send(data, snackbar, callback) {
				let payload = {
					...data,
					userRoleRegistrationRequest: [],
				};

				data.roles?.forEach((item) => {
					payload.userRoleRegistrationRequest.push({
						roleId: item?.id,
						assignId: data?.assignId ? data?.assignId : null,
					});
				});

				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/register`,
						getRequestOptions("POST", payload)
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status === 0) {
							self.status = "done";
							snackbar.showMessage(
								"Invite has been Sent",
								null,
								null,
								{
									severity: "success",
								}
							);
							callback();
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
							self.status = "error";
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),

			loadTenantsByRole: flow(function* loadTenantsByRole(roles) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/getAllTenantsBasedOnRole`,
						getRequestOptions("POST", { roles: roles })
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.tenants = json;
						self.status = "done";
					} else {
						self.status = "error";
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					self.status = "error";
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
		};
	});
