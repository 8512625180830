import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, CONTENT_TYPE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const RoleItem = types.model({
	id: types.number,
	//roleId: types.number,
	title: types.string,
	description: types.maybeNull(types.string),
	active: types.maybeNull(types.boolean),
	roleType: types.maybeNull(types.number),
	tenantId: types.maybeNull(types.number),
});

const ResourceItem = types.model({
	id: types.number,
	title: types.string,
	parentId: types.maybeNull(types.number),
	path: types.maybeNull(types.string),
	icon: types.maybeNull(types.string),
	type: types.maybeNull(types.string),
	permission: types.maybeNull(types.boolean),
	children: types.array(types.late(() => ResourceItem)),
});

export const Roles = types
	.model({
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		items: types.array(RoleItem),
		resources: types.array(ResourceItem),
		status: types.maybeNull(
			types.enumeration("status", ["pending", "done", "error"])
		),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.id === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			load: flow(function* load(tenantID, snackbar, doNotLoadResources) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/getRoles`,
						getRequestOptions("GET", null)
					);

					const json = yield response.json();
					if (response.status === 200) {
						self.items = json.map((item) => {
							return { ...item, roleId: item.id };
						});
						if (json.length > 0) {
							self.updateCurrentItem(
								json[0].id,
								tenantID,
								snackbar,
								doNotLoadResources
							);
						}
						self.status = "done";
					} else {
						snackbar.showMessage(response.statusText, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			updateResource: flow(function* updateResource(
				data,
				userID,
				granted,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/updateTenantResourcePermission`,
						getRequestOptions("POST", {
							resourceID: data.id,
							userID,
							roleID: self.currentId,
							isPermissionGrant: granted,
						})
					);

					//const json = yield response.json();
					if (response.status === 200) {
						//self.resources = json;
					} else {
						snackbar.showMessage(response.statusText, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(error, null, null, {
						severity: "error",
					});
				}
			}),
			loadResourcesByRoleId: flow(function* loadResourcesByRoleId(
				id,
				tenantID,
				snackbar
			) {
				console.log("tenantID", tenantID);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/getResourcesByRoleIdAndTenatID?roleId=${id}&tenantID=${tenantID}`,
						getRequestOptions("GET", null)
					);

					const json = yield response.json();
					if (response.status === 200) {
						self.resources = json;
					} else {
						snackbar?.showMessage(response.statusText, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			updateCurrentItem(id, tenantID, snackbar, doNotLoadResources) {
				self.currentId = id;
				if (id && !doNotLoadResources) {
					self.loadResourcesByRoleId(id, tenantID, snackbar);
				}
			},
			updateRoleListItem(data) {
				applySnapshot(
					self.items.find((item) => item.id === data.id),
					{ ...data }
				);
			},
			addRoleListItem(data) {
				self.items.push(data);
			},
			save: flow(function* save(data, snackbar) {
				if (self.current) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/admin/UpdateRole`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.updateRoleListItem(json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);

							self.updateContentType(CONTENT_TYPE.LIST);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						console.log(e);
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/admin/CreateRole`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.addRoleListItem(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							self.updateContentType(CONTENT_TYPE.LIST);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			remove: flow(function* remove(id) {
				yield fetchApi(
					`${ROOT_URL}/api/admin/DeleteRole?RoleId=${id}`,
					getRequestOptions("POST", {})
				);
				self.deleteRole(id);
			}),
			deleteRole(id) {
				self.currentId = null;
				destroy(self.items.find((item) => item.id === id));
			},
		};
	});
