import { applySnapshot, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { blobToBase64 } from "../../helpers";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const NotificationItem = types.model({
	createdDate: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
	isRead: types.maybeNull(types.boolean),
	message: types.maybeNull(types.string),
	readDate: types.maybeNull(types.string),
	sNo: types.maybeNull(types.string),
	sentBy: types.maybeNull(types.number),
	sentTo: types.maybeNull(types.number),
	subject: types.maybeNull(types.string),
	sentUserName: types.maybeNull(types.string),
});

const SenderHeadshotItem = types.model({
	senderId: types.maybeNull(types.number),
	headshot: types.maybeNull(types.string),
});

let notificationTimeout;

export const Notification = types
	.model({
		items: types.array(NotificationItem),
		history: types.array(NotificationItem),
		headshots: types.optional(types.array(SenderHeadshotItem), []),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					if (self.items.some((item) => item.id === self.currentId)) {
						return self.items.find(
							(item) => item.id === self.currentId
						);
					} else {
						return self.history.find(
							(item) => item.id === self.currentId
						);
					}
				} else {
					return null;
				}
			},
			get totalNewMesagges() {
				return self.items.filter((item) => !item.isRead).length;
			},
			get newMessages() {
				return self.items.filter((item) => !item.isRead);
			},
			headshot(senderId) {
				return self.headshots.find((item) => item.senderId === senderId)
					?.headshot;
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				try {
					var requestOptions = getRequestOptions("GET", null);
					if (requestOptions.headers.Authorization.indexOf('null') < 0) {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Notification/getTop10NotificationListByUserId`,
							requestOptions
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.stopRefreshTokenTimer();
							self.items = data;
							self.startRefreshTokenTimer(snackbar);
							self.generateSenderHeadshots(data, snackbar);
						} else {
							self.stopRefreshTokenTimer();
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					}
				} catch (error) {
					self.stopRefreshTokenTimer();
					// snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					// 	severity: "error",
					// });
				}
			}),
			loadHistory: flow(function* loadHistory(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Notification/getNotificationListByUserId`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.history = data;
						self.generateSenderHeadshots(data, snackbar);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			markAllAsRead: flow(function* markAllAsRead(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Notification/markAllNotificationRead`,
						getRequestOptions("POST", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = data;
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			markAsUnread: flow(function* markAsUnread(message, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Notification/markNotificationUnReadById`,
						getRequestOptions("POST", {
							id: message.id,
							sNo: message.sNo,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(message, data);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			markAsRead: flow(function* markAsRead(message, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Notification/markNotificationReadById`,
						getRequestOptions("POST", {
							id: message.id,
							sNo: message.sNo,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(message, data);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			generateSenderHeadshots(data, snackbar) {
				self.headshots = [];
				data.forEach(
					(item) =>
						item.sentBy && self.loadHeadshot(item.sentBy, snackbar)
				);
			},
			loadHeadshot: flow(function* loadHeadshot(userId, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getHeadShotStreamByUserId?userId=${userId}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						const base64 = yield blobToBase64(blob);
						self.headshots.push({
							senderId: userId,
							headshot: base64,
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			updateCurrentItem(id, snackbar) {
				self.currentId = id;
				self.markAsRead(self.current, snackbar);
			},
			startRefreshTokenTimer(snackbar) {
				let timeout = 900000;
				if (timeout < 0) {
					timeout = 0;
				}
				notificationTimeout = setTimeout(() => {
					self.load(snackbar);
				}, timeout);
			},
			stopRefreshTokenTimer() {
				clearTimeout(notificationTimeout);
			},
		};
	});
