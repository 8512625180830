import { flow, types } from "mobx-state-tree";
import { ROOT_URL } from "../../constants";
import { fetchApi } from "../../utils/CustomFetch";

const PracticeTypeItem = types.model({
	id: types.identifierNumber,
	type: types.string,
});

export const PracticeType = types
	.model({
		items: types.array(PracticeTypeItem),
		fetch_all_status: types.optional(
			types.enumeration("State", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		let controller;

		return {
			afterCreate() {
				self.load();
			},
			load: flow(function* load() {
				controller = AbortController && new AbortController();
				self.fetch_all_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getAllPracticeTypes`,
						{
							signal: controller && controller.signal,
						}
					);
					self.items = yield response.json();
					self.fetch_all_status = "done";
				} catch (error) {
					console.error("Failed to fetchApi practice types", error);
					self.fetch_all_status = "error";
				}
			}),
		};
	});
