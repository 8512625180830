import { flow, getEnv, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const CertificateConsultantItem = types.model({
	certificateConsultantId: types.number,
	certificateConsultantSNo: types.maybeNull(types.string),
	certificateConsultantPrefix: types.maybeNull(types.string),
	certificateConsultantFirstName: types.maybeNull(types.string),
	certificateConsultantMiddleName: types.maybeNull(types.string),
	certificateConsultantLastName: types.maybeNull(types.string),
	certificateConsultantPhone: types.maybeNull(types.string),
	certificateConsultantEmail: types.maybeNull(types.string),
	certificateConsultantName: types.maybeNull(types.string),
});

export const CertificateConsultant = types
	.model({
		items: types.array(CertificateConsultantItem),
	})
	.views((self) => {
		return {
			name(item) {
				if (item) {
					return `${
						item.certificateConsultantPrefix
							? item.certificateConsultantPrefix
							: ""
					} ${
						item.certificateConsultantFirstName
							? item.certificateConsultantFirstName
							: ""
					} ${
						item.certificateConsultantMiddleName
							? item.certificateConsultantMiddleName
							: ""
					} ${
						item.certificateConsultantLastName
							? item.certificateConsultantLastName
							: ""
					}`;
				}
			},
			nameWithEmail(item) {
				if (item) {
					return `${
						item.certificateConsultantPrefix
							? item.certificateConsultantPrefix
							: ""
					} ${
						item.certificateConsultantFirstName
							? item.certificateConsultantFirstName
							: ""
					} ${
						item.certificateConsultantMiddleName
							? item.certificateConsultantMiddleName
							: ""
					} ${
						item.certificateConsultantLastName
							? item.certificateConsultantLastName
							: ""
					} ${
						item.certificateConsultantEmail
							? "( " + item.certificateConsultantEmail + " )"
							: ""
					}`;
				} else {
					return "";
				}
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load() {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/getAllCertificateConsultants`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();

					if (response.status === 200) {
						self.items = json;
					} else {
						getEnv(self).notifier.enqueue(json.error, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
		};
	});
