import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { blobToBase64, getFeedbackThreads } from "../../helpers";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const FeedbackItem = types.model({
	feedbackId: types.number,
	feedbackSNo: types.string,
	parentType: types.maybeNull(types.number),
	parentId: types.maybeNull(types.number),
	parentSNo: types.maybeNull(types.string),
	uploadType: types.maybeNull(types.string),
	uploadId: types.maybeNull(types.number),
	feedbackText: types.maybeNull(types.string),
	statusAcknowledgement: types.maybeNull(types.boolean),
	feedbackStatus: types.maybeNull(types.boolean),
	feedbackType: types.maybeNull(types.boolean),
	feedbackBy: types.maybeNull(types.number),
	feedbackByName: types.maybeNull(types.string),
	feedbackByEmail: types.maybeNull(types.string),
	createDate: types.maybeNull(types.string),
	expectedDate: types.maybeNull(types.string),
	deliverAt:types.maybeNull(types.string),
	seenAt:types.maybeNull(types.string),
});

const SenderHeadshotItem = types.model({
	senderId: types.maybeNull(types.number),
	headshot: types.maybeNull(types.string),
});

export const Feedback = types
	.model({
		history: types.array(FeedbackItem),
		items: types.array(FeedbackItem),
		headshots: types.optional(types.array(SenderHeadshotItem), []),
	})
	.views((self) => {
		return {
			getThreads(tree) {
				let threads = [];
				threads = getFeedbackThreads(self.items, tree);
				return threads;
			},
			headshot(senderId) {
				return self.headshots.find((item) => item.senderId === senderId)
					?.headshot;
			},
		};
	})
	.actions((self) => {
		return {
			loadByCourse: flow(function* loadByCourse(
				courseId,
				courseSNo,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getFeedbackHistoryByCourseId?courseId=${courseId}&courseSNo=${courseSNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json;
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			load: flow(function* load(
				courseId,
				courseSNo,
				id,
				sNo,
				type,
				snackbar,
				uploadType,
				uploadId
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getFeedbackHistory?courseId=${courseId}&courseSNo=${courseSNo}&parentId=${id}&parentSNo=${sNo}&parentType=${type}&uploadType=${
							uploadType ? uploadType : ""
						}&uploadId=${uploadId ? uploadId : ""}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.history = json;
						self.loadByCourse(courseId, courseSNo, snackbar);
						self.generateSenderHeadshots(json, snackbar);
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			addFeedback: flow(function* addFeedback(
				feedback,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addFeedback`,
						getRequestOptions("POST", feedback)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.history.push(data);
						self.items.push(data);
						callback();
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			generateSenderHeadshots(data, snackbar) {
				self.headshots = [];
				let fetchedUsers = [];
				data.forEach((item) => {
					if (
						item.feedbackBy &&
						!fetchedUsers.includes(item.feedbackBy)
					) {
						self.loadHeadshot(item.feedbackBy, snackbar);
						fetchedUsers.push(item.feedbackBy);
					}
				});
			},
			loadHeadshot: flow(function* loadHeadshot(userId, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/UserProfile/getHeadShotStreamByUserId?userId=${userId}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						const base64 = yield blobToBase64(blob);
						self.headshots.push({
							senderId: userId,
							headshot: base64,
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			sendFeedback: flow(function* sendFeedback(
				feedback,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/sendFeedback`,
						getRequestOptions("POST", feedback)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						snackbar.showMessage("Feedback Submited", null, null, {
							severity: "success",
						});
						callback();
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
		};
	});
