import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const RoleItem = types.model({
	roleId: types.number,
	userId: types.number,
	roleTitle: types.string,
	userName: types.string,
});

const CourseRoleItem = types.model({
	courseId: types.number,
	courseSNo: types.string,
	feedbackDuration: types.number,
	courseRoleList: types.array(RoleItem),
});

const UserItem = types.model({
	userId: types.maybeNull(types.number),
	userPrefix: types.maybeNull(types.string),
	userFirstName: types.maybeNull(types.string),
	userMiddleName: types.maybeNull(types.string),
	userLastName: types.maybeNull(types.string),
	userPhone: types.maybeNull(types.string),
	userEmail: types.maybeNull(types.string),
});

export const CourseRoles = types
	.model({
		details: types.maybeNull(CourseRoleItem),
		users: types.array(UserItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		return {
			load: flow(function* load(course, snackbar, callback) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCourseRoles?courseId=${course?.id}&courseSNo=${course?.sNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.details = json;
						callback();
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			save: flow(function* save(data, snackbar, callback) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addCourseRoles`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.details = json;
						snackbar.showMessage(
							"Activity Roles Saved Successfully",
							null,
							null,
							{
								severity: "success",
							}
						);
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),
			getUserByRole: flow(function* getUserByRole(
				role,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/user/getUserByRoleId?roleId=${role}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.users = json;
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
		};
	});
