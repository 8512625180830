import { css } from "@emotion/react";
import styled from "@emotion/styled";

// Define the global styles using Emotion's css function
const globalStyles = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
  }
  body {
    height: 100%;
    width: 100%;
  }
  #root {
    height: 100%;
    width: 100%;
  }
`;

// Create a styled component for the GlobalStyles
const GlobalStyles = styled.div`
  ${globalStyles};
`;

export default GlobalStyles;
