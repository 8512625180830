import { applySnapshot, destroy, flow, getEnv, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const AnswerItem = types.model({
	id: types.maybeNull(types.number),
	answerName: types.maybeNull(types.string),
	weightage: types.maybeNull(types.number),
	createdDate: types.maybeNull(types.string),
	sequence: types.maybeNull(types.number),
	correctAnswer: types.maybeNull(types.number),
	edit: types.optional(types.boolean, false),
	new: types.optional(types.boolean, false),
});

const QuestionItem = types.model({
	id: types.maybeNull(types.number),
	questionName: types.maybeNull(types.string),
	checkbox: types.maybeNull(types.boolean),
	radio: types.maybeNull(types.boolean),
	textbox: types.maybeNull(types.boolean),
	switch: types.maybeNull(types.boolean),
	isCritical: types.maybeNull(types.boolean),
	isReverseScore: types.maybeNull(types.boolean),
	createdDate: types.maybeNull(types.string),
	questionFactor: types.array(
		types.model({ factorId: types.maybeNull(types.number) })
	),
	singleSelect: types.maybeNull(types.boolean),
	multiSelect: types.maybeNull(types.boolean),
	answers: types.array(AnswerItem),
	label: types.optional(types.boolean, false),
	edit: types.optional(types.boolean, false),
	new: types.optional(types.boolean, false),
});

const SectionItem = types.model({
	id: types.maybeNull(types.number),
	surveyId: types.maybeNull(types.number),
	name: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	questions: types.array(QuestionItem),
	pagebreak: types.optional(types.boolean, false),
	edit: types.optional(types.boolean, false),
	new: types.optional(types.boolean, false),
});

const SurveyItem = types.model({
	copyright: types.maybeNull(types.string),
	createdDate: types.string,
	id: types.number,
	surveyId: types.maybeNull(types.number),
	surveyName: types.string,
	sections: types.array(SectionItem),
});

const PatientAnswerItem = types.model({
	answerId: types.maybeNull(types.number),
	answerName: types.maybeNull(types.string),
	weightage: types.maybeNull(types.number),
	sequence: types.maybeNull(types.number),
	selected: types.maybeNull(types.boolean),
});

const PatientQuestionItem = types.model({
	questionId: types.maybeNull(types.number),
	questionName: types.maybeNull(types.string),
	checkbox: types.maybeNull(types.boolean),
	radio: types.maybeNull(types.boolean),
	textbox: types.maybeNull(types.boolean),
	switch: types.maybeNull(types.boolean),
	singleSelect: types.maybeNull(types.boolean),
	multiSelect: types.maybeNull(types.boolean),
	answers: types.array(PatientAnswerItem),
	label: types.maybeNull(types.boolean),
	textAnswer: types.maybeNull(types.string),
	submitted: types.maybeNull(types.boolean),
});

const PatientSectionItem = types.model({
	sectionId: types.maybeNull(types.number),
	name: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	questions: types.array(PatientQuestionItem),
	pagebreak: types.maybeNull(types.boolean),
});

const PatientSurveyItem = types.model({
	surveyId: types.maybeNull(types.number),
	patientId: types.maybeNull(types.number),
	surveyName: types.maybeNull(types.string),
	dueDate: types.maybeNull(types.string),
	assignedDate: types.maybeNull(types.string),
	preReminder: types.maybeNull(types.number),
	postReminder: types.maybeNull(types.number),
	isSubmitted: types.maybeNull(types.boolean),
	submittedDate: types.maybeNull(types.string),
	sections: types.array(PatientSectionItem),
});

export const SurveyCA = types
	.model({
		items: types.array(SurveyItem),
		filledPatientSurveys: types.array(PatientSurveyItem),
		pendingPatientSurveys: types.array(PatientSurveyItem),
		surveySettings: types.maybeNull(
			types.model({
				clinician: types.array(
					types.model({ clinicianId: types.number })
				),
				preReminder: types.maybeNull(types.number),
				postReminder: types.maybeNull(types.number),
			})
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
		status: types.maybeNull(
			types.enumeration("status", ["pending", "done", "error"])
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.id === self.currentId
					);
				} else {
					return null;
				}
			},
			get pages() {
				const current = self.items.find(
					(item) => item.id === self.currentId
				);
				let pages = [];
				let page = { sections: [] };
				current.sections.forEach((section) => {
					page.sections.push(section);
					if (section.pagebreak) {
						pages.push(page);
						page = { sections: [] };
					}
				});

				if (page.sections.length > 0) {
					pages.push(page);
				}

				return pages;
			},
			viewpages(survey) {
				const current = survey;
				let pages = [];
				let page = { sections: [] };
				current.sections.forEach((section) => {
					page.sections.push(section);
					if (section.pagebreak) {
						pages.push(page);
						page = { sections: [] };
					}
				});

				if (page.sections.length > 0) {
					pages.push(page);
				}

				return pages;
			},
			getQuestions(sectionId) {
				if (sectionId && self.currentId) {
					return self.items
						.find((item) => item.id === self.currentId)
						.sections.find((section) => section.id === sectionId)
						.questions;
				} else {
					return [];
				}
			},
			getAnswerType(question) {
				if (question.radio) {
					return "Radio";
				} else if (question.switch) {
					return "Switch";
				} else if (question.singleSelect) {
					return "Single Select Dropdown";
				} else if (question.multiSelect) {
					return "Multi Select Dropdown";
				} else if (question.textbox) {
					return "Text";
				} else if (question.checkbox) {
					return "Checkbox";
				}
			},
			getBooleanValuesForAnswerType(type) {
				let question = {
					radio: false,
					switch: false,
					singleSelect: false,
					multiSelect: false,
					textbox: false,
					checkbox: false,
				};
				switch (type) {
					case "Radio":
						question.radio = true;
						question.switch = false;
						question.singleSelect = false;
						question.multiSelect = false;
						question.textbox = false;
						question.checkbox = false;
						break;
					case "Switch":
						question.radio = false;
						question.switch = true;
						question.singleSelect = false;
						question.multiSelect = false;
						question.textbox = false;
						question.checkbox = false;
						break;
					case "Single Select Dropdown":
						question.radio = false;
						question.switch = false;
						question.singleSelect = true;
						question.multiSelect = false;
						question.textbox = false;
						question.checkbox = false;
						break;
					case "Multi Select Dropdown":
						question.radio = false;
						question.switch = false;
						question.singleSelect = false;
						question.multiSelect = true;
						question.textbox = false;
						question.checkbox = false;
						break;
					case "Text":
						question.radio = false;
						question.switch = false;
						question.singleSelect = false;
						question.multiSelect = false;
						question.textbox = true;
						question.checkbox = false;
						break;
					case "Checkbox":
						question.radio = false;
						question.switch = false;
						question.singleSelect = false;
						question.multiSelect = false;
						question.textbox = false;
						question.checkbox = true;
						break;
					default:
						break;
				}

				return question;
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getAllSurveyForClinicAdmin`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === 200) {
						self.items = json.map((item) => {
							item.surveyId = item.id;
							return item;
						});
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),

			loadById: flow(function* loadById(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getSurveyById?id=${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find((item) => item.id === id),
							data
						);
						self.currentId = id;
						self.updateContentType(CONTENT_TYPE.EDIT);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			cloneAnswersFromQuestion: flow(function* cloneAnswersFromQuestion(
				data,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/cloneAnswersFromQuestion`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.current, json);
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			loadCliniciansById: flow(function* loadCliniciansById(
				id,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getClinicianBySurveyId?surveyId=${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.surveySettings = {
							...data,
							clinician: data.clinician ? data.clinician : [],
						};
						callback();
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			assignSurveyToClinician: flow(function* assignSurveyToClinician(
				data,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/assignSurveyToClinician`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.surveySettings = json;
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			getFilledSurveyByPatientId: flow(
				function* getFilledSurveyByPatientId(id, snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/getFilledSurveyByPatientId?patientId=${id}`,
							getRequestOptions("GET", null)
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.filledPatientSurveys = data;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),
			getPendingSurveyByPatientId: flow(
				function* getPendingSurveyByPatientId(id, snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/getPendingSurveyByPatientId?patientId=${id}`,
							getRequestOptions("GET", null)
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.pendingPatientSurveys = data;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),

			updatePatientQuestionAnswer: flow(
				function* updatePatientQuestionAnswer(data, snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/updatePatientQuestionAnswer`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status !== RESPONSE_STATUS.SUCCESS) {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),
			getAssignedSurveyByPatientnSurveyId: flow(
				function* getAssignedSurveyByPatientnSurveyId(
					patientId,
					surveyId,
					snackbar
				) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/getAssignedSurveyByPatientnSurveyId?patientId=${patientId}&surveyId=${surveyId}`,
							getRequestOptions("GET", null)
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							return { status: 200, data: data };
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
							return { status: 400, data: null };
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						return { status: 500, data: null };
					}
				}
			),
			submitSurveyByPatientnSurveyId: flow(
				function* submitSurveyByPatientnSurveyId(
					patientId,
					surveyId,
					snackbar
				) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/submitSurveyByPatientnSurveyId`,
							getRequestOptions("POST", {
								patientId: patientId,
								surveyId: surveyId,
							})
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							snackbar.showMessage(
								"Survey Submitted",
								null,
								null,
								{
									severity: "success",
								}
							);
							return { status: 200, data: data };
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
							return { status: 400, data: null };
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						return { status: 500, data: null };
					}
				}
			),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
			updatePageBreak(sectionId, value, snackbar) {
				let section = self.current.sections.find(
					(section) => section.id === sectionId
				);
				section.pagebreak = value;
				self.updateSection(snackbar, sectionId);
			},
			addQuestion(sectionId) {
				let questions = self.current.sections.find(
					(section) => section.id === sectionId
				).questions;
				questions.push({
					id: 0,
					edit: true,
					new: true,
				});
			},
			addAnswer(sectionId, questionId) {
				let answers = self.current.sections
					.find((section) => section.id === sectionId)
					.questions.find(
						(question) => question.id === questionId
					).answers;
				answers.push({
					id: answers.length + 1,
					edit: true,
					new: true,
				});
			},

			updateQuestionName(sectionId, questionId, value) {
				let question = self.current.sections
					.find((section) => section.id === sectionId)
					.questions.find((question) => question.id === questionId);
				question.questionName = value;
			},
			updateSectionName(sectionId, value) {
				let section = self.current.sections.find(
					(section) => section.id === sectionId
				);
				section.name = value;
			},
			updateSectionDescription(sectionId, value) {
				let section = self.current.sections.find(
					(section) => section.id === sectionId
				);
				section.description = value;
			},
			updateAnswerWeightage(sectionId, questionId, answerId, value) {
				let answer = self.current.sections
					.find((section) => section.id === sectionId)
					.questions.find((question) => question.id === questionId)
					.answers.find((answer) => answer.id === answerId);
				try {
					answer.weightage = parseFloat(value);
				} catch (error) {
					answer.weightage = null;
				}
			},
			updateAnswerName(sectionId, questionId, answerId, value) {
				let answer = self.current.sections
					.find((section) => section.id === sectionId)
					.questions.find((question) => question.id === questionId)
					.answers.find((answer) => answer.id === answerId);
				answer.answerName = value;
			},
			editSection(sectionId, value) {
				let section = self.current.sections.find(
					(section) => section.id === sectionId
				);
				section.edit = value;
				section.new = false;
			},
			updateSection: flow(function* updateSection(snackbar, sectionId) {
				let section = self.current.sections.find(
					(section) => section.id === sectionId
				);

				if (!section.new) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/UpdateSection`,
							getRequestOptions("POST", {
								...section,
								surveyId: self.currentId,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							if (json.questions) {
								applySnapshot(section, json);
							} else {
								applySnapshot(section, {
									...json,
									questions: [],
								});
							}
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/CreateSection`,
							getRequestOptions("POST", section)
						);
						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							applySnapshot(section, { ...json, questions: [] });
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			editQuestion(sectionId, questionId, value) {
				let question = self.current.sections
					.find((section) => section.id === sectionId)
					.questions.find((question) => question.id === questionId);
				question.edit = value;
				question.new = false;
			},
			updateQuestion: flow(function* updateSection(
				snackbar,
				sectionId,
				questionId
			) {
				let question = self.current.sections
					.find((section) => section.id === sectionId)
					.questions.find((question) => question.id === questionId);

				if (!question.new) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/updateQuestion`,
							getRequestOptions("POST", {
								...question,
								sectionId: sectionId,
								answerRequest: question.answers,
								isCritical: question.isCritical ? true : false,
								isReverseScore: question.isReverseScore
									? true
									: false,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							applySnapshot(question, json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/createQuestion`,
							getRequestOptions("POST", {
								...question,
								sectionId: sectionId,
								answerRequest: question.answers,
								isCritical: question.isCritical ? true : false,
								isReverseScore: question.isReverseScore
									? true
									: false,
							})
						);
						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							applySnapshot(question, json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			editAnswer(sectionId, questionId, answerId, value) {
				let answer = self.current.sections
					.find((section) => section.id === sectionId)
					.questions.find((question) => question.id === questionId)
					.answers.find((answer) => answer.id === answerId);
				answer.edit = value;
				answer.new = false;
			},
			updateAnswer: flow(function* updateSection(
				snackbar,
				sectionId,
				questionId,
				answerId
			) {
				let question = self.current.sections
					.find((section) => section.id === sectionId)
					.questions.find((question) => question.id === questionId);
				let answer = question.answers.find(
					(answer) => answer.id === answerId
				);
				if (!answer.new) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/UpdateAnswer`,
							getRequestOptions("POST", {
								...answer,
								questionId: questionId,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							applySnapshot(answer, json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/addAnswerToQuestion`,
							getRequestOptions("POST", {
								...answer,
								questionId: questionId,
							})
						);
						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							applySnapshot(question, json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			deleteQuestion(sectionId, questionId) {
				let question = self.current.sections
					.find((section) => section.id === sectionId)
					.questions.find((question) => question.id === questionId);
				if (question.new) {
					destroy(question);
				} else {
					question.edit = false;
				}
			},
			deleteSection(sectionId) {
				let section = self.current.sections.find(
					(section) => section.id === sectionId
				);
				if (section.new) {
					destroy(section);
				} else {
					section.edit = false;
				}
			},
			deleteAnswer(sectionId, questionId, answerId) {
				let answer = self.current.sections
					.find((section) => section.id === sectionId)
					.questions.find((question) => question.id === questionId)
					.answers.find((answer) => answer.id === answerId);
				if (answer.new) {
					destroy(answer);
				} else {
					answer.edit = false;
				}
			},

			remove: flow(function* remove(survey) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/deleteSurveyById?id=${survey.id}`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(survey);
						getEnv(self).notifier.enqueue(
							"Record Successfully Deleted!!",
							"success"
						);
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			removeQuestion: flow(function* removeQuestion(
				sectionId,
				questionId,
				snackbar,
				deleteType
			) {
				try {
					let question = self.current.sections
						.find((section) => section.id === sectionId)
						.questions.find(
							(question) => question.id === questionId
						);
					let response;
					if (deleteType === "delete") {
						response = yield fetchApi(
							`${ROOT_URL}/api/Survey/deleteQuestionById?id=${question.id}`,
							getRequestOptions("POST", null)
						);
					} else {
						response = yield fetchApi(
							`${ROOT_URL}/api/Survey/deleteQuestionFromSurvey`,
							getRequestOptions("POST", {
								questionId: questionId,
								sectionId: sectionId,
							})
						);
					}

					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(question);
						snackbar.showMessage(
							"Record Successfully Created!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			removeSection: flow(function* removeSection(sectionId, snackbar) {
				try {
					let section = self.current.sections.find(
						(section) => section.id === sectionId
					);
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/DeleteSectionById?sectionId=${section.id}`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(section);
						snackbar.showMessage(
							"Record Successfully Created!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			removeAnswer: flow(function* removeAnswer(
				sectionId,
				questionId,
				answerId,
				snackbar
			) {
				try {
					let answer = self.current.sections
						.find((section) => section.id === sectionId)
						.questions.find(
							(question) => question.id === questionId
						)
						.answers.find((answer) => answer.id === answerId);
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/deleteAnswer`,
						getRequestOptions("POST", {
							questionId: questionId,
							answerId: answerId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(answer);
						snackbar.showMessage(
							"Record Successfully Created!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			addSection(surveyId) {
				self.current.sections.push({
					id: 0,
					surveyId: surveyId,
					new: true,
					edit: true,
					questions: [],
				});
			},
			updateSurveyListItem(survey) {
				self.current.surveyName = survey.surveyName;
			},
			addSurveyListItem(survey) {
				self.items.push(survey);
			},
			save: flow(function* save(data, snackbar) {
				if (self.current) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/updateSurvey`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							self.updateSurveyListItem(json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Survey/createSurveyByClinicAdmin`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.addSurveyListItem(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							self.updateCurrentItem(json.id);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			updateAnswerType(sectionId, questionId, type) {
				let answerTypeValues = self.getBooleanValuesForAnswerType(type);
				applySnapshot(
					self.current.sections
						.find((section) => section.id === sectionId)
						.questions.find(
							(question) => question.id === questionId
						),
					{
						...self.current.sections
							.find((section) => section.id === sectionId)
							.questions.find(
								(question) => question.id === questionId
							),
						...answerTypeValues,
					}
				);
			},
			updateFactor(sectionId, questionId, factors) {
				let factorIds = [];
				factors.forEach((factor) => {
					factorIds.push({ factorId: factor.id });
				});
				applySnapshot(
					self.current.sections
						.find((section) => section.id === sectionId)
						.questions.find(
							(question) => question.id === questionId
						),
					{
						...self.current.sections
							.find((section) => section.id === sectionId)
							.questions.find(
								(question) => question.id === questionId
							),
						questionFactor: factorIds,
					}
				);
			},
			updateCritical(sectionId, questionId, isCritical) {
				applySnapshot(
					self.current.sections
						.find((section) => section.id === sectionId)
						.questions.find(
							(question) => question.id === questionId
						),
					{
						...self.current.sections
							.find((section) => section.id === sectionId)
							.questions.find(
								(question) => question.id === questionId
							),
						isCritical: isCritical,
					}
				);
			},

			updateReverseScore(sectionId, questionId, isReverseScore) {
				applySnapshot(
					self.current.sections
						.find((section) => section.id === sectionId)
						.questions.find(
							(question) => question.id === questionId
						),
					{
						...self.current.sections
							.find((section) => section.id === sectionId)
							.questions.find(
								(question) => question.id === questionId
							),
						isReverseScore: isReverseScore,
					}
				);
			},

			updateCopyright(snackbar, copyright) {
				applySnapshot(self.current, {
					...self.current,
					copyright: copyright,
				});
			},
		};
	});
