export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const RESPONSE_STATUS = {
  SUCCESS: 200,
  SUCCESS_ZERO: 0,
  SERVER_ERROR: 500,
  UNAUTHORISED: 401,
  BAD_REQUEST: 400,
  NOT_FOUND: 404
};

export const ROOT_URL = document
  .getElementsByTagName("base")[0]
  .getAttribute("href");
export const DEFAULT_COUNTRY_ID = 233;

export const API_ERROR_MESSAGE =
  "Server not reachable, Please contact administrator";

export const CONTENT_TYPE = {
  LIST: 1,
  ADD: 2,
  EDIT: 3,
};


export const ARF_TENANT_ID = 1;
export const COURSE_WORKSHOP_ID = 2;

export const ACCOUNT_TYPES = ['ROLES','USERS','RESOURCES','PERMISSIONS'];

export const DEFAULT_PREFIX = "Mr";

export const FREQUENCY_MAPPING= {
  0: "Daily",
  1: "Weekly",
  2: "Every two week",
  3: "Thirty days",
  4: "Sixty Days",
  5: "Ninety Days",
  6: "Monthly",
  7: "Every two months",
  8: "Quarterly",
  9: "Every four months",
  10: "Every six months",
  11: "Annual",
  12: "Every two years",
  "WEEKLY": "Weekly",
  "MONTHLY": "Monthly",
  "QUARTERLY": "Quaterly",
  "ANNUAL": "Yearly",
};

export const FREQUENCY_DURATION_MAPPING= {
  1: ["Week", "Weeks"],
  6: ["Month", "Months"],
  8: ["Quarter", "Quarters"],
  11: ["Year", "Years"],
  "WEEKLY": ["Week", "Weeks"],
  "MONTHLY": ["Month", "Months"],
  "QUARTERLY": ["Quarter", "Quarters"],
  "ANNUAL": ["Year", "Years"],
};

export const SUBSCRIPTION_FREQUENCY = [
	{
		frequency: 1,
		frequencyName: "Weekly",
    frequencySecondaryName: "Weeks",
	},
	{
		frequency: 6,
		frequencyName: "Monthly",
    frequencySecondaryName: "Months",
	},
	{
		frequency: 8,
		frequencyName: "Quarterly",
    frequencySecondaryName: "Quarters",
	},
	{
		frequency: 11,
		frequencyName: "Yearly",
    frequencySecondaryName: "Years",
	},
];

export const SUBSCRIPTION_CLIENT_TYPE= {
  ALL: "ALL",
  WITH_CLINIC: "CLNC",
  WITH_RESEARCH_INSTITUTE: "RSIN",
}

export const ORGANIZATION_TYPE= [
  {
    name: "Clinic",
    value: "Clinic",
  },
  {
    name: "School",
    value: "School",
  },
  {
    name: "Research Institute",
    value: "ResearchInstitute",
  },
];

export const SUBSCRIPTION_CATEGORIES= {
  CLINIC_MANAGEMENT: "Clinic Management",
}

export const DURATION_PATTERN= {
  1: "Weeks",
  6: "Months",
  8: "Quarters",
  11: "Year",
};

export const APPLE_HEALTH_GROUP= {
  PRIMARY: "Essential",
  SECONDARY: "Wellness Bundle",
  SLEEP: "Sleep & Recovery Bundle",
  EXERCISE: "Exercise Bundle",
  NUTRITION: "Nutrition Bundle"
}

export const LOCAL_STORAGE_ACCESSOR= {
  CART_ITEM: 'amyResearchCartItems',
}