import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { CONTENT_TYPE, ROOT_URL, API_ERROR_MESSAGE } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { getStructuredClientSubsDetails } from "../../utils/Utilities";
import { fetchApi } from "../../utils/CustomFetch";

const ClientSubscriptionPlans = types.model({
	appSubscriptionPlanID: types.maybeNull(types.number),
	isSelectedForPurchase: types.maybeNull(types.boolean),
	amount: types.maybeNull(types.number),
	frequency: types.maybeNull(types.number),
	subscriptionName: types.maybeNull(types.string),
	appSubscriptionID: types.maybeNull(types.number),
	appSubscriptionIDs: types.maybeNull(types.array(types.number)),
	categoryName: types.maybeNull(types.string),
	duration: types.maybeNull(types.number),
	clientType: types.maybeNull(types.number),
	isSkipTrial: types.maybeNull(types.boolean),
	clientID: types.maybeNull(types.number),
	trialDuration: types.maybeNull(types.number),
	trialFrequency: types.maybeNull(types.number),
	isPaymentDone: types.maybeNull(types.boolean),
});

const ResearchInstitutionItem = types.model({
	sNo: types.maybeNull(types.number),
	id: types.maybeNull(types.string),
	researcherCount: types.maybeNull(types.number),
	name: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	institutionAdminContactSNo: types.maybeNull(types.number),
	institutionAdminContactId: types.maybeNull(types.string),
	primaryContactPrefix: types.maybeNull(types.string),
	primaryContactFirstName: types.maybeNull(types.string),
	primaryContactMiddleName: types.maybeNull(types.string),
	primaryContactLastName: types.maybeNull(types.string),
	primaryContactPhone: types.maybeNull(types.string),
	primaryContactEmail: types.maybeNull(types.string),
	address1: types.maybeNull(types.string),
	address2: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	stateId: types.maybeNull(types.number),
	countryId: types.maybeNull(types.number),
	zip: types.maybeNull(types.string),
	clientSubscriptionPlans: types.maybeNull(
		types.array(ClientSubscriptionPlans)
	),
});

export const ResearchInstitution = types
	.model({
		items: types.array(ResearchInstitutionItem),
		currentId: types.maybeNull(types.number),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		fetch_all_status: types.optional(
			types.enumeration("State", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.sNo === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		let controller;

		return {
			loadResearchInstitution: flow(function* loadResearchInstitution() {
				controller = AbortController && new AbortController();
				self.fetch_all_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ResearchInstitution/getAllResearchInstitution`,
						getRequestOptions("GET", null)
					);
					// self.items = yield response.json();
					const researchInstitutes = yield response.json();
					self.items = JSON.parse(
						JSON.stringify(researchInstitutes)
					)?.map((researchInstitute) => {
						if (researchInstitute?.clientSubscriptionPlans) {
							researchInstitute.clientSubscriptionPlans =
								getStructuredClientSubsDetails(
									researchInstitute.clientSubscriptionPlans
								);
						}
						return researchInstitute;
					});
					self.fetch_all_status = "done";
				} catch (error) {
					console.error(
						"Failed to fetchApi research institution",
						error
					);
					self.fetch_all_status = "error";
				}
			}),
			updateResearchInstitutionListItem(data) {
				applySnapshot(
					self.items.find((item) => item.sNo === data.sNo),
					data
				);
			},
			addResearchInstitutionListItem(data) {
				self.items.push(data);
			},
			save: flow(function* save(data, snackbar, loadAdminContact) {
				if (self.currentId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/updateResearchInstitution`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
							// {
							//   method: "POST",
							//   headers: { "Content-Type": "application/json" },
							//   body: JSON.stringify({
							//     ...self.current,
							//     ...data,
							//   }),
							// }
						);
						const json = yield response.json();

						if (response.status === 200) {
							json.clientSubscriptionPlans =
								getStructuredClientSubsDetails(
									json.clientSubscriptionPlans
								);
							self.updateResearchInstitutionListItem(json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
							loadAdminContact(json.sNo, json.id);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/createResearchInstitution`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
							// {
							//   method: "POST",
							//   headers: { "Content-Type": "application/json" },
							//   body: JSON.stringify(data),
							// }
						);
						const json = yield response.json();
						if (response.status === 200) {
							json.clientSubscriptionPlans =
								getStructuredClientSubsDetails(
									json.clientSubscriptionPlans
								);
							self.addResearchInstitutionListItem(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							loadAdminContact(json.sNo, json.id);
							self.updateCurrentItem(json.sNo);
							self.updateContentType(CONTENT_TYPE.EDIT);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			reloadResearchInstitution() {
				if (controller) controller.abort();
				self.loadResearchInstitution();
			},
			beforeDestroy() {
				if (controller) controller.abort();
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
			remove: flow(function* remove(id, snackbar) {
				const item = self.items.find((item) => item.sNo === id);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ResearchInstitution/deleteResearchInstitutionById`,
						getRequestOptions("POST", {
							id: item.id,
							sNo: item.sNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 400) {
							self.deleteResearchInstitution(json.sNo);
							snackbar.showMessage(
								"Record Successfully Deleted!!",
								null,
								null,
								{
									severity: "success",
								}
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					console.error("Uh oh, failed to save: ", e);
				}
			}),
			deleteResearchInstitution(id) {
				self.updateCurrentItem(null);
				destroy(self.items.find((item) => item.sNo === id));
			},
		};
	});
