import { applySnapshot, destroy, flow, getEnv, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const DependentQuestionAnswerItem = types.model({
	dependentQuestionSNo: types.maybeNull(types.string),
	dependentAnswerSNo: types.maybeNull(types.string),
});

const TrackingSheetAnswer = types.model({
	trackingSheetAnswerId: types.maybeNull(types.number),
	trackingSheetAnswerSNo: types.maybeNull(types.string),
	answerName: types.maybeNull(types.string),
	answerType: types.maybeNull(types.string),
	dependent: types.maybeNull(types.boolean),
	dependentQuestionAnswer: types.array(DependentQuestionAnswerItem),
});

const ReviewNoteItem = types.model({
	reviewerNotesId: types.number,
	reviewerNotesSNo: types.string,
	reviewerNote: types.string,
	reviewDate: types.string,
});

const TrackingSheetLineItem = types.model({
	trackingSheetLineItemSNo: types.maybeNull(types.string),
	trackingSheetLineItemId: types.maybeNull(types.number),
	trackingSheetLineItemName: types.maybeNull(types.string),
	trackingSheetLineItemType: types.maybeNull(types.string),
	trackingSheetParentLineItemSNo: types.maybeNull(types.string),
	trackingSheetParentLineItemId: types.maybeNull(types.number),
	varient: types.maybeNull(types.string),
	answerType: types.maybeNull(types.string),
	answerValue: types.maybeNull(types.string),
	dependent: types.maybeNull(types.boolean),
	dependentQuestionAnswer: types.array(DependentQuestionAnswerItem),
	trackingSheetAnswers: types.maybeNull(types.array(TrackingSheetAnswer)),
	trackingSheetSelectedAnswer: types.maybeNull(
		types.array(
			types.model({ answerId: types.number, answerSNo: types.string })
		)
	),
	reviewerNotes: types.maybeNull(types.array(ReviewNoteItem)),
});

const TrackingSheetLine = types.model({
	trackingSheetLineId: types.number,
	trackingSheetLineSNo: types.maybeNull(types.string),
	trackingSheetLineItems: types.array(TrackingSheetLineItem),
});

const TrackingSheetItem = types.model({
	id: types.maybeNull(types.number),
	trackingSheetId: types.maybeNull(types.number),
	trackingSheetSNo: types.maybeNull(types.string),
	trackingSheetName: types.maybeNull(types.string),
	trackingSheetLines: types.maybeNull(types.array(TrackingSheetLine)),
	patientIdentifier: types.maybeNull(types.string),
	patientName: types.maybeNull(types.string),
	trackingSheetStatus: types.maybeNull(types.string),
	sessionDate: types.maybeNull(types.string),
	submittedDate: types.maybeNull(types.string),
	approvalDate: types.maybeNull(types.string),
});

export const TrackingSheet = types
	.model({
		currentId: types.maybeNull(types.number),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		items: types.array(TrackingSheetItem),
		patientItems: types.array(TrackingSheetItem),
		reviewerItems: types.array(TrackingSheetItem),
		all: types.array(TrackingSheetItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.trackingSheetId === self.currentId
					);
				} else {
					return null;
				}
			},
			currentDividerItem(item) {
				if (self.currentId) {
					return self.items
						.find((item) => item.trackingSheetId === self.currentId)
						.trackingSheetLines.find(
							(line) =>
								line.trackingSheetLineId ===
								item.trackingSheetLineId
						);
				} else {
					return null;
				}
			},
			verifyDependecy(checkItem) {
				let dependencySelected = false;

				const current = self.items.find(
					(item) => item.trackingSheetId === self.currentId
				);
				current.trackingSheetLines.forEach((line) => {
					line.trackingSheetLineItems
						.filter(
							(lineItem) =>
								lineItem.trackingSheetLineItemType === "Answer"
						)
						.forEach((lineItem) => {
							if (
								lineItem.trackingSheetSelectedAnswer.length > 0
							) {
								if (
									checkItem.dependentQuestionAnswer.some(
										(q) =>
											lineItem.trackingSheetLineItemSNo ===
												q.dependentQuestionSNo &&
											lineItem.trackingSheetSelectedAnswer.some(
												(ans) =>
													ans.answerSNo ===
													q.dependentAnswerSNo
											)
									)
								) {
									dependencySelected = true;
								}
							}
						});
				});

				return dependencySelected;
			},
			get currentSheetSections() {
				if (self.currentId) {
					let sections = [];
					const current = self.items.find(
						(item) => item.trackingSheetId === self.currentId
					);
					const trackingSheetLines = current.trackingSheetLines;

					let section = [];
					trackingSheetLines.forEach((line) => {
						if (
							line.trackingSheetLineItems.some(
								(lineItem) =>
									lineItem.trackingSheetLineItemType ===
									"Divider"
							)
						) {
							if (section.length > 0) {
								sections.push({
									items: section,
									dividerLine: line,
								});
								section = [];
							}
						} else {
							let lineItems = [];
							line.trackingSheetLineItems.forEach((lineItem) => {
								if (
									!lineItem.dependent ||
									self.verifyDependecy(lineItem)
								) {
									lineItems.push(lineItem);
								}
							});
							if (lineItems.length > 0) {
								section.push({
									...line,
									trackingSheetLineItems: lineItems,
								});
							}
						}
					});

					return sections;
				} else {
					return [];
				}
			},
		};
	})
	.actions((self) => {
		return {
			loadAll: flow(function* loadAll(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllTrackingSheets`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.all = json;
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadPatientTrackingSheets: flow(function* loadAllForPatient(
				patient,
				snackbar
			) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllPatientTrackingSheets?patientId=${patient.userId}&patientSNo=${patient.userSNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.patientItems = json;
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadReviewerTrackingSheets: flow(function* loadAllForReviewer(
				snackbar
			) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllReviewerTrackingSheets`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.reviewerItems = json;
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadAllForClinician: flow(function* loadAllForClinician(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllClinicianTrackingSheets`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json;
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadById: flow(function* loadById(
				id,
				sNo,
				snackbar,
				callback = () => {}
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getNewTrackingSheetById?trackingSheetId=${id}&trackingSheetSNo=${sNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (
							self.items.some(
								(item) => item.trackingSheetId === id
							)
						) {
							applySnapshot(
								self.items.find(
									(item) => item.trackingSheetId === id
								),
								json
							);
						} else {
							self.items.push(json);
						}
						self.updateCurrentItem(id);
						self.updateContentType(CONTENT_TYPE.EDIT);
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			addTrackingSheetToProgram: flow(function* addTrackingSheetToProgram(
				data,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addTrackingSheetToProgram`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items.push(json);
						self.updateCurrentItem(json.trackingSheetId);
						// self.updateContentType(CONTENT_TYPE.EDIT);
						if (callback) {
							callback();
						}
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			remove: flow(function* remove(trackingSheet, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteTrackingSheet`,
						getRequestOptions("POST", {
							trackingSheetId: trackingSheet.trackingSheetId,
							trackingSheetSNo: trackingSheet.trackingSheetSNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(trackingSheet);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			saveTrackingSheet: flow(function* saveTrackingSheet(
				data,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/saveTrackingSheetWithAnswers`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find(
								(item) =>
									item.trackingSheetSNo ===
									data.trackingSheetSNo
							),
							json
						);
						snackbar.showMessage(
							"Draft Saved Successfully",
							null,
							null,
							{
								severity: "success",
							}
						);
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			approveTrackingSheet: flow(function* approveTrackingSheet(
				data,
				callback = () => {}
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/approveTrackingSheetByReviewer`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find(
								(item) =>
									item.trackingSheetSNo ===
									data.trackingSheetSNo
							),
							json
						);
						getEnv(self).notifier.enqueue(
							"Tracking Sheet Approved",
							"success"
						);
						callback();
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			submitTrackingSheet: flow(function* submitTrackingSheet(
				data,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/submitTrackingSheetForReview`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find(
								(item) =>
									item.trackingSheetSNo ===
									data.trackingSheetSNo
							),
							json
						);
						snackbar.showMessage(
							"Tracking Sheet Submitted",
							null,
							null,
							{
								severity: "success",
							}
						);
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			resubmitTrackingSheet: flow(function* resubmitTrackingSheet(
				data,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/resubmitTrackingSheetByReviewer`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(
							self.items.find(
								(item) =>
									item.trackingSheetSNo ===
									data.trackingSheetSNo
							),
							json
						);
						getEnv(self).notifier.enqueue(
							"Tracking Sheet Resubmit Requested",
							"success"
						);
						callback();
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			addReviewerNotesToTrackingSheet: flow(
				function* addReviewerNotesToTrackingSheet(data, dividerItem) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addReviewerNotesToTrackingSheet`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							applySnapshot(dividerItem, {
								...dividerItem,
								reviewerNotes: json.reviewerNotes,
							});
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
						}
					} catch (error) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				}
			),

			updateAnswerValue(lineSNo, lineItem, value) {
				applySnapshot(
					self.items
						.find((item) => item.trackingSheetId === self.currentId)
						.trackingSheetLines.find(
							(line) => line.trackingSheetLineSNo === lineSNo
						)
						.trackingSheetLineItems.find(
							(item) =>
								item.trackingSheetLineItemSNo ===
								lineItem.trackingSheetLineItemSNo
						),
					{ ...lineItem, answerValue: value }
				);
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
		};
	});
