import { Alert } from "@mui/material";
import { forwardRef } from "react";

const SnackbarAlert = forwardRef((props, forwardedRef) => {
    const { message, variant, component, persist, action } = props;
    const closeAction = action;
    return (
        <Alert
            ref={forwardedRef}
            elevation={6}
            variant='filled'
            severity={variant}
            onClose={persist ? () => closeAction() : undefined}>
            {component ? component : message}
        </Alert>
    );
});

export default SnackbarAlert;