import { applySnapshot, destroy, flow, getEnv, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const TrackCertItem = types.model({
	trackVideoPermissionId: types.number,
	trackVideoPermissionSNo: types.identifier,
	trackClinicianId: types.number,
	trackClinicianSNo: types.string,
	companyName: types.string,
	aggrementDate: types.string,
	signatureURL: types.maybeNull(types.string),
	printName: types.string,
	trackCertificationVideoId: types.maybeNull(types.number),
	trackCertificationVideoSNo: types.maybeNull(types.string),
	trackCertificationVideoLink: types.maybeNull(types.string),
	trackCertificationVideoFile: types.maybeNull(types.string),
	trackCertificationVideoContentType: types.maybeNull(types.string),
	videoUploading: types.maybeNull(types.boolean),
});

export const TrackCertificationVideo = types
	.model({
		items: types.array(TrackCertItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		current: types.safeReference(types.late(() => TrackCertItem)),
	})
	.views((self) => {
		return {
			get currentCompanyName() {
				return self.current?.companyName;
			},
		};
	})
	.actions((self) => {
		return {
			loadByTrackClinician: flow(function* loadByTrackClinician(
				trackClinician
			) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllTrackCertificationVideo?trackClinicianId=${trackClinician.id}&trackClinicianSNo=${trackClinician.sNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json;
						self.status = "done";
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						self.status = "error";
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					self.status = "error";
				}
			}),
			addTrackVideoPermission: flow(function* addTrackVideoPermission(
				data
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addTrackVideoPermission`,
						getRequestOptions("POST", data)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						self.items.push(json);
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			updateTrackVideoPermission: flow(
				function* updateTrackVideoPermission(data) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackVideoPermission`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (
							response.status === RESPONSE_STATUS.SUCCESS &&
							json.status === RESPONSE_STATUS.SUCCESS_ZERO
						) {
							applySnapshot(self.current, json);
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
						}
					} catch (error) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				}
			),
			uploadVideo: flow(function* uploadVideo(data, item) {
				try {
					applySnapshot(item, { ...item, videoUploading: true });
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addTrackCertificationVideo`,
						getRequestOptions("POST", data, true)
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS_ZERO
					) {
						applySnapshot(item, { ...item, ...json });
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
					applySnapshot(item, { ...item, videoUploading: false });
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			deleteVideo: flow(function* deleteVideo(trackVideo) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteTrackCertificationVideo`,
						getRequestOptions("POST", {
							trackVideoPermissionId:
								trackVideo.trackVideoPermissionId,
							trackVideoPermissionSNo:
								trackVideo.trackVideoPermissionSNo,
							trackCertificationVideoId:
								trackVideo.trackCertificationVideoId,
							trackCertificationVideoSNo:
								trackVideo.trackCertificationVideoSNo,
						})
					);
					const json = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						json.status === RESPONSE_STATUS.SUCCESS
					) {
						applySnapshot(trackVideo, {
							...trackVideo,
							trackCertificationVideoId: null,
							trackCertificationVideoSNo: null,
							trackCertificationVideoLink: null,
							trackCertificationVideoFile: null,
							trackCertificationVideoContentType: null,
						});
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			updateCurrentItem(item) {
				self.current = item;
			},
			clearSignature() {
				applySnapshot(self.current, {
					...self.current,
					signatureURL: null,
				});
			},
			deleteTrackVideoPermission: flow(
				function* deleteTrackVideoPermission(trackVideo) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackVideoPermission`,
							getRequestOptions("POST", {
								trackVideoPermissionId:
									trackVideo.trackVideoPermissionId,
								trackVideoPermissionSNo:
									trackVideo.trackVideoPermissionSNo,
							})
						);
						const json = yield response.json();
						if (
							response.status === RESPONSE_STATUS.SUCCESS &&
							json.status === RESPONSE_STATUS.SUCCESS_ZERO
						) {
							destroy(trackVideo);
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
						}
					} catch (error) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				}
			),
		};
	});
