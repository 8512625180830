import { applySnapshot, destroy, flow, getEnv, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const UserRoleItem = types.model({
	roleId: types.number,
	assignId: types.maybeNull(types.number),
	clinicId: types.maybeNull(types.number),
	lmsId: types.maybeNull(types.number),
	researchInstitutionId: types.maybeNull(types.string),
	researchInstitutionSNo: types.maybeNull(types.number),
});

const TenantItem = types.model({
	tenantID: types.maybeNull(types.number),
	subDomain: types.maybeNull(types.string),
	tenantName: types.maybeNull(types.string),
	clientName: types.maybeNull(types.string),
	clientSNo: types.maybeNull(types.string),
	clientID: types.maybeNull(types.number),
	clientType: types.maybeNull(types.number),
});

const UserItem = types.model({
	email: types.maybeNull(types.string),
	firstName: types.maybeNull(types.string),
	isNewUser: types.maybeNull(types.boolean),
	lastName: types.maybeNull(types.string),
	middleName: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	prefix: types.maybeNull(types.string),
	userId: types.maybeNull(types.number),
	tenantId: types.maybeNull(types.number),
	userRole: types.maybeNull(types.array(UserRoleItem)),
	isEmailVerified: types.maybeNull(types.boolean),
	displaySurveyStatistics: types.maybeNull(types.boolean),
});

const RoleItem = types.model({
	id: types.number,
	//roleId: types.number,
	title: types.string,
	description: types.maybeNull(types.string),
	active: types.maybeNull(types.boolean),
	roleType: types.maybeNull(types.number),
	tenantId: types.maybeNull(types.number),
});

export const Users = types
	.model({
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		items: types.array(UserItem),
		status: types.maybeNull(
			types.enumeration("status", ["pending", "done", "error"])
		),
		currentId: types.maybeNull(types.number),
		tenants: types.array(TenantItem),
		roles: types.array(RoleItem),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.userId === self.currentId
					);
				} else {
					return null;
				}
			},
			name(item) {
				return `${item.prefix ? item.prefix : ""} ${
					item.firstName ? item.firstName : ""
				} ${item.middleName ? item.middleName : ""} ${
					item.lastName ? item.lastName : ""
				}`;
			},
			rolesList(item, roles) {
				let role = "";

				item.userRole?.forEach((ur, index) => {
					if (roles.some((r) => r.id === ur.roleId)) {
						role +=
							roles.find((r) => r.id === ur.roleId)?.title +
							`${index < item.userRole.length - 1 ? ", " : ""}`;
					}
				});

				return role;
			},

			roleItems(item, roles) {
				let list = [];
				item.userRole?.forEach((ur, index) => {
					if (roles.some((r) => r.id === ur.roleId)) {
						if (roles.some((r) => r.id === ur.roleId)) {
							list.push(roles.find((r) => r.id === ur.roleId));
						}
					}
				});
				return list;
			},
			get assignId() {
				if (self.currentId) {
					const userRole = self.items.find(
						(item) => item.userId === self.currentId
					)?.userRole;

					if (userRole) {
						let assignId = null;

						userRole.forEach((role) => {
							if (!assignId) {
								assignId = role.assignId;
							}
						});

						return assignId;
					} else {
						return null;
					}
				} else {
					return null;
				}
			},
			get clinicId() {
				if (self.currentId) {
					const userRole = self.items.find(
						(item) => item.userId === self.currentId
					)?.userRole;

					if (userRole) {
						let clinicId = null;

						userRole.forEach((role) => {
							if (!clinicId) {
								clinicId = role.clinicId;
							}
						});

						return clinicId;
					} else {
						return null;
					}
				} else {
					return null;
				}
			},
			get lmsId() {
				if (self.currentId) {
					const userRole = self.items.find(
						(item) => item.userId === self.currentId
					)?.userRole;

					if (userRole) {
						let lmsId = null;

						userRole.forEach((role) => {
							if (!lmsId) {
								lmsId = role.lmsId;
							}
						});

						return lmsId;
					} else {
						return null;
					}
				} else {
					return null;
				}
			},
			get researchInstitutionId() {
				if (self.currentId) {
					const userRole = self.items.find(
						(item) => item.userId === self.currentId
					)?.userRole;

					if (userRole) {
						let researchInstitutionId = null;

						userRole.forEach((role) => {
							if (!researchInstitutionId) {
								researchInstitutionId =
									role.researchInstitutionId;
							}
						});

						return researchInstitutionId;
					} else {
						return null;
					}
				} else {
					return null;
				}
			},
			get researchInstitutionSNo() {
				if (self.currentId) {
					const userRole = self.items.find(
						(item) => item.userId === self.currentId
					)?.userRole;

					if (userRole) {
						let researchInstitutionSNo = null;

						userRole.forEach((role) => {
							if (!researchInstitutionSNo) {
								researchInstitutionSNo =
									role.researchInstitutionSNo;
							}
						});

						return researchInstitutionSNo;
					} else {
						return null;
					}
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/getAllUsers`,
						getRequestOptions("GET", null)
					);

					const json = yield response.json();
					if (response.status === 200) {
						self.items = json;
						self.status = "done";
					} else {
						snackbar.showMessage(response.statusText, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadById: flow(function* loadById(userId) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/getUserDetailById?userId=${userId}`,
						getRequestOptions("GET", null)
					);

					const json = yield response.json();
					if (response.status === 200) {
						self.currentId = userId;
						applySnapshot(self.current, json);
						self.updateContentType(CONTENT_TYPE.EDIT);
					} else {
						getEnv(self).notifier.enqueue(
							response?.statusText,
							"error"
						);
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			updateCurrentItem(id) {
				self.currentId = id;
			},
			save: flow(function* save(data, snackbar, callback = () => {}) {
				let payload = {
					...self.current,
					...data,
					userRoleRegistrationRequest: [],
				};

				data.roles?.forEach((item) => {
					payload.userRoleRegistrationRequest.push({
						roleId: item?.id,
						assignId: data?.assignId ? data?.assignId : null,
					});
				});

				if (self.current) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/admin/updateRegistration`,
							getRequestOptions("POST", payload)
						);
						const json = yield response.json();
						if (response.status === 200) {
							applySnapshot(self.current, {
								...json,
								userRole: json.userRoleRegistrationResponse,
							});
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
							callback();
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			remove: flow(function* remove(userId, snackbar) {
				const user = self.items.find((item) => item.userId === userId);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/deleteUserById`,
						getRequestOptions("POST", {
							userId: user.userId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						self.currentId = null;
						destroy(user);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			loadTenantsByRole: flow(function* loadTenantsByRole(roles) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/getAllTenantsBasedOnRole`,
						getRequestOptions("POST", { roles: roles })
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.tenants = json;
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			loadRolesByTenant: flow(function* loadRolesByTenant(
				tenantId,
				userId
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/getTenantRoles/${tenantId}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.roles = json;
						self.loadById(userId);
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
		};
	});
