import { CourseFeedbackTypes } from "./enums";

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data === undefined ? null : data;
        if (value !== null) {
            formData.append(parentKey, value);
        }
    }
}


export function generateArrayBetween(start, end) {
    let array = [];

    for (let i = start; i < end; i++) {
        array.push(i);
    }

    return array;
}

export function jsonToFormData(data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
}

export function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}


export const emptyCanvas = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

export const courseRecursiveSearch = (id, type, data) => {
    if (data.length > 0) {
        if (type === 'session') {
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                if (item.sessionId) {
                    if (`${item.sessionId}` === id) {
                        return item;
                    } else {
                        const result = courseRecursiveSearch(id, type, item.activity);
                        if (result) {
                            return result;
                        }
                    }
                } else if (item.activityId) {
                    const result = courseRecursiveSearch(id, type, item.session);
                    if (result) {
                        return result;
                    }
                }
                else if (item.courseDaysId) {
                    const result = courseRecursiveSearch(id, type, [...item.courseActivity, ...item.courseSession]);
                    if (result) {
                        return result;
                    }
                }
                else {
                    return null;
                }
            }
        }
        else if (type === 'activity') {
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                if (item.sessionId) {
                    const result = courseRecursiveSearch(id, type, item.activity);
                    if (result) {
                        return result;
                    }
                } else if (item.activityId) {
                    if (`${item.activityId}` === id) {
                        return item;
                    } else {
                        const result = courseRecursiveSearch(id, type, item.session);
                        if (result) {
                            return result;
                        }
                    }
                }
                else if (item.courseDaysId) {
                    const result = courseRecursiveSearch(id, type, [...item.courseActivity, ...item.courseSession]);
                    if (result) {
                        return result;
                    }
                } else {
                    return null;
                }
            }
        }
        else if (type === 'day') {
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                if (item.courseDaysId) {
                    if (`${item.courseDaysId}` === id) {
                        return item;
                    }
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }
    else {
        return null;
    }
}

export const courseRecursiveParentSearch = (id, type, data, parent = null) => {
    if (data.length > 0) {
        if (type === 'session') {
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                if (item.sessionId) {
                    if (`${item.sessionId}` === `${id}`) {
                        return parent;
                    } else {
                        const result = courseRecursiveParentSearch(id, type, item.activity, item);
                        if (result) {
                            return result;
                        }
                    }
                } else if (item.activityId) {
                    const result = courseRecursiveParentSearch(id, type, item.session, item);
                    if (result) {
                        return result;
                    }
                }
                else if (item.courseDaysId) {
                    const result = courseRecursiveParentSearch(id, type, [...item.courseActivity, ...item.courseSession], item);
                    if (result) {
                        return result;
                    }
                }

                else {
                    return null;
                }
            }
        }
        else if (type === 'activity') {
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                if (item.sessionId) {
                    const result = courseRecursiveParentSearch(id, type, item.activity, item);
                    if (result) {
                        return result;
                    }
                } else if (item.activityId) {
                    if (`${item.activityId}` === `${id}`) {
                        return parent;
                    } else {
                        const result = courseRecursiveParentSearch(id, type, item.session, item);
                        if (result) {
                            return result;
                        }
                    }
                }
                else if (item.courseDaysId) {
                    const result = courseRecursiveParentSearch(id, type, [...item.courseActivity, ...item.courseSession], item);
                    if (result) {
                        return result;
                    }
                }
                else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }
    else {
        return null;
    }
}


export const getCompleteDayStructure = (day, parent) => {
    return {
        label: day.name,
        value: { node: day, type: CourseFeedbackTypes.Day, sNo: day.courseDaysSNo, id: day.courseDaysId, for: 'Day', name: day.name },
        children: [...getCompleteActivityStructure(day.courseActivity, parent), ...getCompleteSessionStructure(day.courseSession, parent)],
        checked: parent.type === CourseFeedbackTypes.Day && parent.id === day.courseDaysId
    }
}


export const getCompleteSessionStructure = (sessions, parent) => {
    let nodes = [];

    sessions.forEach((session) => {
        nodes.push({
            label: session.name,
            value: { node: session, type: CourseFeedbackTypes.Session, sNo: session.sessionSNo, id: session.sessionId, for: 'Session', name: session.name },
            children: [...getCompleteActivityStructure(session.activity, parent), ...getCompleteUploadStructure(session.sessionUpload, parent, { type: CourseFeedbackTypes.SessionUpload, sNo: session.sessionSNo, id: session.sessionId })],
            checked: parent.type === CourseFeedbackTypes.Session && parent.id === session.sessionId
        })
    })

    return nodes;

}

export const getCompleteUploadStructure = (uploads, parent, node) => {
    let nodes = [];

    uploads.forEach((upload) => {
        nodes.push({
            label: upload.uploadFile,
            value: { node: upload, type: node.type, sNo: node.sNo, id: node.id, uploadId: upload.uploadId, uploadType: upload.uploadType, for: 'Upload File', name: upload.uploadFile },
            checked: parent.type === node.type && parent.id === node.id && parent.uploadId === upload.uploadId
        })
    })

    return nodes;
}

export const getCompleteActivityStructure = (activities, parent) => {
    let nodes = [];

    activities.forEach((activity) => {
        nodes.push({
            label: activity.title1,
            value: { node: activity, type: CourseFeedbackTypes.SubActivity, sNo: activity.activitySNo, id: activity.activityId, for: 'Sub-Activity', name: activity.title1 },
            children: getCompleteSessionStructure(activity.session, parent),
            checked: parent.type === CourseFeedbackTypes.SubActivity && parent.id === activity.activityId
        })
    })

    return nodes;
}

export const getThreadDetails = (history, node) => {
    let feedbackCounts = 0;
    let newFeedbacks = 0;
    let feedback = null;

    history.filter(item => item.parentType === node.type && item.parentId === node.id).forEach((item) => {
        if (item.uploadId) {
            if (item.uploadId === node.uploadId) {
                feedback = item;
                if (!item.statusAcknowledgement) {
                    newFeedbacks += 1;
                }
                if (!item.feedbackType) {
                    feedbackCounts += 1;
                }
            }
        } else {
            feedback = item;
            if (!item.statusAcknowledgement) {
                newFeedbacks += 1;
            }
            if (!item.feedbackType) {
                feedbackCounts += 1;
            }
        }
    })

    return { count: feedbackCounts, new: newFeedbacks, detail: feedback };
}



export const getFeedbackThreads = (history, tree) => {
    let threads = []
    const details = getThreadDetails(history, tree.value)
    if (details.count > 0) {
        threads.push({ label: tree.label, active: tree.checked, id: tree.value.id, value: tree.value, details: details })
    }

    tree.children?.forEach((child) => {
        threads = [...threads, ...getFeedbackThreads(history, child)];
    });

    return threads;
}