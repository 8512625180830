import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const StatusItem = types.model({
	id: types.number,
	status: types.string,
});

export const CourseStatus = types
	.model({
		items: types.array(StatusItem),
		fetch_all_status: types.optional(
			types.enumeration("fetch_all_status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			getCourseStatus(id) {
				return self.items.find((item) => item.id === id)?.status;
			},
			getCourseId(status) {
				return self.items.find(
					(item) => item.status.trim() === status.trim()
				)?.id;
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllCourseStatus`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json;
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
		};
	});
