import { flow, types } from "mobx-state-tree";
import { ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const CurriculumItem = types.model({
	id: types.number,
	name: types.string,
	curriculumId: types.number,
});

export const Curriculum = types
	.model({
		items: types.array(CurriculumItem),
		fetch_all_status: types.optional(
			types.enumeration("fetch_all_status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.actions((self) => {
		return {
			load: flow(function* load() {
				self.fetch_all_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllCurriculim`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					self.items = json.map((item) => {
						item.curriculumId = item.id;
						return item;
					});
					self.fetch_all_status = "done";
				} catch (error) {
					console.error("Failed to fetchApi countries", error);
					self.fetch_all_status = "error";
				}
			}),
		};
	});
