import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const AllDegreeItem = types.model({
	id: types.number,
	name: types.maybeNull(types.string),
});

const DegreeItem = types.model({
	id: types.number,
	degreeId: types.maybeNull(types.number),
	institution: types.maybeNull(types.string),
	areaOfStudy: types.maybeNull(types.string),
	yearAwarded: types.maybeNull(types.string),
	otherDegree: types.maybeNull(types.string),
	contentCreatorId: types.maybeNull(types.number),
});

export const Degree = types
	.model({
		items: types.array(DegreeItem),
		all: types.array(AllDegreeItem),
		currentId: types.maybeNull(types.number),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.id === self.currentId
					);
				} else {
					return null;
				}
			},
			degreeNameById(id) {
				return self.all.find((item) => item.id === id)?.name;
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ContentCreator/getDegreeByContentCreatorById?Id=${id}`,
						getRequestOptions("GET", null)
					);

					const json = yield response.json();

					if (response.status === 200) {
						self.items = json;
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadAll: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Common/getDegree`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.all = json;
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			save: flow(function* save(data, snackbar, id) {
				if (self.current) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ContentCreator/updateContentCreatorDegree`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);

						const json = yield response.json();

						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.updateDegreeListItem(json);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ContentCreator/addContentCreatorDegree`,
							getRequestOptions("POST", {
								...data,
								contentCreatorId: id,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.addDegreeListItem(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			updateDegreeListItem(data) {
				if (self.items.find((item) => item?.id === data?.id)) {
					applySnapshot(
						self.items.find((item) => item?.id === data?.id),
						data
					);
				}
			},
			addDegreeListItem(data) {
				self.items.push(data);
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
			remove: flow(function* remove(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ContentCreator/deleteContentCreatorDegreeById?Id=${id}`,
						getRequestOptions("POST", null)
					);

					const json = yield response.json();
					if (response.status === 200) {
						self.deleteDegree(id);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			deleteDegree(id) {
				self.currentId = null;
				destroy(self.items.find((item) => item.id === id));
			},
		};
	});
