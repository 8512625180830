import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const BiographyItem = types.model({
	id: types.number,
	contentCreatorId: types.maybeNull(types.number),
	biography: types.maybeNull(types.string),
});

const CVItem = types.model({
	contentCreatorId: types.maybeNull(types.number),
	cvContentType: types.maybeNull(types.string),
	cvFileName: types.maybeNull(types.string),
	cvUrl: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const HeadshotItem = types.model({
	contentCreatorId: types.maybeNull(types.number),
	headShotContentType: types.maybeNull(types.string),
	headShotFile: types.maybeNull(types.string),
	headShotUrl: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const LicenseItem = types.model({
	id: types.maybeNull(types.number),
	contentCreatorId: types.maybeNull(types.number),
	professionalClassificationId: types.maybeNull(types.number),
	professionalClassification: types.maybeNull(types.string),
	otherProfessionalClassification: types.maybeNull(types.string),
	firstProfessionalClassificationId: types.maybeNull(types.number),
	firstProfessionalClassification: types.maybeNull(types.string),
	firstOtherProfessionalClassification: types.maybeNull(types.string),
	secondProfessionalClassificationId: types.maybeNull(types.number),
	secondProfessionalClassification: types.maybeNull(types.string),
	secondOtherProfessionalClassification: types.maybeNull(types.string),
	licenseNumber: types.maybeNull(types.string),
	dateOfLicense: types.maybeNull(types.string),
});

const ContentCreatorItem = types.model({
	id: types.number,
	prefix: types.maybeNull(types.string),
	firstName: types.maybeNull(types.string),
	middleName: types.maybeNull(types.string),
	lastName: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	dob: types.maybeNull(types.string),
	assignId: types.maybeNull(types.number),
	phoneBusiness: types.maybeNull(types.string),
	phoneFax: types.maybeNull(types.string),
	address1: types.maybeNull(types.string),
	address2: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	countryId: types.maybeNull(types.number),
	stateId: types.maybeNull(types.number),
	zip: types.maybeNull(types.string),
	notification: types.maybeNull(types.number),
	cv: types.array(CVItem),
	headShot: types.array(HeadshotItem),
	licenses: types.array(LicenseItem),
});

export const ContentCreator = types
	.model({
		items: types.array(ContentCreatorItem),
		biography: types.maybeNull(BiographyItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.id === self.currentId
					);
				} else {
					return null;
				}
			},
			name(item) {
				return `${item.prefix ? item.prefix : ""} ${
					item.firstName ? item.firstName : ""
				} ${item.middleName ? item.middleName : ""} ${
					item.lastName ? item.lastName : ""
				}`;
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ContentCreator/getAllContentCreator`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json;
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadById: flow(function* loadById(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ContentCreator/getContentCreatorById?Id=${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (self.items.some((item) => item.id === id)) {
							applySnapshot(
								self.items.find((item) => item.id === id),
								data
							);
						} else {
							self.items.push(data);
						}

						self.biography =
							data.biography.length > 0
								? data.biography[0]
								: null;
						self.currentId = id;
						self.updateContentType(CONTENT_TYPE.EDIT);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			uploadCV: flow(function* uploadCV(data, snackbar) {
				try {
					const fd = new FormData();
					fd.append("fileUpload.formFile", data);
					fd.append("fileUpload.fileName", data?.name);
					fd.append("contentCreatorId", self.currentId);

					const response = yield fetchApi(
						`${ROOT_URL}/api/ContentCreator/addContentCreatorCV`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.current.cv = [json];
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			uploadHeadshot: flow(function* uploadHeadshot(data, snackbar) {
				try {
					const fd = new FormData();
					fd.append("fileUpload.formFile", data);
					fd.append("fileUpload.fileName", data?.name);
					fd.append("contentCreatorId", self.currentId);

					const response = yield fetchApi(
						`${ROOT_URL}/api/ContentCreator/addContentCreatorHeadshot`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.current.headShot = [json];
						console.log("success");
					} else {
						snackbar.showMessage(
							json?.error || json?.title,
							null,
							null,
							{
								severity: "error",
							}
						);
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			remove: flow(function* remove(id, snackbar) {
				try {
					const contentCreator = self.items.find(
						(item) => item.id === id
					);

					const response = yield fetchApi(
						`${ROOT_URL}/api/ContentCreator/deleteContentCreatorById?id=${id}`,
						getRequestOptions("POST", null)
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(contentCreator);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			save: flow(function* save(data, snackbar) {
				if (self.current) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ContentCreator/UpdateContentCreator`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);

						const json = yield response.json();

						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.updateContentCreatorListItem(json);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveBiography: flow(function* saveBiography(
				data,
				snackbar,
				contentCreatorId
			) {
				if (self.biography) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ContentCreator/updateContentCreatorBiography`,
							getRequestOptions("POST", {
								...self.biography,
								...data,
							})
						);

						const json = yield response.json();

						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.updateBiography(json);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						let response = null;
						response = yield fetchApi(
							`${ROOT_URL}/api/ContentCreator/addContentCreatorBiography`,
							getRequestOptions("POST", {
								...data,
								contentCreatorId: contentCreatorId,
							})
						);

						const json = yield response.json();
						if (response.status === 200) {
							self.updateBiography(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			updateBiography(data) {
				self.biography = data;
			},
			updateContentCreatorListItem(data) {
				if (self.items.find((item) => item?.id === data?.id)) {
					applySnapshot(
						self.items.find((item) => item?.id === data?.id),
						data
					);
				}
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
		};
	});
