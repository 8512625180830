import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, CONTENT_TYPE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const ResearcherItem = types.model({
	sNo: types.identifierNumber,
	id: types.maybeNull(types.string),
	prefix: types.maybeNull(types.string),
	firstName: types.maybeNull(types.string),
	middleName: types.maybeNull(types.string),
	lastName: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	address1: types.maybeNull(types.string),
	address2: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	stateId: types.maybeNull(types.number),
	countryId: types.maybeNull(types.number),
	zip: types.maybeNull(types.string),
	admin: types.maybeNull(types.boolean),
	researchInstitutionSNo: types.maybeNull(types.number),
	researchInstitutionId: types.maybeNull(types.string),
	researchInstitutionName: types.maybeNull(types.string),
});

export const Researchers = types
	.model({
		items: types.array(ResearcherItem),
		current: types.maybeNull(
			types.reference(types.late(() => ResearcherItem))
		),
		fetch_all_status: types.maybeNull(
			types.enumeration("State", ["pending", "done", "error"])
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
	})
	.actions((self) => {
		return {
			loadResearcher: flow(function* loadResearcher() {
				self.fetch_all_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ResearchInstitution/getAllResearchers`,
						getRequestOptions("GET", null)
					);
					self.items = yield response.json();
					self.fetch_all_status = "done";
				} catch (error) {
					console.error("Failed to fetchApi researchers", error);
					self.fetch_all_status = "error";
				}
			}),
			loadResearcherByInstitutionId: flow(
				function* loadResearcherByInstitutionId(
					researchInstitutionSNo,
					researchInstitutionId
				) {
					self.current = null;
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/getResearcherByInstitutionId?researchInstitutionSNo=${researchInstitutionSNo}&researchInstitutionId=${researchInstitutionId}`,
							getRequestOptions("GET", null)
						);
						self.items = yield response.json();
					} catch (error) {
						console.error(
							"Failed to fetchApi Admin Contact",
							error
						);
					}
				}
			),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(
				id,
				researchInstitutionSNo,
				researchInstitutionId
			) {
				if (id) {
					self.current = self.items.find((item) => item.sNo === id);
				} else {
					if (self.items.find((item) => item.sNo === -1)) {
						self.current = self.items.find(
							(item) => item.sNo === -1
						);
					} else {
						self.items.push({
							sNo: -1,
							researchInstitutionSNo: researchInstitutionSNo,
							researchInstitutionId: researchInstitutionId,
						});
						self.current = self.items.find(
							(item) => item.sNo === -1
						);
					}
				}
			},
			updateResearcherListItem(data) {
				applySnapshot(
					self.items.find((item) => item.sNo === data.sNo),
					data
				);
			},
			addResearcherListItem(data) {
				self.items.push(data);
			},
			save: flow(function* save(
				data,
				snackbar,
				// closeDialog,
				reloadResearchInstitution
			) {
				if (self.current?.sNo !== -1) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/updateResearcher`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							if (json.status === 0) {
								// self.updateResearcherListItem(json);
								// self.loadResearcherByInstitutionId(
								//   self.current.researchInstitutionSNo,
								//   self.current.researchInstitutionId
								// );
								// reloadResearchInstitution();
								self.loadResearcher();
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);

								self.updateContentType(CONTENT_TYPE.LIST);
								// closeDialog();
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/createResearcher`,
							getRequestOptions("POST", {
								...data,
								researchInstitutionSNo:
									self.current.researchInstitutionSNo,
								researchInstitutionId:
									self.current.researchInstitutionId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 400) {
								self.addResearcherListItem(json);
								self.loadResearcherByInstitutionId(
									self.current.researchInstitutionSNo,
									self.current.researchInstitutionId
								);
								reloadResearchInstitution();
								snackbar.showMessage(
									"Record Successfully Created!!",
									null,
									null,
									{ severity: "success" }
								);
								self.updateContentType(CONTENT_TYPE.LIST);
								// closeDialog();
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			remove: flow(function* remove(id, snackbar) {
				const item = self.items.find((item) => item.sNo === id);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ResearchInstitution/deleteResearcherById`,
						getRequestOptions("POST", {
							id: item.id,
							sNo: item.sNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 400) {
							self.deleteResearcher(json.sNo);
							snackbar.showMessage(
								"Record Successfully Deleted!!",
								null,
								null,
								{
									severity: "success",
								}
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			deleteResearcher(id) {
				self.current = null;
				destroy(self.items.find((item) => item.sNo === id));
			},
			changeCountryId(id) {
				self.current.countryId = id;
				self.current.stateId = null;
			},
		};
	});
