import { FREQUENCY_DURATION_MAPPING } from "../constants";

export const getSubscriptionPhases= ({
  trialFrequency,
  trialDuration,
  frequency,
  duration,
  amount,
}) => {
  const subscriptionPhases= [];
  let ordinal= 0;
  if (trialFrequency && trialDuration) {
    subscriptionPhases.push({
      "cadence": trialFrequency || 0,
      "periods": parseInt(trialDuration) || 0,
      "ordinal": ordinal,
      "amount": 0,
    });
    ++ordinal;
  }

  subscriptionPhases.push({
    "cadence": frequency || 0,
    "periods": parseInt(duration) || 0,
    "ordinal": ordinal,
    "amount": parseInt(amount || 0),
  });
  return subscriptionPhases;
}

export const extractSubscriptionPhases= (subscriptionPhases) => {
  let extractedSubscription= {};
  if (subscriptionPhases?.length === 1) {
    extractedSubscription= {
      frequency: subscriptionPhases[0]?.cadence,
      duration: subscriptionPhases[0]?.periods,
      amount: subscriptionPhases[0]?.amount,
      trialFrequency: 0,
      trialDuration: 0,
    }
  } else if (subscriptionPhases?.length === 2) {
    extractedSubscription= {
      frequency: subscriptionPhases[1]?.cadence,
      duration: subscriptionPhases[1]?.periods,
      amount: subscriptionPhases[1]?.amount,
      trialFrequency: subscriptionPhases[0]?.cadence,
      trialDuration: subscriptionPhases[0]?.periods,
    };
  }

  return extractedSubscription;
}

export const getCreateSubscriptionPayload=({
    subscriptionCategory,
    subscriptionName,
    frequency,
    duration,
    amount,
    trialFrequency,
    trialDuration,
    clientId,
    clientType,
    isSelectedForPurchase,
})=> {

  let packageAppSubscriptionIDs= [];
  let appSubscriptionID = 0;
  if (subscriptionCategory?.length === 1) {
    appSubscriptionID= subscriptionCategory?.[0]?.appSubscriptionID || 0
  } else if (subscriptionCategory?.length) {
    packageAppSubscriptionIDs= subscriptionCategory.map((item)=> (item.appSubscriptionID));
  } else {
    appSubscriptionID= subscriptionCategory?.appSubscriptionID || 0
  }

  const subscriptionPhases= getSubscriptionPhases({
    trialFrequency,
    trialDuration,
    duration,
    frequency,
    amount,
  });

  const payload= {
    "packageAppSubscriptionIDs": packageAppSubscriptionIDs,
    "subscriptionName": subscriptionName || "" ,
    "clientType": clientType || "ALL",
    "clientID": clientId || 0,
    "subscriptionPhases": subscriptionPhases,
    "isSelectedForPurchase": isSelectedForPurchase || true,
  };

  if (appSubscriptionID) {
    payload.appSubscriptionID= appSubscriptionID;
  }

  return payload;
};

  export const getBundleSubscriptionPayload= ({
    clientSubscription= [],
    appSubscriptionPlan= [],
    clientType,
  })=> {

    const clientSubscriptionPlans= clientSubscription.map((currentPlanId)=> ({
      appSubscriptionPlanID: currentPlanId?.appSubscriptionPlanID,
      isSelectedForPurchase: currentPlanId?.isSelectedForPurchase,
      isSkipTrial: currentPlanId?.isSkipTrial || false,
    })) || [];

    const appSubscriptionPlanRequest= appSubscriptionPlan.map((currentPlan)=> (
      getCreateSubscriptionPayload({
        subscriptionCategory: currentPlan?.subscriptionCategory,
        subscriptionName: currentPlan?.subscriptionName,
        frequency: currentPlan?.frequency,
        duration: currentPlan?.duration,
        amount: currentPlan?.amount,
        trialFrequency: currentPlan?.trialFrequency,
        trialDuration: currentPlan?.trialDuration,
        clientId: currentPlan?.clientId,
        clientType: clientType,
        isSelectedForPurchase: currentPlan?.isSelectedForPurchase,
      })
    ));

    return {
      clientSubscriptionPlans,
      appSubscriptionPlanRequest,
    };
  }

 export const getDurationMappings=(duration, frequency)=> {
    if (duration && frequency) {
      const frequencyArr= FREQUENCY_DURATION_MAPPING[frequency];
      return `${duration} ${duration > 1 ? frequencyArr[1]: frequencyArr[0]}`
    }
    return "";
  }

  export const getStructuredClientSubsDetails= (subscriptions = [])=> {
    return subscriptions.map((subscription)=> {
      const payload= {
        appSubscriptionPlanID: subscription?.appSubscriptionPlanID,
        // amount: subscription?.subscriptionPhases?.[1]?.amount,
        // frequency: subscription?.subscriptionPhases?.[1]?.cadence,
        subscriptionName: subscription?.subscriptionName,
        // duration: subscription?.subscriptionPhases?.[1]?.periods,
        clientType: subscription?.clientType || null,
        isSkipTrial: subscription?.isSkipTrial || false,
        clientID: subscription?.clientID || null,
        // trialDuration: subscription?.subscriptionPhases?.[0]?.periods,
        // trialFrequency: subscription?.subscriptionPhases?.[0]?.cadence,	
        isSelectedForPurchase: subscription?.isSelectedForPurchase,
        isPaymentDone: subscription?.isPaymentDone,
        ...extractSubscriptionPhases(subscription?.subscriptionPhases),
      }
      if (subscription?.appSubscriptions?.length > 1) {
        const ids= [];
        let category= '';
        subscription?.appSubscriptions.forEach((record, idx)=> {
          ids.push(record?.appSubscriptionID);
          if (idx) {
            category+= `, ${record?.name || ""}`;
          } else {
            category+= record?.name || "";
          }
        });
        payload.appSubscriptionIDs= ids;
        payload.categoryName= category;
        payload.appSubscriptionID= null;
      } else {
        payload.appSubscriptionID= subscription?.appSubscriptions?.[0]?.appSubscriptionID;
        payload.categoryName= subscription?.appSubscriptions?.[0]?.name;
        payload.appSubscriptionIDs= null;
      }
      return payload;
    })
  }

  export const getStructuredAppleSubsDetails= (subscriptions = [])=> {
    return subscriptions.map((subscription)=> {
      const payload= {
        appleHealthSubscriptionPlanID: subscription?.appleHealthSubscriptionPlanID,
        // amount: subscription?.subscriptionPhases?.[0]?.amount,
        // frequency: subscription?.subscriptionPhases?.[0]?.cadence,
        subscriptionName: subscription?.subscriptionName,
        // duration: subscription?.subscriptionPhases?.[0]?.periods,
        isSkipTrial: subscription?.isSkipTrial || false,
        isSelectedForPurchase: subscription?.isSelectedForPurchase,
        isPaymentDone: subscription?.isPaymentDone,
        tenantID: subscription?.tenantID || null,
        appleHealthGroupID: subscription?.appleHealthGroups?.[0]?.id,
	      groupName: subscription?.appleHealthGroups?.[0]?.group,
        ...extractSubscriptionPhases(subscription?.subscriptionPhases),
      }
      return payload;
    })
  }

  export const getRandomId= ()=> (Math.random()).toString(36).split(".")[1];