import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const PatientPreviousHealthItem = types.model({
	patientId: types.maybeNull(types.number),
	sNo: types.maybeNull(types.number),
	id: types.maybeNull(types.string),
	providerFirstName: types.maybeNull(types.string),
	providerLastName: types.maybeNull(types.string),
	therapeuticSettingSNo: types.maybeNull(types.number),
	therapeuticSettingId: types.maybeNull(types.string),
	therapeuticProviderSNo: types.maybeNull(types.number),
	therapeuticProviderId: types.maybeNull(types.string),
	facilityName: types.maybeNull(types.string),
	currently: types.maybeNull(types.boolean),
	greaterThan12: types.maybeNull(types.boolean),
	lessThan12: types.maybeNull(types.boolean),
	edit: types.optional(types.boolean, false),
});

export const PatientPreviousHealth = types
	.model({
		items: types.array(PatientPreviousHealthItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		status: types.maybeNull(
			types.enumeration("status", ["pending", "done", "error"])
		),
	})
	.actions((self) => {
		return {
			loadById: flow(function* loadById(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Patient/getPatientTherapeuticHealthByPatientId?patientId=${id}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 0) {
							self.items = data.therapeuticHealthResponse;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			save: flow(function* save(data, snackbar) {
				if (data.id) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Patient/updatePatientTherapeuticHealth`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
								return { status: 400 };
							} else {
								applySnapshot(
									self.items.find(
										(item) => item.id === data.id
									),
									json
								);

								return { status: 200, json: json };
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
							return { status: 400 };
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						return { status: 500 };
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Patient/createPatientTherapeuticHealth`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
								return { status: 400 };
							} else {
								self.items.push(json);
								return { status: 200, json: json };
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
							return { status: 400 };
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						return { status: 500 };
					}
				}
			}),

			remove: flow(function* remove(id, snackbar) {
				try {
					const item = self.items.find((item) => item.id === id);
					const response = yield fetchApi(
						`${ROOT_URL}/api/Patient/deleteTherapeuticHealthById`,
						getRequestOptions("POST", {
							sNo: item.sNo,
							id: item.id,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(item);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
						return { status: 200 };
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
						return { status: 400 };
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					return { status: 500 };
				}
			}),

			updateContentType(contentType) {
				self.contentType = contentType;
			},

			addEmptyItem() {
				self.items.push({});
			},
		};
	});
