import { applySnapshot, flow, types, getEnv } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const LocationItem = types.model({
	locationId: types.maybeNull(types.number),
});

const ClinicianItem = types.model({
	clinicianId: types.number,
	clinicianPrefix: types.maybeNull(types.string),
	clinicianFirstName: types.maybeNull(types.string),
	clinicianMiddleName: types.maybeNull(types.string),
	clinicianLastName: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	dob: types.maybeNull(types.string),
	clinicId: types.maybeNull(types.number),
	researchDataAccess: types.optional(types.boolean, false),
	clinicLocation: types.array(LocationItem),
	canViewCompletedSurvey: types.optional(types.boolean, true),
	canAddPatientToGroup: types.optional(types.boolean, true),
	canViewIndividualPatient: types.optional(types.boolean, true),
	supervisorId: types.maybeNull(types.number),
	supervisorLicenseNo: types.maybeNull(types.string),
	supervisorApproval: types.maybeNull(types.number),
	locationIds: types.array(types.number),
});

const AnswerItem = types.model({
	answerId: types.maybeNull(types.number),
	answerName: types.maybeNull(types.string),
	weightage: types.maybeNull(types.number),
	sequence: types.maybeNull(types.number),
	selected: types.maybeNull(types.boolean),
});

const QuestionItem = types.model({
	questionId: types.maybeNull(types.number),
	questionName: types.maybeNull(types.string),
	checkbox: types.maybeNull(types.boolean),
	radio: types.maybeNull(types.boolean),
	textbox: types.maybeNull(types.boolean),
	switch: types.maybeNull(types.boolean),
	singleSelect: types.maybeNull(types.boolean),
	multiSelect: types.maybeNull(types.boolean),
	answers: types.array(AnswerItem),
	label: types.maybeNull(types.boolean),
	textAnswer: types.maybeNull(types.string),
	submitted: types.maybeNull(types.boolean),
});

const SectionItem = types.model({
	sectionId: types.maybeNull(types.number),
	name: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	questions: types.array(QuestionItem),
	pagebreak: types.maybeNull(types.boolean),
});

const SurveyItem = types.model({
	surveyId: types.maybeNull(types.number),
	patientId: types.maybeNull(types.number),
	patientName: types.maybeNull(types.string),
	surveyName: types.maybeNull(types.string),
	activityName: types.maybeNull(types.string),
	assessmentDetails: types.maybeNull(types.string),
	dueDate: types.maybeNull(types.string),
	assignedDate: types.maybeNull(types.string),
	preReminder: types.maybeNull(types.number),
	postReminder: types.maybeNull(types.number),
	isSubmitted: types.maybeNull(types.boolean),
	submittedDate: types.maybeNull(types.string),
	showToPatient:types.maybeNull(types.boolean),
	sections: types.array(SectionItem),
});

export const Clinician = types
	.model({
		items: types.array(ClinicianItem),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		filledSurveys: types.array(SurveyItem),
		pendingSurveys: types.array(SurveyItem),
		fetch_all_status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		currentId: types.maybeNull(types.number),
	})
	.views((self) => {
		return {
			clinicianName(item) {
				if (item) {
					return `${
						item.clinicianPrefix ? item.clinicianPrefix : ""
					} ${
						item.clinicianFirstName ? item.clinicianFirstName : ""
					} ${
						item.clinicianMiddleName ? item.clinicianMiddleName : ""
					} ${item.clinicianLastName ? item.clinicianLastName : ""}`;
				}
			},
			getItemById(id) {
				return self.items.find((item) => item.clinicianId === id);
			},
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) => item.clinicianId === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load() {
				self.fetch_all_status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinician/getAllClinician`,
						getRequestOptions("GET", null)
					);
					self.items = yield response.json();
					self.fetch_all_status = "done";
				} catch (error) {
					console.error("Failed to fetchApi patient groups", error);
					self.fetch_all_status = "error";
				}
			}),
			reloadClinicians() {
				self.load();
			},
			updateSurveyShowToPatient: flow(function* updateSurveyShowToPatient(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/updateSurveyShowToPatient`,
						getRequestOptions("POST", data)
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json.error, "error");
						} else {
							applySnapshot(
								self.filledSurveys.find(
									(item) => item.surveyId === data.surveyId
								),
								json
							);
						}
					} else {
						getEnv(self).notifier.enqueue(json.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			getCompletedSurveyByClinicId: flow(
				function* getCompletedSurveyByClinicId(snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinician/getCompletedSurveyByClinicId`,
							getRequestOptions("GET", null)
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.filledSurveys = data;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),
			getPendingSurveyByClinicId: flow(
				function* getPendingSurveyByClinicId(snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinician/getPendingSurveyByClinicId`,
							getRequestOptions("GET", null)
						);
						const data = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.pendingSurveys = data;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),

			loadClinicianById: flow(function* loadClinicianById(
				id,
				loadLocationsByClinicId
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinician/getClinicianById?Id=${id}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					loadLocationsByClinicId(json.clinicId);
					self.updateClinicianListItem(json);

					self.updateLocationIds(json);
				} catch (error) {
					console.error("Failed to fetchApi patient groups", error);
				}
			}),
			save: flow(function* save(data, snackbar) {
				if (self.current) {
					self.current.clinicLocation.clear();
				}

				let clinicLocations = [];
				for (let i = 0; i <= data.locationIds.length; i++) {
					if (
						parseInt(data.locationIds[i]) !== 0 &&
						parseInt(data.locationIds[i])
					) {
						clinicLocations.push({
							locationId: parseInt(data.locationIds[i]),
						});
					}
				}

				if (self.current) {
					self.current.clinicLocation = clinicLocations;
				}

				if (self.current) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinician/updateClinician`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							self.updateClinicianListItem(json);
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinician/createClinician`,
							getRequestOptions("POST", {
								...data,
								clinicLocation: clinicLocations,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.addClinicianListItem(json);
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							self.updateCurrentItem(json.clinicianId);
							self.updateContentType(CONTENT_TYPE.LIST);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			updateClinicianListItem(data) {
				if (
					self.items.find(
						(item) => item.clinicianId === data.clinicianId
					)
				) {
					applySnapshot(
						self.items.find(
							(item) => item.clinicianId === data.clinicianId
						),
						data
					);
				}
			},
			updateLocationIds(json) {
				const locations = json.clinicLocation;
				for (let i = 0; i < locations.length; i++) {
					self.current.locationIds.push(locations[i].locationId);
				}
				if (locations.length === 0) {
					self.current.locationIds = [];
				}
			},
			addClinicianListItem(data) {
				self.items.push(data);
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
		};
	});
