import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { jsonToFormData } from "../../helpers";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const CECreditItem = types.model({
	creditId: types.number,
	creditOther: types.maybeNull(types.string),
});

const CE1Item = types.model({
	continuingEducationId: types.number,
	bestPractices: types.maybeNull(types.boolean),
	gap: types.maybeNull(types.boolean),
	educationalIntervention: types.maybeNull(types.boolean),
	patientHealthOutcome: types.maybeNull(types.boolean),
	statements: types.maybeNull(types.string),
	reference: types.maybeNull(types.string),
});

const CE2Item = types.model({
	continuingEducationId: types.number,
	focusPanels: types.maybeNull(types.boolean),
	surveyOfTargetedLearner: types.maybeNull(types.boolean),
	peerReviewedLiterature: types.maybeNull(types.boolean),
	keyOpinionInterviews: types.maybeNull(types.boolean),
	previousEvaluation: types.maybeNull(types.boolean),
	nationalGuidelines: types.maybeNull(types.boolean),
	expertOpinion: types.maybeNull(types.boolean),
	summaries: types.maybeNull(types.boolean),
});

const CE3Item = types.model({
	continuingEducationId: types.number,
	identifiedGap: types.maybeNull(types.string),
	desiredOutcomes: types.maybeNull(types.string),
	knowledge: types.maybeNull(types.boolean),
	competence: types.maybeNull(types.boolean),
	performance: types.maybeNull(types.boolean),
	patientOutcomes: types.maybeNull(types.boolean),
});

const CE4Item = types.model({
	continuingEducationId: types.number,
	patientCare: types.maybeNull(types.boolean),
	medicalKnowledge: types.maybeNull(types.boolean),
	practiceBasedLearning: types.maybeNull(types.boolean),
	interpersonalCommunication: types.maybeNull(types.boolean),
	professionalism: types.maybeNull(types.boolean),
	systemBasedPractice: types.maybeNull(types.boolean),
	valueEthics: types.maybeNull(types.boolean),
	rolesResponsibilities: types.maybeNull(types.boolean),
	interprofessionalCom: types.maybeNull(types.boolean),
	teamsTeamWork: types.maybeNull(types.boolean),
});

const CE5Item = types.model({
	continuingEducationId: types.number,
	isMultipleModality: types.maybeNull(types.number),
	livePresentation: types.maybeNull(types.boolean),
	enduringMaterial: types.maybeNull(types.boolean),
	qualityImprovement: types.maybeNull(types.boolean),
	rational: types.maybeNull(types.string),
});

const CE6AItem = types.model({
	continuingEducationId: types.number,
	facultyName: types.maybeNull(types.string),
	titleAffiliation: types.maybeNull(types.string),
	disclosureStatement: types.maybeNull(types.string),
	cvBio: types.maybeNull(types.string),
});

const CE6BItem = types.model({
	continuingEducationId: types.number,
	name: types.maybeNull(types.string),
	role: types.maybeNull(types.string),
	disclosures: types.maybeNull(types.string),
});

const CE7Item = types.model({
	continuingEducationId: types.number,
	commitmentToChange: types.maybeNull(types.string),
	increasedKnowledge: types.maybeNull(types.boolean),
	improvementCompetency: types.maybeNull(types.boolean),
	enhancedPerformance: types.maybeNull(types.boolean),
	betterPatientOutcome: types.maybeNull(types.boolean),
	level1: types.maybeNull(types.boolean),
	level2: types.maybeNull(types.boolean),
	level3: types.maybeNull(types.boolean),
	level4: types.maybeNull(types.boolean),
	level5: types.maybeNull(types.boolean),
	level6: types.maybeNull(types.boolean),
	telmsModel: types.maybeNull(types.boolean),
	telmsDescribe: types.maybeNull(types.string),
	preSurvey: types.maybeNull(types.boolean),
	postTest: types.maybeNull(types.boolean),
	arsQuestion: types.maybeNull(types.boolean),
	controlGroupSurvey: types.maybeNull(types.boolean),
	followUpSurvey: types.maybeNull(types.boolean),
	caseVignette: types.maybeNull(types.boolean),
	other7a: types.maybeNull(types.boolean),
	other7aDescribe: types.maybeNull(types.string),
	preliminaryReport: types.maybeNull(types.boolean),
	finalReport: types.maybeNull(types.boolean),
	executiveSummary: types.maybeNull(types.boolean),
	posterSubmission: types.maybeNull(types.boolean),
	selectOutcome: types.maybeNull(types.boolean),
	blogPost: types.maybeNull(types.boolean),
	other7b: types.maybeNull(types.boolean),
	other7bDescribe: types.maybeNull(types.string),
});

const CE8BItem = types.model({
	continuingEducationId: types.number,
	discussion: types.maybeNull(types.string),
	systemBarrier: types.maybeNull(types.string),
});

const CE8Item = types.model({
	continuingEducationId: types.number,
	cE08Detail: types.maybeNull(types.array(CE8BItem)),
	isBarriers: types.maybeNull(types.boolean),
});

const CE9Item = types.model({
	continuingEducationId: types.number,
	source1: types.maybeNull(types.string),
	source2: types.maybeNull(types.string),
	source3: types.maybeNull(types.string),
	source4: types.maybeNull(types.string),
	source5: types.maybeNull(types.string),
});

const CE10Item = types.model({
	continuingEducationId: types.maybeNull(types.number),
	continuingEducationSNo: types.maybeNull(types.string),
	socialMediaMarketing: types.maybeNull(types.boolean),
	creditHours: types.maybeNull(types.string),
});

const CELinkItem = types.model({
	continuingEducationId: types.maybeNull(types.number),
	continuingEducationSNo: types.maybeNull(types.string),
	linkAddress: types.maybeNull(types.string),
});

const CEGeneralItem = types.model({
	continuingEducationId: types.number,
	continuingEducationSNo: types.maybeNull(types.string),
	dateOfNewPlanning: types.maybeNull(types.string),
	dateOfFinalPlanning: types.maybeNull(types.string),
	activityTitlePrimary: types.maybeNull(types.string),
	activityTitleOtherVersion: types.maybeNull(types.string),
	projectCodesLive: types.maybeNull(types.string),
	projectCodesLiveStream: types.maybeNull(types.string),
	projectCodesEnduring: types.maybeNull(types.string),
	projectCodesLiveEnduring: types.maybeNull(types.string),
	targetAudienceLive: types.maybeNull(types.string),
	targetAudienceLiveStream: types.maybeNull(types.string),
	targetAudienceEnduring: types.maybeNull(types.string),
	activityStartsDateAndTime: types.maybeNull(types.string),
	doorsOpenDateAndTime: types.maybeNull(types.string),
	enduringDateAndTime: types.maybeNull(types.string),
	locationOrURLLive: types.maybeNull(types.string),
	locationOrURLLiveStream: types.maybeNull(types.string),
	locationOrURLEnduring: types.maybeNull(types.string),
	sourcesOfFunding: types.maybeNull(types.string),
	commercialSupporterStatement: types.maybeNull(types.string),
	typeOfCertification: types.maybeNull(types.number),
	collaborativeInfo: types.maybeNull(types.string),
	enduringMaterialsWebArchive: types.maybeNull(types.string),
	enduringMaterialPodcast: types.maybeNull(types.string),
	continuingEducationCredits: types.array(CECreditItem),
});

const UploadItem = types.model({
	uploadId: types.maybeNull(types.number),
	uploadType: types.maybeNull(types.string),
	uploadURL: types.maybeNull(types.string),
	uploadContentType: types.maybeNull(types.string),
	uploadFile: types.maybeNull(types.string),
	uploadSection: types.maybeNull(types.number),
});

export const CE = types
	.model({
		general: types.maybeNull(CEGeneralItem),
		ce1: types.maybeNull(CE1Item),
		ce2: types.maybeNull(CE2Item),
		ce3: types.array(CE3Item),
		ce4: types.maybeNull(CE4Item),
		ce5: types.maybeNull(CE5Item),
		ce6a: types.array(CE6AItem),
		ce6b: types.array(CE6BItem),
		ce7: types.maybeNull(CE7Item),
		ce8: types.maybeNull(CE8Item),
		ce9: types.maybeNull(CE9Item),
		ce10: types.maybeNull(CE10Item),
		continuingEducationUploads: types.array(UploadItem),
		ceLinks: types.array(CELinkItem),
	})
	.views((self) => {
		return {
			continuingEducationCredits(credits, other_id) {
				let temp_credits = [];
				credits.forEach((item) => {
					if (
						self.general?.continuingEducationCredits.some(
							(cred) => cred.creditId === item.creditId
						)
					) {
						if (item.creditId === other_id) {
							temp_credits.push({
								...item,
								creditOther:
									self.general?.continuingEducationCredits.find(
										(cr) => cr.creditId === other_id
									)?.creditOther,
							});
						} else {
							temp_credits.push(item);
						}
					}
				});
				return temp_credits;
			},
		};
	})
	.actions((self) => {
		return {
			loadCompleteCE: flow(function* loadById(id, sNo, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCompleteCEByCourseId?courseId=${id}&courseSNo=${sNo}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 0) {
							self.general = data;
							self.ce1 = data.cE01s;
							self.ce2 = data.cE02s;
							self.ce3 = data.cE03s;
							self.ce4 = data.cE04s;
							self.ce5 = data.cE05s;
							self.ce6a = data.cE06As;
							self.ce6b = data.cE06Bs;
							self.ce7 = data.cE07s;
							self.ce8 = data.cE08s;
							self.ce9 = data.cE09s;
							self.ce10 = data.cE10s;
							self.continuingEducationUploads =
								data.continuingEducationUploads;
							self.ceLinks = data.ceLinks;
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			clearCompleteCEDataForm: () => {
				self.general = null;
				self.ce1 = null;
				self.ce2 = null;
				self.ce3 = [];
				self.ce4 = null;
				self.ce5 = null;
				self.ce6a = [];
				self.ce6b = [];
				self.ce7 = null;
				self.ce8 = null;
				self.ce9 = null;
				self.ce10 = null;
				self.continuingEducationUploads = [];
				self.ceLinks = [];
		 	},
			changeCENonCE: flow(function* changeCENonCE(
				isCE,
				course,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/changeCENonCE`,
						getRequestOptions("POST", {
							courseId: course?.id,
							courseSNo: course?.sNo,
							isCECourse: isCE,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 0) {
							self.general = data;
							self.ce1 = data.cE01s;
							self.ce2 = data.cE02s;
							self.ce3 = data.cE03s;
							self.ce4 = data.cE04s;
							self.ce5 = data.cE05s;
							self.ce6a = data.cE06As;
							self.ce6b = data.cE06Bs;
							self.ce7 = data.cE07s;
							self.ce8 = data.cE08s;
							self.ce9 = data.cE09s;
							self.ce10 = data.cE10s;
							self.continuingEducationUploads =
								data.continuingEducationUploads;
							self.ceLinks = data.ceLinks;
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			save: flow(function* save(data, snackbar, course) {
				if (self.general?.continuingEducationId || 
					course?.continuingEducation?.continuingEducationId
				) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateContinuingEducation`,
							getRequestOptions("POST", {
								...self.general,
								...data,
								courseId: course?.id,
								courseSNo: course?.sNo,
								continuingEducationId: course?.continuingEducation?.continuingEducationId || self.general?.continuingEducationId
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.general = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addContinuingEducation`,
							getRequestOptions("POST", {
								...data,
								courseId: course?.id,
								courseSNo: course?.sNo,
							})
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							applySnapshot(self.general, json);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveCE1: flow(function* saveCE1(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE01`,
							getRequestOptions("POST", {
								...self.ce1,
								...data,
								continuingEducationId:
									self.general?.continuingEducationId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.ce1 = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveCE2: flow(function* saveCE2(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE02`,
							getRequestOptions("POST", {
								...self.ce2,
								...data,
								continuingEducationId:
									self.general?.continuingEducationId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.ce2 = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveCE3: flow(function* saveCE3(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE03`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.ce3 = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveCE4: flow(function* saveCE4(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE04`,
							getRequestOptions("POST", {
								...self.ce4,
								...data,
								continuingEducationId:
									self.general?.continuingEducationId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.ce4 = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveCE5: flow(function* saveCE5(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE05`,
							getRequestOptions("POST", {
								...self.ce5,
								...data,
								continuingEducationId:
									self.general?.continuingEducationId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.ce5 = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveCE6A: flow(function* saveCE6A(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE06a`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.ce6a = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveCE6B: flow(function* saveCE6B(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE06b`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.ce6b = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveCE7: flow(function* saveCE7(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE07`,
							getRequestOptions("POST", {
								...self.ce7,
								...data,
								continuingEducationId:
									self.general?.continuingEducationId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.ce7 = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveCE8: flow(function* saveCE8(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE08`,
							getRequestOptions("POST", {
								...self.ce8,
								...data,
								continuingEducationId:
									self.general?.continuingEducationId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.ce8 = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveCE9: flow(function* saveCE9(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE09`,
							getRequestOptions("POST", {
								...self.ce9,
								...data,
								continuingEducationId:
									self.general?.continuingEducationId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.ce9 = json;
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveCE10: flow(function* saveCE10(data, snackbar) {
				if (self.general?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCE10`,
							getRequestOptions("POST", {
								...self.ce10,
								...data,
								continuingEducationId:
									self.general?.continuingEducationId,
								continuingEducationSNo:
									self.general?.continuingEducationSNo,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								if (!self.ce10) {
									self.ce10 = json;
								} else {
									self.ce10 = json;
								}
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			deleteContinuingEducationUpload: flow(
				function* deleteContinuingEducationUpload(file, snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteContinuingEducationUpload`,
							getRequestOptions("POST", {
								uploadId: file.uploadId,
							})
						);
						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							destroy(file);
							snackbar.showMessage(
								"Record Successfully Deleted!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),

			addCELink: flow(function* addCELink(link, snackbar, callback) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addCELink`,
						getRequestOptions("POST", {
							continuingEducationId:
								self.general?.continuingEducationId,
							continuingEducationSNo:
								self.general?.continuingEducationSNo,
							ceLinkRequest: [
								...self.ceLinks,
								{
									continuingEducationId:
										self.general?.continuingEducationId,
									continuingEducationSNo:
										self.general?.continuingEducationSNo,
									linkAddress: link,
								},
							],
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.ceLinks = data;
						callback();
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeCELink: flow(function* removeCELink(links, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addCELink`,
						getRequestOptions("POST", {
							continuingEducationId:
								self.general?.continuingEducationId,
							continuingEducationSNo:
								self.general?.continuingEducationSNo,
							ceLinkRequest: links,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.ceLinks = data;
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			addContinuingEducationUpload: flow(
				function* addContinuingEducationUpload(
					data,
					snackbar,
					removeLoader
				) {
					if (self.general?.continuingEducationId) {
						const fd = jsonToFormData({
							...data,
							CEId: self.general?.continuingEducationId,
							CESNo: self.general?.continuingEducationSNo,
						});
						try {
							const response = yield fetchApi(
								`${ROOT_URL}/api/Course/addContinuingEducationUpload`,
								getRequestOptions("POST", fd, true)
							);
							const json = yield response.json();
							if (response.status === 200) {
								if (json.status !== 0) {
									snackbar.showMessage(
										json.error,
										null,
										null,
										{
											severity: "error",
										}
									);
									removeLoader();
								} else {
									if (
										!self.continuingEducationUploads?.some(
											(upload) =>
												upload.uploadId ===
												json.uploadId
										)
									) {
										self.continuingEducationUploads.push(
											json
										);
									}

									snackbar.showMessage(
										"File Successfully Uploaded!!",
										null,
										null,
										{ severity: "success" }
									);
									removeLoader();
								}
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
								removeLoader();
							}
						} catch (e) {
							snackbar.showMessage(
								API_ERROR_MESSAGE,
								null,
								null,
								{
									severity: "error",
								}
							);
							removeLoader();
						}
					}
				}
			),
		};
	});
