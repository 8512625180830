import moment from "moment";

export const getStartDate = (sessions) => {
	var date = new Date();
	date.setDate(date.getDate() + ((4 + 7 - date.getDay()) % 7));
	return date;
};

export const getEndDate = (sessions) => {
	const startDate = getStartDate(sessions);
	let endDate = new Date();
	endDate.setDate(startDate.getDate() + (sessions - 1) * 7);
	return endDate;
};

export const getEndDateContinous = (sessions) => {
	const startDate = getStartDate(sessions);
	let endDate = new Date();
	endDate.setDate(startDate.getDate() + (sessions - 1));
	return endDate;
};

export const getNextThursday = (previousDate) => {
	const nextDate = moment(previousDate).add(7, "days");
	return nextDate.toDate();
};

export const getNextDay = (previousDate) => {
	const nextDate = moment(previousDate).add(1, "days");
	return nextDate.toDate();
};
