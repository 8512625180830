import { flow, getEnv, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const StateItem = types.model({
	stateId: types.identifierNumber,
	countryId: types.number,
	stateName: types.string,
});

export const State = types
	.model({
		items: types.array(StateItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			getStatesByCountryId(id) {
				return self.items.filter((item) => item.countryId === id);
			},
		};
	})
	.actions((self) => {
		return {
			// afterCreate() {
			// 	self.load();
			// },
			load: flow(function* load() {
				if (self?.items?.length) return;
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getAllState`,
						getRequestOptions("GET", null)
					);
					const items = yield response.json();
					if (items?.length) {
						self.items = items;
					}
					self.status = "done";
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					self.status = "error";
				}
			}),
		};
	});
