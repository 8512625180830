import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const CreditItem = types.model({
	id: types.number,
	creditId: types.number,
	name: types.string,
});

export const Credit = types
	.model({
		items: types.array(CreditItem),
		fetch_all_status: types.optional(
			types.enumeration("fetch_all_status", ["pending", "done", "error"]),
			"pending"
		),
	})
	.views((self) => {
		return {
			get other() {
				return self.items.find(
					(item) => item.name === "Other (specify):"
				)?.id;
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllCredit`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.items = json.map((item) => {
							item.creditId = item.id;
							return item;
						});
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
		};
	});
