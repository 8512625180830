import { CircularProgress, Container, Typography } from "@mui/material";
import { Logo } from ".";

const JsonLoadingScreen = (props) => {
	return (
		<Container component='main' maxWidth='xs'
			sx={{
				minHeight: "100%",
				py: 1,
				alignItems: "center",
				display: "flex",
				flexDirection: "column",
				height: "100%",
				justifyContent: "center",
			}}>
			<Logo />

			<CircularProgress
				style={{
					marginTop: "5vh",
					width: "30px",
					height: "30px",
				}}
			/>
			<Typography
				variant='body2'
				color='primary'
				style={{ marginTop: "1vh" }}>
				<i>{props.text ? props.text : "Loading"}</i> {props.type}
			</Typography>
		</Container>
	);
};

export default JsonLoadingScreen;
