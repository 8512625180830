import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	DEFAULT_COUNTRY_ID,
	ROOT_URL,
} from "../../constants";
import { fetchApi } from "../../utils/CustomFetch";
import { getRequestOptions } from "../../utils/RequestOptions";

const ContactItem = types.model({
	clinicContactId: types.identifierNumber,
	contactName: types.maybeNull(types.string),
	contactPhone: types.maybeNull(types.string),
	contactEmail: types.maybeNull(types.string),
	isPrimary: types.maybeNull(types.boolean),
	locationId: types.maybeNull(types.number),
	clinicId: types.maybeNull(types.number),
});

const LocationItem = types.model({
	locationId: types.identifierNumber,
	locationName: types.maybeNull(types.string),
	address1: types.maybeNull(types.string),
	address2: types.maybeNull(types.string),
	city: types.maybeNull(types.string),
	isPrimary: types.maybeNull(types.boolean),
	clinicId: types.maybeNull(types.number),
	stateId: types.maybeNull(types.number),
	countryId: types.maybeNull(types.number),
	zip: types.maybeNull(types.string),
	practiceTypeId: types.maybeNull(types.number),
	clinicContact: types.array(ContactItem),
});

export const Location = types
	.model({
		items: types.array(LocationItem),
		current: types.maybeNull(
			types.reference(types.late(() => LocationItem))
		),
		currentContact: types.maybeNull(
			types.reference(types.late(() => ContactItem))
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
	})
	.actions((self) => {
		return {
			loadLocationsByCLinicId: flow(function* loadLocationsByCLinicId(
				id
			) {
				self.current = null;
				self.currentContact = null;
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/getLocationByClinicId?clinicId=${id}`
					);
					self.items = yield response.json();
				} catch (error) {
					console.error("Failed to fetchApi patient groups", error);
				}
			}),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id, clinicId) {
				if (id) {
					self.current = self.items.find(
						(item) => item.locationId === id
					);
				} else {
					if (self.items.find((item) => item.locationId === -1)) {
						self.current = self.items.find(
							(item) => item.locationId === -1
						);
					} else {
						self.items.push({
							locationId: -1,
							countryId: DEFAULT_COUNTRY_ID,
							clinicId: clinicId,
						});
						self.current = self.items.find(
							(item) => item.locationId === -1
						);
					}
				}
			},
			updateCurrentContactItem(id, locationId, clinicId) {
				if (id) {
					self.currentContact = self.items
						.find((item) => item.locationId === locationId)
						?.clinicContact?.find(
							(item) => item.clinicContactId === id
						);
				} else {
					if (
						self.items
							.find((item) => item.locationId === locationId)
							?.clinicContact?.find(
								(item) => item.clinicContactId === -1
							)
					) {
						self.currentContact = self.items
							.find((item) => item.locationId === locationId)
							?.clinicContact?.find(
								(item) => item.clinicContactId === -1
							);
					} else {
						self.items
							.find((item) => item.locationId === locationId)
							?.clinicContact?.push({
								clinicContactId: -1,
								locationId: locationId,
								clinicId: clinicId,
							});
						self.currentContact = self.items
							.find((item) => item.locationId === locationId)
							?.clinicContact?.find(
								(item) => item.clinicContactId === -1
							);
					}
				}
			},
			updateLocationListItem(data) {
				applySnapshot(
					self.items.find(
						(item) => item.locationId === data.locationId
					),
					data
				);
			},
			addLocationListItem(data) {
				self.items.push(data);
			},
			save: flow(function* save(
				data,
				snackbar,
				closeDialog,
				reloadClinic
			) {
				if (self.current?.locationId !== -1) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinic/updateLocation`,
							getRequestOptions('POST', {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							self.updateLocationListItem(json);
							self.loadLocationsByCLinicId(self.current.clinicId);
							reloadClinic();
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);

							self.updateContentType(CONTENT_TYPE.LIST);
							closeDialog();
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinic/createLocation`,
							getRequestOptions('POST', {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.addLocationListItem(json);
							self.loadLocationsByCLinicId(self.current.clinicId);
							reloadClinic();
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							self.updateContentType(CONTENT_TYPE.LIST);
							closeDialog();
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			updateContactListItem(data) {
				applySnapshot(
					self.items
						.find((item) => item.locationId === data.locationId)
						.clinicContact.find(
							(item) =>
								item.clinicContactId === data.clinicContactId
						),
					data
				);
			},
			addContactListItem(data) {
				self.items
					.find(
						(item) =>
							item.locationId === self.currentContact.locationId
					)
					.clinicContact.push(data);
			},
			saveContact: flow(function* saveContact(
				data,
				snackbar,
				closeDialog,
				reloadClinic
			) {
				if (self.currentContact?.clinicContactId !== -1) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinic/updateContact`,
							getRequestOptions('POST', {
								...self.currentContact,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							self.updateContactListItem(json);
							self.loadLocationsByCLinicId(
								self.currentContact.clinicId
							);
							reloadClinic();
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);

							self.updateContentType(CONTENT_TYPE.LIST);
							closeDialog();
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Clinic/createContact`,
							getRequestOptions('POST', {
								...self.currentContact,
								...data,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							self.addContactListItem(json);
							self.loadLocationsByCLinicId(
								self.currentContact.clinicId
							);
							reloadClinic();
							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
							self.updateContentType(CONTENT_TYPE.LIST);
							closeDialog();
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			remove: flow(function* remove(id, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/deleteLocationById?id=${id}`,
						getRequestOptions('POST', null)
					);
					const json = yield response.json();
					if (response.status === 200) {
						self.deleteLocation(id);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			deleteLocation(id) {
				self.current = null;
				destroy(self.items.find((item) => item.locationId === id));
			},
			removeContact: flow(function* remove(id, locationId, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinic/deleteContactById?id=${id}`,
						getRequestOptions('POST', null)
					);
					const json = yield response.json();
					if (response.status === 200) {
						self.deleteContact(id, locationId);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			deleteContact(id, locationId) {
				self.currentContact = null;
				destroy(
					self.items
						.find((item) => item.locationId === locationId)
						.clinicContact.find(
							(item) => item.clinicContactId === id
						)
				);
			},
			changeCountryId(id) {
				self.current.countryId = id;
				self.current.stateId = null;
			},
		};
	});
