import { flow, getEnv, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { extractSubscriptionPhases } from "../../utils/Utilities";
import { fetchApi } from "../../utils/CustomFetch";

const Attributes = types.model({
	appleHealthAttributeID: types.maybeNull(types.number),
	attribute: types.maybeNull(types.string),
});

const Group = types.model({
	id: types.maybeNull(types.number),
	group: types.maybeNull(types.string),
	attributes: types.maybeNull(types.array(Attributes)),
});

const HealthGroup = types.model({
	appleHealthGroupID: types.maybeNull(types.number),
	groupName: types.maybeNull(types.string),
});

const AppleHealthSubscriptions = types.model({
	appleHealthGroupID: types.maybeNull(types.number),
	groupName: types.maybeNull(types.string),
	appleHealthSubscriptionPlanID: types.maybeNull(types.number),
	amount: types.maybeNull(types.number),
	frequency: types.maybeNull(types.number),
	subscriptionName: types.maybeNull(types.string),
	duration: types.maybeNull(types.number),
	isSkipTrial: types.maybeNull(types.boolean),
	isExcluded: types.maybeNull(types.boolean),
	trialDuration: types.maybeNull(types.number),
	trialFrequency: types.maybeNull(types.number),
});

export const AppleHealth = types
	.model({
		status: types.optional(types.string, "done"),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		groups: types.maybeNull(types.array(Group)),
		healthGroups: types.maybeNull(types.array(HealthGroup)),
		attributes: types.array(types.string),
		items: types.maybeNull(types.array(AppleHealthSubscriptions)),
		current: types.maybeNull(
			types.reference(types.late(() => AppleHealthSubscriptions))
		),
	})
	.actions((self) => {
		return {
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				if (id) {
					// self.current = self.items.find(
					// 	(item) => item.appSubscriptionID === id
					// );
				} else {
					self.current = null;
				}
			},
			getAppleHealthSubscriptions: flow(
				function* getAppleHealthSubscriptions(
					snackbar,
					callback = () => {}
				) {
					self.status = "pending";
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/admin/getapplehealthsubscriptions`,
							getRequestOptions("GET", null)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							const healthGroups = [];
							const items = [];
							json?.forEach((currentGroup) => {
								healthGroups.push({
									appleHealthGroupID:
										currentGroup?.appleHealthGroupID,
									groupName: currentGroup?.groupName,
								});

								const subscriptions =
									currentGroup?.appleHealthSubscriptionPlans?.map(
										(currentSubscription) => ({
											appleHealthGroupID:
												currentGroup?.appleHealthGroupID,
											groupName: currentGroup?.groupName,
											appleHealthSubscriptionPlanID:
												currentSubscription?.appleHealthSubscriptionPlanID,
											// amount: currentSubscription?.subscriptionPhases?.[0]?.amount,
											// frequency: currentSubscription?.subscriptionPhases?.[0]?.cadence,
											subscriptionName:
												currentSubscription?.subscriptionName,
											// duration: currentSubscription?.subscriptionPhases?.[0]?.periods,
											isSkipTrial:
												currentSubscription?.isSkipTrial ||
												false,
											isExcluded:
												currentSubscription?.isExcluded ||
												false,
											...extractSubscriptionPhases(
												currentSubscription?.subscriptionPhases
											),
										})
									);
								items?.push(...subscriptions);
							});
							self.items = items || [];
							self.healthGroups = healthGroups || [];
							self.status = "done";
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							self.status = "error";
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				}
			),
			addAppleHealthSubscription: flow(
				function* addAppleHealthSubscription(
					data,
					snackbar,
					callback = () => {}
				) {
					self.status = "pending";
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/admin/addapplehealthsubscriptionplan`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.status = "done";
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							self.status = "error";
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				}
			),
			getAppleHealthGroups: flow(function* getAppleHealthGroups(
				snackbar,
				callback = () => {}
			) {
				if (self?.groups?.length) return;
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/admin/applehealthgroup`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json?.length) {
							const attributesSet = {};
							for (const currentGroup of json) {
								const attributesResponse = yield fetchApi(
									`${ROOT_URL}/api/admin/applehealthattribute?appleHealthGroupID=${currentGroup.id}`,
									getRequestOptions("GET", null)
								);
								const attributes =
									yield attributesResponse.json();
								attributesSet[currentGroup?.id] = {
									...currentGroup,
									attributes,
								};
							}
							self.groups = Object.values(attributesSet);
						}
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),

			appleHealthSubscriptionsForTenant: flow(
				function* appleHealthSubscriptionsForTenant() {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/admin/applehealthsubscriptionsfortenant`,
							getRequestOptions("GET", null)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.attributes = json;
						} else {
							getEnv(self).notifier.enqueue(
								json?.message,
								"error"
							);
						}
					} catch (error) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				}
			),

			getAttributeData: flow(function* getAttributeData(
				attribute,
				userGUID,
				toDate,
				fromDate
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/appleHealth/getattributedata?attribute=${attribute}&userGUID=${userGUID}&toDate=${toDate}&fromDate=${fromDate}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						return json;
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
						return [];
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					return [];
				}
			}),

			getAttributeDataForPatient: flow(function* getAttributeDataForPatient(
				attribute,
				toDate,
				fromDate
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/appleHealth/getattributedataforpatient?attribute=${attribute}&toDate=${toDate}&fromDate=${fromDate}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						return json;
					} else {
						getEnv(self).notifier.enqueue(json?.message, "error");
						return [];
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					return [];
				}
			}),
		};
	});
