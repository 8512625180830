import React from "react";
import { createContext } from "react";
import { rootStore } from "../models";

//Store Context for App
export const StoreContext = createContext(rootStore);

//Store Provider Store for App
export const StoreProvider = ({ children }) => {
	return (
		<StoreContext.Provider value={rootStore}>
			{children}
		</StoreContext.Provider>
	);
};
