import { flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

export const SurveyStats = types.model({}).actions((self) => {
	return {
		loadById: flow(function* loadById(patientId, surveyId, snackbar) {
			try {
				const response = yield fetchApi(
					`${ROOT_URL}/api/Survey/getDateForPatientSurveyGraph?patientId=${patientId}&surveyId=${surveyId}`,
					getRequestOptions("GET", null)
				);
				const data = yield response.json();
				if (response.status === RESPONSE_STATUS.SUCCESS) {
					return { status: 200, data: data };
				} else {
					snackbar.showMessage(data?.error, null, null, {
						severity: "error",
					});
					return { status: 400 };
				}
			} catch (error) {
				snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					severity: "error",
				});
				return { status: 500 };
			}
		}),
		loadByIdForPatient: flow(function* loadByIdForPatient(surveyId, snackbar) {
			try {
				const response = yield fetchApi(
					`${ROOT_URL}/api/Survey/getDateForPatientSurveyGraphByPatient?surveyId=${surveyId}`,
					getRequestOptions("GET", null)
				);
				const data = yield response.json();
				if (response.status === RESPONSE_STATUS.SUCCESS) {
					return { status: 200, data: data };
				} else {
					snackbar.showMessage(data?.error, null, null, {
						severity: "error",
					});
					return { status: 400 };
				}
			} catch (error) {
				snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					severity: "error",
				});
				return { status: 500 };
			}
		}),
		loadPatientSurveyGraphData: flow(function* loadPatientSurveyGraphData(recievedData, snackbar) {
			try {
				const response = yield fetchApi(
					`${ROOT_URL}/api/Survey/getPatientSurveyGraphForDetails
					?${recievedData?.templateSurveyId ? `templateSurveyId=${recievedData?.templateSurveyId}` : ""}
					&courseId=${recievedData?.courseId ? recievedData?.courseId : ""}
					&courseSNo=${recievedData?.courseSNo ? recievedData?.courseSNo : ""}
					&trackId=${recievedData?.trackId ? recievedData?.trackId : ""}
					&trackSNo=${recievedData?.trackSNo ? recievedData?.trackSNo : ""}
					&fromDate=${recievedData?.fromDate ? recievedData?.fromDate : ""}
					&toDate=${recievedData?.toDate ? recievedData?.toDate : ""}
					`,
					getRequestOptions("GET", null)
				);
				const data = yield response.json();
				if (response.status === RESPONSE_STATUS.SUCCESS) {
					return { status: 200, data: data };
				} else {
					snackbar.showMessage(data?.error, null, null, {
						severity: "error",
					});
					return { status: 400 };
				}
			} catch (error) {
				snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					severity: "error",
				});
				return { status: 500 };
			}
		}),
		loadByIdForPresenter: flow(function* loadByIdForPresenter(surveyId, courseId, courseSNo, snackbar) {
			try {
				const response = yield fetchApi(
					`${ROOT_URL}/api/Survey/GetSurveyGraphDataForPresenter?surveyId=${surveyId}&courseId=${courseId}&courseSNo=${courseSNo}`,
					getRequestOptions("GET", null)
				);
				const data = yield response.json();
				if (response.status === RESPONSE_STATUS.SUCCESS) {
					return { status: 200, data: data };
				} else {
					snackbar.showMessage(data?.error, null, null, {
						severity: "error",
					});
					return { status: 400 };
				}
			} catch (error) {
				snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					severity: "error",
				});
				return { status: 500 };
			}
		}),
		loadActivityListForSurveyReport: flow(
			function* loadActivityListForSurveyReport(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getActivityListForSurveyReport`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						return { status: 200, data: data };
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}
		),
		loadTrackListForSurveyReport: flow(
			function* loadTrackListForSurveyReport(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Survey/getTrackListForSurveyReport`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						return { status: 200, data: data };
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}
		),
		loadSurveyListForSurveyReport: flow(function* loadSurveyListForSurveyReport(recievedData, snackbar) {
			try {
				const response = yield fetchApi(
					`${ROOT_URL}/api/Survey/getSurveyListForSurveyReport
					?courseId=${recievedData?.courseId ? recievedData?.courseId : ""}
					&courseSNo=${recievedData?.courseSNo ? recievedData?.courseSNo : ""}
					&trackId=${recievedData?.trackId ? recievedData?.trackId : ""}
					&trackSNo=${recievedData?.trackSNo ? recievedData?.trackSNo : ""}
					`,
					getRequestOptions("GET", null)
				);
				const data = yield response.json();
				if (response.status === RESPONSE_STATUS.SUCCESS) {
					return { status: 200, data: data };
				} else {
					snackbar.showMessage(data?.error, null, null, {
						severity: "error",
					});
					return { status: 400 };
				}
			} catch (error) {
				snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					severity: "error",
				});
				return { status: 500 };
			}
		}),
	};
});
