import {
	destroy,
	flow,
	getSnapshot,
	types,
	applySnapshot,
} from "mobx-state-tree";
import { API_ERROR_MESSAGE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const LicenseItem = types.model({
	clinicianLicensesInfoId: types.maybeNull(types.number),
	id: types.maybeNull(types.number),
	contentCreatorId: types.maybeNull(types.number),
	clinicianId: types.maybeNull(types.number),
	professionalClassificationId: types.maybeNull(types.number),
	professionalClassification: types.maybeNull(types.string),
	otherProfessionalClassification: types.maybeNull(types.string),
	firstProfessionalClassificationId: types.maybeNull(types.number),
	firstProfessionalClassification: types.maybeNull(types.string),
	firstOtherProfessionalClassification: types.maybeNull(types.string),
	secondProfessionalClassificationId: types.maybeNull(types.number),
	secondProfessionalClassification: types.maybeNull(types.string),
	secondOtherProfessionalClassification: types.maybeNull(types.string),
	licenseNumber: types.maybeNull(types.string),
	dateOfLicense: types.maybeNull(types.string),
});

const SecondLicenseItem = types.model({
	id: types.number,
	secondClassification: types.maybeNull(types.string),
});

const FirstLicenseItem = types.model({
	id: types.number,
	firstClassification: types.maybeNull(types.string),
	secondProfessionalClassification: types.array(SecondLicenseItem),
});

const AllLicensesItem = types.model({
	id: types.number,
	classification: types.maybeNull(types.string),
	licenseNoRequired: types.maybeNull(types.boolean),
	firstProfessionalClassification: types.array(FirstLicenseItem),
});

export const License = types
	.model({
		items: types.array(LicenseItem),
		all: types.array(AllLicensesItem),
		currentId: types.maybeNull(types.number),
		formData: types.optional(
			types.model({
				professionalClassificationId: types.maybeNull(types.number),
				firstProfessionalClassificationId: types.maybeNull(
					types.number
				),
				firstProfessionalClassifications: types.array(FirstLicenseItem),
				secondProfessionalClassifications:
					types.array(SecondLicenseItem),
				secondProfessionalClassificationId: types.maybeNull(
					types.number
				),
			}),
			{
				professionalClassificationId: 0,
				firstProfessionalClassificationId: 0,
				secondProfessionalClassificationId: 0,
			}
		),
		disabledItems: types.optional(
			types.model({
				otherProfessionalClassification: types.optional(
					types.boolean,
					true
				),
				firstProfessionalClassification: types.optional(
					types.boolean,
					true
				),
				otherFirstProfessionalClassification: types.optional(
					types.boolean,
					true
				),
				secondProfessionalClassification: types.optional(
					types.boolean,
					true
				),
				otherSecondProfessionalClassification: types.optional(
					types.boolean,
					true
				),
			}),
			{
				otherProfessionalClassification: true,
				firstProfessionalClassification: true,
				otherFirstProfessionalClassification: true,
				secondProfessionalClassification: true,
				otherSecondProfessionalClassification: true,
			}
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					return self.items.find(
						(item) =>
							item.clinicianLicensesInfoId === self.currentId ||
							item.id === self.currentId
					);
				} else {
					return null;
				}
			},
		};
	})
	.actions((self) => {
		return {
			updateProfessionalClassification(type, id) {
				switch (type) {
					case 0:
						const firstProfessionalClassifications = self.all.find(
							(item) => item.id === id
						)
							? getSnapshot(
									self.all.find((item) => item.id === id)
										.firstProfessionalClassification
							  )
							: [];
						self.formData.professionalClassificationId = id;
						self.formData.firstProfessionalClassificationId = null;
						self.formData.secondProfessionalClassificationId = null;
						self.formData.firstProfessionalClassifications =
							firstProfessionalClassifications;
						break;
					case 1:
						const secondProfessionalClassifications = self.all
							.find(
								(item) =>
									item.id ===
									self.formData.professionalClassificationId
							)
							.firstProfessionalClassification.find(
								(item) => item.id === id
							)
							? getSnapshot(
									self.all
										.find(
											(item) =>
												item.id ===
												self.formData
													.professionalClassificationId
										)
										.firstProfessionalClassification.find(
											(item) => item.id === id
										).secondProfessionalClassification
							  )
							: [];
						self.formData.firstProfessionalClassificationId = id;
						self.formData.secondProfessionalClassificationId = null;
						self.formData.secondProfessionalClassifications =
							secondProfessionalClassifications;
						break;
					case 2:
						self.formData.secondProfessionalClassificationId = id;
						break;
					default:
						break;
				}
			},
			updateDisabledItems(disabledItems) {
				self.disabledItems = disabledItems;
			},
			updateDisabledItemsOnEditAdd() {
				let tempDisabledItems = {
					otherProfessionalClassification: true,
					firstProfessionalClassification: true,
					otherFirstProfessionalClassification: true,
					secondProfessionalClassification: true,
					otherSecondProfessionalClassification: true,
				};

				let currentLicense = self.all.find(
					(item) =>
						item.id === self.current?.professionalClassificationId
				);
				if (currentLicense?.classification === "Other") {
					tempDisabledItems = {
						...tempDisabledItems,
						otherProfessionalClassification: false,
					};
				} else {
					tempDisabledItems = {
						...tempDisabledItems,
						otherProfessionalClassification: true,
					};
				}
				if (
					currentLicense?.firstProfessionalClassification?.length > 0
				) {
					tempDisabledItems = {
						...tempDisabledItems,
						firstProfessionalClassification: false,
					};
				} else {
					tempDisabledItems = {
						...tempDisabledItems,
						firstProfessionalClassification: true,
						secondProfessionalClassification: true,
					};
				}

				if (
					self.all.find(
						(item) =>
							item.id ===
							self.current?.professionalClassificationId
					)?.firstProfessionalClassification?.length > 0
				) {
					currentLicense = self.all
						.find(
							(item) =>
								item.id ===
								self.current?.professionalClassificationId
						)
						.firstProfessionalClassification.find(
							(classification) =>
								classification.id ===
								self.current?.firstProfessionalClassificationId
						);

					if (currentLicense?.firstClassification === "Other") {
						tempDisabledItems = {
							...tempDisabledItems,
							otherFirstProfessionalClassification: false,
						};
					} else {
						tempDisabledItems = {
							...tempDisabledItems,
							otherFirstProfessionalClassification: true,
						};
					}
					if (
						currentLicense?.secondProfessionalClassification
							?.length > 0
					) {
						tempDisabledItems = {
							...tempDisabledItems,
							secondProfessionalClassification: false,
						};
					} else {
						tempDisabledItems = {
							...tempDisabledItems,
							secondProfessionalClassification: true,
						};
					}

					currentLicense = self.all
						.find(
							(item) =>
								item.id ===
								self.current?.professionalClassificationId
						)
						.firstProfessionalClassification.find(
							(classification) =>
								classification.id ===
								self.current?.firstProfessionalClassificationId
						)
						?.secondProfessionalClassification.find(
							(classification) =>
								classification.id ===
								self.current?.secondProfessionalClassificationId
						);
					if (currentLicense?.secondClassification === "Other") {
						tempDisabledItems = {
							...tempDisabledItems,
							otherSecondProfessionalClassification: false,
						};
					} else {
						tempDisabledItems = {
							...tempDisabledItems,
							otherSecondProfessionalClassification: true,
						};
					}
				}

				self.disabledItems = tempDisabledItems;
			},
			load: flow(function* load(id, snackbar, type) {
				try {
					let response = null;
					if (type === "CONTENT_CREATOR") {
						response = yield fetchApi(
							`${ROOT_URL}/api/ContentCreator/getLicenseByContentCreatorById?Id=${id}`,
							getRequestOptions("GET", null)
						);
					} else {
						response = yield fetchApi(
							`${ROOT_URL}/api/Clinician/getLicenseByClinicianId?id=${id}`,
							getRequestOptions("GET", null)
						);
					}

					if (response) {
						const json = yield response.json();

						if (response.status === 200) {
							self.items = json;
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			loadAll: flow(function* loadAll(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Clinician/getAllLicenses`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();

					if (response.status === 200) {
						self.all = json;
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			remove: flow(function* remove(id, snackbar, type) {
				try {
					let response = null;
					if (type === "CONTENT_CREATOR") {
						response = yield fetchApi(
							`${ROOT_URL}/api/ContentCreator/deleteContentCreatorLicenseById?Id=${id}`,
							getRequestOptions("POST", null)
						);
					} else {
						response = yield fetchApi(
							`${ROOT_URL}/api/Clinician/deleteClinicianLicenseById?id=${id}`,
							getRequestOptions("POST", null)
						);
					}
					if (response) {
						const json = yield response.json();
						if (response.status === 200) {
							self.deleteLicense(id);
							snackbar.showMessage(
								"Record Successfully Deleted!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			deleteLicense(id) {
				self.currentId = null;
				destroy(
					self.items.find(
						(item) =>
							item.clinicianLicensesInfoId === id ||
							item.id === id
					)
				);
			},
			updateCurrentItem(id, type) {
				self.currentId = id;
				if (id) {
					let currentItem;

					if (type === "CONTENT_CREATOR") {
						currentItem = self.items.find((item) => item.id === id);
					} else {
						currentItem = self.items.find(
							(item) =>
								item.clinicianLicensesInfoId === id ||
								item.id === id
						);
					}

					self.formData = {
						...currentItem,
					};

					self.formData.firstProfessionalClassifications =
						self.all.find(
							(item) =>
								item.id ===
								currentItem.professionalClassificationId
						)
							? getSnapshot(
									self.all.find(
										(item) =>
											item.id ===
											currentItem.professionalClassificationId
									).firstProfessionalClassification
							  )
							: [];
					if (
						currentItem.firstProfessionalClassificationId &&
						currentItem.firstProfessionalClassificationId !== 0
					) {
						self.formData.secondProfessionalClassifications =
							self.formData.firstProfessionalClassifications?.find(
								(item) =>
									item.id ===
									currentItem.firstProfessionalClassificationId
							)
								? getSnapshot(
										self.formData.firstProfessionalClassifications.find(
											(item) =>
												item.id ===
												currentItem.firstProfessionalClassificationId
										).secondProfessionalClassification
								  )
								: [];
					}
				} else {
					self.formData = {};
				}
			},
			updateLicenseListItem(data) {
				if (
					self.items.find(
						(item) =>
							item?.clinicianLicensesInfoId ===
								data?.clinicianLicensesInfoId ||
							item?.id === data?.id
					)
				) {
					applySnapshot(
						self.items.find(
							(item) =>
								item?.clinicianLicensesInfoId ===
									data?.clinicianLicensesInfoId ||
								item?.id === data?.id
						),
						data
					);
				}
			},
			save: flow(function* save(data, snackbar, clinicianId, type) {
				if (self.current) {
					try {
						let response = null;
						if (type === "CONTENT_CREATOR") {
							response = yield fetchApi(
								`${ROOT_URL}/api/ContentCreator/updateContentCreatorLicense`,
								getRequestOptions("POST", {
									...self.current,
									...data,
								})
							);
						} else {
							response = yield fetchApi(
								`${ROOT_URL}/api/Clinician/updateClinicianLicense`,
								getRequestOptions("POST", {
									...self.current,
									...data,
								})
							);
						}
						if (response) {
							const json = yield response.json();

							if (response.status === 200) {
								if (json.status === 400) {
									snackbar.showMessage(
										json.error,
										null,
										null,
										{
											severity: "error",
										}
									);
								} else {
									self.updateLicenseListItem(json);
									snackbar.showMessage(
										"Record Successfully Updated!!",
										null,
										null,
										{ severity: "success" }
									);
								}
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						let response = null;
						if (type === "CONTENT_CREATOR") {
							response = yield fetchApi(
								`${ROOT_URL}/api/ContentCreator/addContentCreatorLicense`,
								getRequestOptions("POST", {
									...data,
									contentCreatorId: clinicianId,
								})
							);
						} else {
							response = yield fetchApi(
								`${ROOT_URL}/api/Clinician/addClinicianLicense`,
								getRequestOptions("POST", {
									...data,
									clinicianId: clinicianId,
								})
							);
						}
						if (response) {
							const json = yield response.json();
							if (response.status === 200) {
								self.addLicenseListItem(json);
								snackbar.showMessage(
									"Record Successfully Created!!",
									null,
									null,
									{ severity: "success" }
								);
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			addLicenseListItem(data) {
				self.items.push(data);
			},
		};
	});
