import { React, Fragment } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useStore } from "../../hooks";
import { observer } from "mobx-react-lite";


const LogoHeader = () => {
	const { tenant } = useStore();
	return (
		<Fragment>
			<title>{tenant.details?.tenantName}</title>
			<div>
				<LazyLoadImage
					src={tenant.details?.headerLogoPath}
					height='50'
					width='auto'
					effect='blur'
					alt={tenant.details?.tenantName || ""}
				/>
			</div>
		</Fragment>
	);
};
export default observer(LogoHeader);
