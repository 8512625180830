import { flow, getEnv, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const ZoomSettingItem = types.model({
	apiKey: types.maybeNull(types.string),
	secretKey: types.maybeNull(types.string),
	accountId: types.maybeNull(types.string),
	clientId: types.maybeNull(types.string),
	clientSecret: types.maybeNull(types.string)
})

const SquareSettingItem = types.model({
	accessToken: types.maybeNull(types.string),
	locationID: types.maybeNull(types.string),
	signatureKey: types.maybeNull(types.string),
	applicationId: types.maybeNull(types.string),
})

const VimeoSettingItem = types.model({
	accessToken: types.maybeNull(types.string)
})

export const Settings = types
	.model({
		zoom: types.maybeNull(ZoomSettingItem),
		square: types.maybeNull(SquareSettingItem),
		vimeo: types.maybeNull(VimeoSettingItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"done"
		),
	})
	.actions((self) => {
		return {
			load: flow(function* load() {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/utility/getzoomsetting`,
						getRequestOptions("GET", null)
					);
					if (response.status != 404) {
						const json = yield response.json();
						if (response.status === 200) {
							self.zoom = json;
						} else {
							getEnv(self).notifier.enqueue(json.error, "error");
						}
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			save: flow(function* save(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/utility/addzoomsetting`,
						getRequestOptions("POST", data)
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 200) {
							getEnv(self).notifier.enqueue(json.error, "error");
						} else {
							getEnv(self).notifier.enqueue(
								"Zoom Keys Updated Successfully!!",
								"success"
							);
						}
					} else {
						getEnv(self).notifier.enqueue(json.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			loadSquare: flow(function* loadSquare() {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/utility/getsquaresetting`,
						getRequestOptions("GET", null)
					);
					if (response.status != 404) {
						const json = yield response.json();
						if (response.status === 200) {
							self.square = json;
						} else {
							getEnv(self).notifier.enqueue(json.error, "error");
						}
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			saveSquare: flow(function* saveSquare(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/utility/addsquaresetting`,
						getRequestOptions("POST", data)
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 200) {
							getEnv(self).notifier.enqueue(json.error, "error");
						} else {
							getEnv(self).notifier.enqueue(
								"Square Keys Updated Successfully!!",
								"success"
							);
						}
					} else {
						getEnv(self).notifier.enqueue(json.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			loadVimeo: flow(function* loadSquare() {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/utility/getvimeosetting`,
						getRequestOptions("GET", null)
					);
					if (response.status != 404) {
						const json = yield response.json();
						if (response.status === 200) {
							self.vimeo = json;
						} else {
							getEnv(self).notifier.enqueue(json.error, "error");
						}
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			saveVimeo: flow(function* saveVimeo(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/utility/addvimeosetting`,
						getRequestOptions("POST", data)
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 200) {
							getEnv(self).notifier.enqueue(json.error, "error");
						} else {
							getEnv(self).notifier.enqueue(
								"Vimeo Keys Updated Successfully!!",
								"success"
							);
						}
					} else {
						getEnv(self).notifier.enqueue(json.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
		};
	});
