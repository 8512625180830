import { applySnapshot, destroy, flow, types } from "mobx-state-tree";
import { API_ERROR_MESSAGE, CONTENT_TYPE, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import { fetchApi } from "../../utils/CustomFetch";

const AdminContactItem = types.model({
	sNo: types.identifierNumber,
	id: types.maybeNull(types.string),
	prefix: types.maybeNull(types.string),
	firstName: types.maybeNull(types.string),
	middleName: types.maybeNull(types.string),
	lastName: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	isPrimary: types.maybeNull(types.boolean),
	researchInstitutionSNo: types.maybeNull(types.number),
	researchInstitutionId: types.maybeNull(types.string),
});

export const AdminContact = types
	.model({
		items: types.array(AdminContactItem),
		current: types.maybeNull(
			types.reference(types.late(() => AdminContactItem))
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
	})
	.actions((self) => {
		return {
			loadAdminContactByInstitutionId: flow(
				function* loadAdminContactByInstitutionId(
					researchInstitutionSNo,
					researchInstitutionId
				) {
					self.current = null;
					self.currentContact = null;
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/getAdminContactByInstitutionId?SNo=${researchInstitutionSNo}&Id=${researchInstitutionId}`,
							getRequestOptions("GET", null)
						);
						self.items = yield response.json();
					} catch (error) {
						console.error(
							"Failed to fetchApi Admin Contact",
							error
						);
					}
				}
			),
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(
				id,
				researchInstitutionSNo,
				researchInstitutionId
			) {
				if (id) {
					self.current = self.items.find((item) => item.sNo === id);
				} else {
					if (self.items.find((item) => item.sNo === -1)) {
						self.current = self.items.find(
							(item) => item.sNo === -1
						);
					} else {
						self.items.push({
							sNo: -1,
							researchInstitutionSNo: researchInstitutionSNo,
							researchInstitutionId: researchInstitutionId,
						});
						self.current = self.items.find(
							(item) => item.sNo === -1
						);
					}
				}
			},
			updateAdminContactListItem(data) {
				applySnapshot(
					self.items.find((item) => item.sNo === data.sNo),
					data
				);
			},
			addAdminContactListItem(data) {
				self.items.push(data);
			},
			save: flow(function* save(
				data,
				snackbar,
				closeDialog,
				reloadResearchInstitution
			) {
				if (self.current?.sNo !== -1) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/updateInstitutionAdminContact`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();

						if (response.status === 200) {
							if (json.status !== 400) {
								self.updateAdminContactListItem(json);
								self.loadAdminContactByInstitutionId(
									self.current.researchInstitutionSNo,
									self.current.researchInstitutionId
								);
								reloadResearchInstitution();
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);

								self.updateContentType(CONTENT_TYPE.LIST);
								closeDialog();
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/ResearchInstitution/createInstitutionAdminContact`,
							getRequestOptions("POST", {
								...data,
								researchInstitutionSNo:
									self.current.researchInstitutionSNo,
								researchInstitutionId:
									self.current.researchInstitutionId,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 400) {
								self.addAdminContactListItem(json);
								self.loadAdminContactByInstitutionId(
									self.current.researchInstitutionSNo,
									self.current.researchInstitutionId
								);
								reloadResearchInstitution();
								snackbar.showMessage(
									"Record Successfully Created!!",
									null,
									null,
									{ severity: "success" }
								);
								self.updateContentType(CONTENT_TYPE.LIST);
								closeDialog();
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			remove: flow(function* remove(id, snackbar) {
				const item = self.items.find((item) => item.sNo === id);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/ResearchInstitution/deleteInstitutionAdminContactById`,
						getRequestOptions("POST", {
							id: item.id,
							sNo: item.sNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 400) {
							self.deleteAdminContact(json.sNo);
							snackbar.showMessage(
								"Record Successfully Deleted!!",
								null,
								null,
								{
									severity: "success",
								}
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			deleteAdminContact(id) {
				self.current = null;
				destroy(self.items.find((item) => item.sNo === id));
			},
		};
	});
