import { applySnapshot, destroy, flow, getEnv, types } from "mobx-state-tree";
import {
	API_ERROR_MESSAGE,
	CONTENT_TYPE,
	RESPONSE_STATUS,
	ROOT_URL,
} from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import {
	courseRecursiveParentSearch,
	courseRecursiveSearch,
	getCompleteDayStructure,
	getCompleteUploadStructure,
	jsonToFormData,
} from "../../helpers";
import { CourseFeedbackTypes, CourseGlobalUploadType } from "../../enums";
import FileSaver from "file-saver";
import { fetchApi } from "../../utils/CustomFetch";

const SessionScheduleItem = types.model({
	durationHour: types.maybeNull(types.number),
	durationMinute: types.maybeNull(types.number),
	scheduleType: types.maybeNull(types.number),
	selectedRecurrence: types.maybeNull(types.boolean),
	every: types.maybeNull(types.number),
	weekDays: types.maybeNull(types.string),
	dayOfMonth: types.maybeNull(types.number),
	monthWeek: types.maybeNull(types.number),
	weekDay: types.maybeNull(types.number),
	month: types.maybeNull(types.number),
	endAfter: types.maybeNull(types.number),
});

const SurveyItem = types.model({
	surveyId: types.number,
});

const SessionLinkItem = types.model({
	sessionLinkAddress: types.maybeNull(types.string),
	sessionLinkTitle: types.maybeNull(types.string),
	sessionLinkId: types.maybeNull(types.number),
});

const SessionItem = types.model({
	sessionId: types.number,
	sessionSNo: types.identifier,
	sessionSchedule: types.maybeNull(SessionScheduleItem),
	name: types.maybeNull(types.string),
	activity: types.array(types.late(() => ActivityItem)),
	sessionSurvey: types.array(SurveyItem),
	sessionDeliveryMethod: types.array(types.late(() => DeliveryMethodItem)),
	sessionUpload: types.array(types.late(() => UploadItem)),
	sessionLink: types.array(SessionLinkItem),
});

const UploadItem = types.model({
	uploadId: types.maybeNull(types.number),
	uploadType: types.maybeNull(types.string),
	uploadURL: types.maybeNull(types.string),
	uploadContentType: types.maybeNull(types.string),
	uploadFile: types.maybeNull(types.string),
	uploadSection: types.maybeNull(types.number),
});

const ActivityItem = types.model({
	activityId: types.number,
	activitySNo: types.identifier,
	session: types.array(SessionItem),
	title1: types.maybeNull(types.string),
	title2: types.maybeNull(types.string),
	title3: types.maybeNull(types.string),
	noOfSession: types.maybeNull(types.number),
	summary: types.maybeNull(types.string),
	activityDeliveryMethod: types.array(types.late(() => DeliveryMethodItem)),
	activitySurvey: types.array(SurveyItem),
});

const CurriculumItem = types.model({
	curriculumId: types.number,
});

const DeliveryMethodItem = types.model({
	deliveryMethodId: types.number,
});

const ActivityIntendedAudience = types.model({
	intendedAudienceId: types.maybeNull(types.number),
});

const DaysItem = types.model({
	courseActivity: types.array(ActivityItem),
	courseDaysId: types.maybeNull(types.number),
	courseDaysSNo: types.maybeNull(types.string),
	courseSession: types.array(SessionItem),
	name: types.maybeNull(types.string),
});

const CourseLinkItem = types.model({
	courseLinkAddress: types.maybeNull(types.string),
	courseLinkTitle: types.maybeNull(types.string),
	courseLinkId: types.maybeNull(types.number),
	courseLinkSection: types.maybeNull(types.number),
});

const ActivityCompetition = types.model({
	activityURL: types.maybeNull(types.string),
	activityCreatorTeacher: types.maybeNull(types.string),
	activitySponsoringOrganization: types.maybeNull(types.string),
	summary: types.maybeNull(types.string),
});

const AdditionalQuestionItem = types.model({
	additionalQuestionId: types.maybeNull(types.number),
	offLevel: types.optional(types.maybeNull(types.boolean), null),
	offLevelDescription: types.maybeNull(types.string),
	hippaDescription: types.maybeNull(types.string),
	copyrightDescription: types.maybeNull(types.string),
	hippaReq: types.optional(types.maybeNull(types.boolean), null),
	hippaInfo: types.optional(types.maybeNull(types.boolean), null),
	hippaAuth: types.optional(types.maybeNull(types.boolean), null),
	copyright: types.optional(types.maybeNull(types.boolean), null),
	auth: types.optional(types.maybeNull(types.boolean), null),
	approval: types.maybeNull(types.string),
	submittingCE: types.optional(types.maybeNull(types.boolean), null),
	financialDisclosures: types.optional(types.maybeNull(types.boolean), null),
	nonFinancialDisclosures: types.optional(
		types.maybeNull(types.boolean),
		null
	),
	nonFinancialDisclosuresDescription: types.maybeNull(types.string),
	agreement: types.optional(types.maybeNull(types.boolean), null),
	signatureDate: types.maybeNull(types.string),
	signatureURL: types.maybeNull(types.string),
	signatureConfirmText: types.maybeNull(types.string),
	additionalQuestionUpload: types.maybeNull(
		types.array(types.late(() => UploadItem))
	),
	coursePostSureys: types.maybeNull(
		types.array(types.late(() => CoursePreparatorySurveyItem))
	),
	additionalQuestionFinancialRelationship: types.maybeNull(
		types.array(
			types.model({
				financialRelationshipId: types.number,
			})
		)
	),
});

const ProposalDetailsItem = types.model({
	activityComptetions: types.array(ActivityCompetition),
	activityIntendedAudience: types.array(ActivityIntendedAudience),
	activityNeedsAssessment: types.maybeNull(types.string),
	activityRefrences: types.maybeNull(types.string),
	proposalDetailsId: types.maybeNull(types.number),
});

const CoursePreparatorySurveyItem = types.model({
	surveyId: types.maybeNull(types.number),
	isMandatory: types.maybeNull(types.boolean),
});

const coursePostSurveysItem = types.model({
	surveyId: types.maybeNull(types.number)
})
const PresenterItem = types.model({
	presenterPrefix: types.maybeNull(types.string),
	presenterFirstName: types.maybeNull(types.string),
	presenterMiddleName: types.maybeNull(types.string),
	presenterLastName: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	phone: types.maybeNull(types.string),
	dob: types.maybeNull(types.string),
	presenterId: types.maybeNull(types.number),
	lmsId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	inviteStatus: types.maybeNull(types.string),
	displaySurveyToPresenter: types.maybeNull(types.boolean),
});

const ContinuingEducationItem = types.model({
	continuingEducationId: types.number,
	continuingEducationSNo: types.maybeNull(types.string),
})

const CourseItem = types.model({
	additionalQuestion: types.maybeNull(AdditionalQuestionItem),
	proposalDetails: types.maybeNull(ProposalDetailsItem),
	contentCreatorId: types.maybeNull(types.number),
	courseCurriculum: types.array(CurriculumItem),
	courseName: types.string,
	courseTitle:types.maybeNull(types.string),
	courseDays: types.array(DaysItem),
	courseSurvey: types.array(SurveyItem),
	coursePreparatorySurveys: types.array(CoursePreparatorySurveyItem),
	coursePostSurveys: types.array(coursePostSurveysItem),
	courseStatus: types.maybeNull(types.number),
	deliveryMethod: types.array(DeliveryMethodItem),
	description: types.maybeNull(types.string),
	id: types.number,
	isMultiDay: types.optional(types.number, 0),
	isCECourse: types.maybeNull(types.boolean),
	isCoursePurchased: types.maybeNull(types.boolean),
	noOfDays: types.maybeNull(types.number),
	nonCEPrice: types.maybeNull(types.number),
	cePrice: types.maybeNull(types.number),
	tags: types.array(types.string),
	sNo: types.string,
	submittedDate: types.maybeNull(types.string),
	courseUpload: types.array(UploadItem),
	courseUploadFlag: types.maybeNull(types.boolean),
	courseLink: types.array(CourseLinkItem),
	isProposal: types.maybeNull(types.boolean),
	isWebCast: types.maybeNull(types.boolean),
	isAppleHealth: types.maybeNull(types.boolean),
	userInstructions: types.maybeNull(types.string),
	coursePresenters: types.maybeNull(types.array(PresenterItem)),
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	continuingEducation: types.maybeNull(ContinuingEducationItem),
});
const BatchesForTracksItem = types.model({
	courseId:types.maybeNull(types.number),
    courseSNo:types.maybeNull(types.string),
    batchId: types.maybeNull(types.number),
    batchSNo: types.maybeNull(types.string),
    courseTitle: types.maybeNull(types.string),
    courseName: types.maybeNull(types.string),
    batchName: types.maybeNull(types.string),
    isZoomEnabled: types.maybeNull(types.boolean),
    batchType: types.maybeNull(types.string),
});
export const Courses = types
	.model({
		items: types.array(CourseItem),
		batchCourses: types.array(CourseItem),
		alltags: types.array(types.string),
		presenters: types.array(PresenterItem),
		PublishedBatchesForTracks: types.array(BatchesForTracksItem),
		status: types.optional(
			types.enumeration("status", ["pending", "done", "error"]),
			"pending"
		),
		contentType: types.optional(types.number, CONTENT_TYPE.LIST),
		currentId: types.maybeNull(types.number),
		currentDayId: types.maybeNull(types.number),
		currentTreeData: types.optional(
			types.model({
				selected: types.maybeNull(types.string),
				expanded: types.array(types.string),
				node: types.maybeNull(types.string),
				parent: types.maybeNull(types.string),
			}),
			{ selected: null, expanded: [], node: null, parent: null }
		),
	})
	.views((self) => {
		return {
			get current() {
				if (self.currentId) {
					const data = self.items.find(
						(item) => item.id === self.currentId
					);
					return data;
				} else {
					return null;
				}
			},
			getById(id) {
				if (id) {
					return self.items.find((item) => item.id === id);
				} else {
					return null;
				}
			},
			get currentDay() {
				if (self.currentId && self.currentDayId) {
					return self.items
						.find((item) => item.id === self.currentId)
						.courseDays.find(
							(day) => day.courseDaysId === self.currentDayId
						);
				} else {
					return null;
				}
			},
			get currentParent() {
				if (self.currentTreeData.parent) {
					const parent_split = self.currentTreeData.parent.split("#");
					const course = self.items.find(
						(item) => item.id === self.currentId
					);
					const parent_type = parent_split[0];
					const parent_id = parent_split[1];
					if (parent_id === "null") {
						return null;
					} else {
						return courseRecursiveSearch(
							parent_id,
							parent_type,
							course.courseDays
						);
					}
				} else {
					return null;
				}
			},
			get currentNode() {
				if (self.currentTreeData.node) {
					const node_split = self.currentTreeData.node.split("#");
					const course = self.items.find(
						(item) => item.id === self.currentId
					);
					const node_type = node_split[0];
					const node_id = node_split[1];
					return courseRecursiveSearch(
						node_id,
						node_type,
						course.courseDays
					);
				} else {
					return null;
				}
			},
			get nearestEmptyDay() {
				const days = self.items.find(
					(item) => item.id === self.currentId
				)?.courseDays;

				return days?.find(
					(day) =>
						day.courseActivity.length === 0 &&
						day.courseSession.length === 0
				);
			},
			getNodeParent(node, type) {
				if (node) {
					const course = self.items.find(
						(item) => item.id === self.currentId
					);
					return courseRecursiveParentSearch(
						node,
						type,
						course.courseDays,
						null
					);
				} else {
					return null;
				}
			},
			treeData(parent) {
				const course = self.items.find(
					(item) => item.id === self.currentId
				);
				let children = [
					...getCompleteUploadStructure(
						course.courseUpload.filter(
							(upload) =>
								upload.uploadSection ===
								CourseGlobalUploadType.Preparatory
						),
						parent,
						{
							type: CourseFeedbackTypes.CoursePrepMaterial,
							sNo: course.sNo,
							id: course.id,
						}
					),
				];

				course.courseDays.forEach((day) => {
					children.push(getCompleteDayStructure(day, parent));
				});

				children = [
					...children,
					...getCompleteUploadStructure(
						course.courseUpload.filter(
							(upload) =>
								upload.uploadSection ===
								CourseGlobalUploadType.Additional
						),
						parent,
						{
							type: CourseFeedbackTypes.CourseAdditionalMaterial,
							sNo: course.sNo,
							id: course.id,
						}
					),
				];
				if (course.additionalQuestion) {
					children = [
						...children,
						...getCompleteUploadStructure(
							course.additionalQuestion?.additionalQuestionUpload,
							parent,
							{
								type: CourseFeedbackTypes.CourseMandatoryQuestion,
								sNo: null,
								id: course.additionalQuestion
									?.additionalQuestionId,
							}
						),
					];
				}

				return {
					label: course.courseName,
					value: {
						node: course,
						type: CourseFeedbackTypes.Course,
						sNo: course.sNo,
						id: course.id,
						for: "Activity",
						name: course.courseName,
					},
					children: children,
					checked:
						parent.type === CourseFeedbackTypes.Course &&
						parent.id === course.id,
				};
			},
			getNextDay(day) {
				if (day) {
					const course = self.items.find(
						(item) => item.id === self.currentId
					);
					const dayIndex = course.courseDays.findIndex(
						(item) => item.courseDaysId === day.courseDaysId
					);

					if (dayIndex >= course.courseDays.length) {
						return null;
					} else {
						return course.courseDays[dayIndex + 1];
					}
				} else {
					return null;
				}
			},
			getSessionActivityById(session, activityId) {
				return session?.activity?.find(
					(item) => item.activityId === activityId
				);
			},
		};
	})
	.actions((self) => {
		return {
			load: flow(function* load(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllCourses`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.items, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),

			loadPublishedBatchesForTracks: flow(function* loadPublishedBatchesForTracks(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/GetAllPublishedBatchesForTracks`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.PublishedBatchesForTracks, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadCoursesForBatch: flow(function* loadCoursesForBatch(snackbar) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/GetAllCoursesForBatch`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.batchCourses, json);
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),

			loadTags: flow(function* loadTags(snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getAllTags`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.alltags, json);
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			updateDisplaySurveyToPresenter: flow(function* updateDisplaySurveyToPresenter(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/UpdateSurveyShowToPresenter`,
						getRequestOptions("POST", data)
					);

					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json.error, "error");
						} else {
							applySnapshot(
								self.current.coursePresenters.find(
									(item) => item.presenterId === data.presenterId
								),
								json
							);
						}
					} else {
						getEnv(self).notifier.enqueue(json.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),


			getAllPresentersByActivity: flow(
				function* getAllPresentersByActivity(id, sNo) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/GetAllPresentersByActivity?courseId=${id}&courseSNo=${sNo}`,
							getRequestOptions("GET", null)
						);
						const json = yield response.json();

						if (response.status === 200) {
							self.presenters = json;
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
						}
					} catch (e) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				}
			),

			invitePresenter: flow(function* invitePresenter(payload) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/InvitePresenter`,
						getRequestOptions("POST", payload)
					);
					const json = yield response.json();

					if (response.status === 200) {
						if (json.status === 0) {
							self.presenters.push(json);
							getEnv(self).notifier.enqueue(
								"Presenter Invited!",
								"success"
							);
							self.loadByIdForPresenter(
								payload.courseId,
								payload.courseSNo
							);
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			deletePresenterByEmailandCourse: flow(function* deletePresenterByEmailandCourse(payload) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/DeletePresenterByEmailandCourse`,
						getRequestOptions("POST", payload)
					);
					const json = yield response.json();

					if (response.status === 200) {
						if (json.status === 0) {
							destroy(self.presenters.find((presenter) => presenter.email === payload.email));

							self.loadByIdForPresenter(
								payload.courseId,
								payload.courseSNo
							);
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			loadByIdForPresenter: flow(function* loadByIdForPresenter(id, sNo) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCourseById?courseId=${id}&courseSNo=${sNo}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (self.items.some((item) => item.id === id)) {
							applySnapshot(
								self.items.find((item) => item.id === id),
								data
							);
						} else {
							self.items.push(data);
						}
						self.currentId = id;
					} else {
						getEnv(self).notifier.enqueue(data?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),
			updateCE(isCE) {
				applySnapshot(self.current, {
					...self.current,
					isCECourse: isCE,
				});
			},

			loadById: flow(function* loadById(
				id,
				sNo,
				snackbar,
				callback,
				batch,
				market
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getCourseById?courseId=${id}&courseSNo=${sNo}`,
						getRequestOptions("GET", null)
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (self.items.some((item) => item.id === id)) {
							applySnapshot(
								self.items.find((item) => item.id === id),
								data
							);
						} else {
							self.items.push(data);
						}

						if (batch) {
							self.currentId = id;
							callback(data);
						} else {
							self.currentId = id;
							self.updateCurrentTreeExpanded([]);
							self.updateCurrentTreeParent(null);
							if (self.current?.courseDays.length > 0) {
								if (
									self.current?.courseDays[0]?.courseActivity
										.length > 0
								) {
									self.updateCurrentTreeSelected(
										`activity#${self.current?.courseDays[0]?.courseActivity[0].activityId}`
									);
									self.updateCurrentTreeNode(
										`activity#${self.current?.courseDays[0]?.courseActivity[0].activityId}`
									);
									self.updateCurrentTreeExpanded([
										...self.currentTreeData.expanded,
										`day#${self.current?.courseDays[0]?.courseDaysId}`,
									]);
								} else if (
									self.current?.courseDays[0]?.courseSession
										.length > 0
								) {
									self.updateCurrentTreeSelected(
										`session#${self.current?.courseDays[0]?.courseSession[0].sessionId}`
									);
									self.updateCurrentTreeNode(
										`session#${self.current?.courseDays[0]?.courseSession[0].sessionId}`
									);
									self.updateCurrentTreeExpanded([
										...self.currentTreeData.expanded,
										`day#${self.current?.courseDays[0]?.courseDaysId}`,
									]);
								} else {
									self.updateCurrentTreeSelected(null);
									self.updateCurrentTreeNode(null);
								}
							} else {
								self.updateCurrentTreeSelected(null);
								self.updateCurrentTreeNode(null);
							}

							if (market) {
								callback();
							} else {
								self.updateContentType(CONTENT_TYPE.EDIT);
							}

							if (
								data?.continuingEducation?.continuingEducationId
							) {
								callback();
							}
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),
			copyActivity: flow(function* copyActivity(id, sNo, snackbar, callback,batch,market) {
				try {
					const response = yield fetchApi(`${ROOT_URL}/api/Course/CopyCourseById`,getRequestOptions("POST", { courseId: id, courseSNo: sNo }));
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (self.items.some((item) => item.id === data?.id)) {
							applySnapshot(
								self.items.find((item) => item.id === data?.id),
								data
							);
						} else {
							self.items.push(data);
						}
						if (batch) {
							self.currentId = data?.id;
							callback(data);
						} else {
							self.currentId = data?.id;

							self.updateCurrentTreeExpanded([]);
							self.updateCurrentTreeParent(null);
							if (self.current?.courseDays?.length > 0) {
								if (
									self.current?.courseDays[0]?.courseActivity
										.length > 0
								) {
									self.updateCurrentTreeSelected(
										`activity#${self.current?.courseDays[0]?.courseActivity[0].activityId}`
									);
									self.updateCurrentTreeNode(
										`activity#${self.current?.courseDays[0]?.courseActivity[0].activityId}`
									);
									self.updateCurrentTreeExpanded([
										...self.currentTreeData.expanded,
										`day#${self.current?.courseDays[0]?.courseDaysId}`,
									]);
								} else if (
									self.current?.courseDays[0]?.courseSession
										.length > 0
								) {
									self.updateCurrentTreeSelected(
										`session#${self.current?.courseDays[0]?.courseSession[0].sessionId}`
									);
									self.updateCurrentTreeNode(
										`session#${self.current?.courseDays[0]?.courseSession[0].sessionId}`
									);
									self.updateCurrentTreeExpanded([
										...self.currentTreeData.expanded,
										`day#${self.current?.courseDays[0]?.courseDaysId}`,
									]);
								} else {
									self.updateCurrentTreeSelected(null);
									self.updateCurrentTreeNode(null);
								}
							} else {
								self.updateCurrentTreeSelected(null);
								self.updateCurrentTreeNode(null);
							}

							if (market) {
								callback();
							} else {
								self.updateContentType(CONTENT_TYPE.EDIT);
							}

							if (
								data?.continuingEducation?.continuingEducationId
							) {
								callback();
							}
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),		
			updateCourseStatus: flow(function* updateCourseStatus(
				id,
				sNo,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/updateCourseStatus`,
						getRequestOptions("POST", { id: id, sNo: sNo })
					);
					const data = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						(data.status === RESPONSE_STATUS.SUCCESS ||
							data.status === RESPONSE_STATUS.SUCCESS_ZERO)
					) {
						applySnapshot(
							self.items.find((item) => item.id === id),
							data
						);
						snackbar.showMessage(
							"Activity Ready For Review!!",
							null,
							null,
							{ severity: "success" }
						);
						self.updateContentType(CONTENT_TYPE.LIST);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			getFileVersionList: flow(function* getFileVersionList(
				fileURL,
				fileUploadType,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getFileVersionListWithUploadBy?fileURL=${fileURL}&uploadType=${fileUploadType}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						callback(json);
					} else {
						snackbar.showMessage(
							"Unable to download File",
							null,
							null,
							{
								severity: "error",
							}
						);
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),

			updateProposalDetails: flow(function* updateProposalDetails(
				data,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/updateProposalDetails`,
						getRequestOptions("POST", {
							...self.current?.proposalDetails,
							...data,
							courseId: self.current?.id,
							courseSNo: self.current?.sNo,
						})
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.current.proposalDetails, json);
						callback(json);
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),

			getFileVersionAsDownload: flow(function* getFileVersionAsDownload(
				fileURL,
				fileContentType,
				versionId,
				fileName,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getFileVersionAsDownload?fileURL=${fileURL}&fileContentType=${fileContentType}&versionId=${versionId}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						FileSaver.saveAs(blob, fileName);
						callback();
					} else {
						snackbar.showMessage(
							"Unable to download File",
							null,
							null,
							{
								severity: "error",
							}
						);
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),

			getFileAsDownload: flow(function* getFileAsDownload(
				fileURL,
				fileContentType,
				fileName,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getFileAsDownload?fileURL=${fileURL}&fileContentType=${fileContentType}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						FileSaver.saveAs(blob, fileName);
						callback();
					} else {
						snackbar.showMessage(
							"Unable to download File",
							null,
							null,
							{
								severity: "error",
							}
						);
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),

			getZipAsDownload: flow(function* getZipAsDownload(
				course,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getZipAsDownload?courseId=${course.id}&courseSNo=${course.sNo}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						FileSaver.saveAs(blob, course.courseName);
						callback();
					} else {
						snackbar.showMessage(
							"Unable to download File",
							null,
							null,
							{
								severity: "error",
							}
						);
						callback();
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					callback();
				}
			}),
			updateCourseUploadFlag: flow(function* updateCourseUploadFlag(
				courseUploadFlag,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/updateCourseUploadFlag`,
						getRequestOptions("POST", {
							courseId: self.current?.id,
							courseSNo: self.current?.sNo,
							courseUploadFlag: courseUploadFlag,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.current.courseUploadFlag = courseUploadFlag;
						if (!courseUploadFlag) {
							applySnapshot(self.current.courseUpload, [
								...self.current.courseUpload.filter(
									(upload) =>
										upload.uploadSection !==
										CourseGlobalUploadType.Additional
								),
							]);
							applySnapshot(self.current.courseLink, [
								...self.current.courseLink.filter(
									(link) =>
										link.courseLinkSection !==
										CourseGlobalUploadType.Additional
								),
							]);
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			changeMultiDayToSingle: flow(function* changeMultiDayToSingle(
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/changeMultiDayToSingle`,
						getRequestOptions("POST", {
							id: self.current?.id,
							sNo: self.current?.sNo,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						applySnapshot(self.current, data);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			addSessionLink: flow(function* addSessionLink(
				session,
				link,
				title,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addSessionLink`,
						getRequestOptions("POST", {
							sessionLinkAddress: link,
							sessionId: session.sessionId,
							sessionSNo: session.sessionSNo,
							sessionLinkTitle: title,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS &&
						(data.status === RESPONSE_STATUS.SUCCESS ||
							data.status === RESPONSE_STATUS.SUCCESS_ZERO)) {
						session.sessionLink.push(data);
						getEnv(self).notifier.enqueue(
							"Link Added!!",
							"success"
						);
						callback();
					} else {
						getEnv(self).notifier.enqueue(data?.error, "error");
						//snackbar.showMessage(data?.error, null, null, {
						//	severity: "error",
						//});
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					//snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					//	severity: "error",
					//});
				}
			}),
			addCourseLink: flow(function* addCourseLink(
				link,
				title,
				type,
				snackbar,
				callback
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addCourseLink`,
						getRequestOptions("POST", {
							courseLinkAddress: link,
							courseId: self.current?.id,
							courseSNo: self.current?.sNo,
							courseLinkTitle: title,
							courseLinkSection: type,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS &&
						(data.status === RESPONSE_STATUS.SUCCESS ||
							data.status === RESPONSE_STATUS.SUCCESS_ZERO)) {
						self.current.courseLink.push(data);
						getEnv(self).notifier.enqueue(
							"Link Added!!",
							"success"
						);
						callback();
					} else {
						getEnv(self).notifier.enqueue(data?.error, "error");
						//snackbar.showMessage(data?.error, null, null, {
						//	severity: "error",
						//});
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					//snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
					//	severity: "error",
					//});
				}
			}),
			approveCourseStatus: flow(function* approveCourseStatus(
				id,
				sNo,
				nonCEPrice,
				cePrice,
				tags,
				snackbar,
				isUpdateTags
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/approveCourseStatus`,
						getRequestOptions("POST", {
							id: id,
							sNo: sNo,
							nonCEPrice:
								nonCEPrice && nonCEPrice !== ""
									? nonCEPrice
									: 0,
							cePrice: cePrice && cePrice !== "" ? cePrice : 0,
							tags: tags,
						})
					);
					const data = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						(data.status === RESPONSE_STATUS.SUCCESS ||
							data.status === RESPONSE_STATUS.SUCCESS_ZERO)
					) {
						applySnapshot(
							self.items.find((item) => item.id === id),
							data
						);
						snackbar.showMessage(
							isUpdateTags
								? "Activity Tags Updated"
								: "Activity Approved!!",
							null,
							null,
							{ severity: "success" }
						);
						self.updateContentType(CONTENT_TYPE.LIST);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			addTagsToActivity: flow(function* addTagsToActivity(id, sNo, tags) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addTagsToActivity`,
						getRequestOptions("POST", {
							courseId: id,
							courseSNo: sNo,
							tags: tags,
						})
					);
					const data = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						(data.status === RESPONSE_STATUS.SUCCESS ||
							data.status === RESPONSE_STATUS.SUCCESS_ZERO)
					) {
						if (self.items.some((item) => item.id === id)) {
							applySnapshot(
								self.items.find((item) => item.id === id),
								data
							);
							self.updateContentType(CONTENT_TYPE.LIST);
						}
						getEnv(self).notifier.enqueue(
							"Tags Updated!!",
							"success"
						);
					} else {
						getEnv(self).notifier.enqueue(data?.error, "error");
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			rejectCourseStatus: flow(function* rejectCourseStatus(
				id,
				sNo,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/rejectCourseStatus`,
						getRequestOptions("POST", { id: id, sNo: sNo })
					);
					const data = yield response.json();
					if (
						response.status === RESPONSE_STATUS.SUCCESS &&
						(data.status === RESPONSE_STATUS.SUCCESS ||
							data.status === RESPONSE_STATUS.SUCCESS_ZERO)
					) {
						applySnapshot(
							self.items.find((item) => item.id === id),
							data
						);
						snackbar.showMessage(
							"Activity Rejected!!",
							null,
							null,
							{ severity: "success" }
						);
						self.updateContentType(CONTENT_TYPE.LIST);
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			approveCourseStatusBySA: flow(function* approveCourseStatusBySA(
				id,
				sNo,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/approveCourseStatusBySA`,
						getRequestOptions("POST", { id: id, sNo: sNo })
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 0) {
							if (self.items.some((item) => item.id === id)) {
								applySnapshot(
									self.items.find((item) => item.id === id),
									data
								);
							} else {
								self.items.push(data);
							}
							snackbar.showMessage(
								"Activity Published!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					console.log(error);
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			sendActivityForARFReview: flow(function* sendActivityForARFReview(
				id,
				sNo,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/course/sendActivityForARFReview`,
						getRequestOptions("POST", {
							courseId: id,
							courseSNo: sNo,
						})
					);
					const data = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (data.status === 200) {
							snackbar.showMessage(
								"Activity Sent for Review!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(data?.error, null, null, {
								severity: "error",
							});
						}
					} else {
						snackbar.showMessage(data?.error, null, null, {
							severity: "error",
						});
					}
				} catch (error) {
					console.log(error);
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			save: flow(function* save(data, snackbar, isProposal, callback) {
				if (self.currentId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCourse`,
							getRequestOptions("POST", {
								...self.current,
								...data,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(self.current, json);
								callback();
								// snackbar.showMessage(
								// 	"Record Successfully Updated!!",
								// 	null,
								// 	null,
								// 	{ severity: "success" }
								// );
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/createCourse`,
							getRequestOptions("POST", {
								...data,
								noOfDays:
									data?.noOfDays > 0 ? data?.noOfDays : 0,
								isProposal: isProposal,
							})
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							// snackbar.showMessage(
							// 	"Record Successfully Created!!",
							// 	null,
							// 	null,
							// 	{ severity: "success" }
							// );
							self.updateCurrentTreeSelected(null);
							self.updateCurrentTreeNode(null);
							self.updateCurrentTreeParent(null);
							self.items.push(json);
							self.currentId = json.id;
							self.updateContentType(CONTENT_TYPE.EDIT);
							callback();
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			addSessionUpload: flow(function* addSessionUpload(
				session,
				data,
				snackbar,
				removeLoader
			) {
				const fd = jsonToFormData({
					...data,
					courseId: self.current?.id,
					courseSNo: self.current?.sNo,
				});
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addSessionUpload`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
							removeLoader();
						} else {
							if (
								!session.sessionUpload?.some(
									(upload) =>
										upload.uploadId === json.uploadId
								)
							) {
								session.sessionUpload.push(json);
							}
							snackbar.showMessage(
								"File Successfully Uploaded!!",
								null,
								null,
								{ severity: "success" }
							);
							removeLoader();
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
						removeLoader();
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					removeLoader();
				}
			}),

			addAdditionalQuestionUpload: flow(
				function* addAdditionalQuestionUpload(
					data,
					snackbar,
					removeLoader
				) {
					let additionalQuestion = self.items.find(
						(item) => item.id === self.currentId
					).additionalQuestion;
					if (additionalQuestion?.additionalQuestionId) {
						const fd = jsonToFormData({
							...data,
							additionalQuestionId:
								additionalQuestion?.additionalQuestionId,
							courseId: self.current?.id,
							courseSNo: self.current?.sNo,
						});
						try {
							const response = yield fetchApi(
								`${ROOT_URL}/api/Course/addAdditionalQuestionUpload`,
								getRequestOptions("POST", fd, true)
							);
							const json = yield response.json();
							if (response.status === 200) {
								if (json.status !== 0) {
									snackbar.showMessage(
										json.error,
										null,
										null,
										{
											severity: "error",
										}
									);
									removeLoader();
								} else {
									if (
										!additionalQuestion.additionalQuestionUpload?.some(
											(upload) =>
												upload.uploadId ===
												json.uploadId
										)
									) {
										additionalQuestion.additionalQuestionUpload.push(
											json
										);
									}

									snackbar.showMessage(
										"File Successfully Uploaded!!",
										null,
										null,
										{ severity: "success" }
									);
									removeLoader();
								}
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
								removeLoader();
							}
						} catch (e) {
							snackbar.showMessage(
								API_ERROR_MESSAGE,
								null,
								null,
								{
									severity: "error",
								}
							);
							removeLoader();
						}
					}
				}
			),

			addCourseUpload: flow(function* addCourseUpload(
				data,
				snackbar,
				removeLoader
			) {
				if (self.current?.id) {
					const fd = jsonToFormData({
						...data,
						courseId: self.current?.id,
						courseSNo: self.current?.sNo,
					});
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addCourseUpload`,
							getRequestOptions("POST", fd, true)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
								removeLoader();
							} else {
								if (
									!self.current.courseUpload?.some(
										(upload) =>
											upload.uploadId === json.uploadId
									)
								) {
									self.current.courseUpload.push(json);
								}
								snackbar.showMessage(
									"File Successfully Uploaded!!",
									null,
									null,
									{ severity: "success" }
								);
								removeLoader();
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
							removeLoader();
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						removeLoader();
					}
				}
			}),

			saveAdditionalQuestion: flow(function* saveAdditionalQuestion(
				data,
				snackbar,
				close = true
			) {
				if (self.current?.additionalQuestion?.additionalQuestionId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateAdditionalQuestion`,
							getRequestOptions("POST", {
								...self.current?.additionalQuestion,
								...data,
								courseId: self.current?.id,
								courseSNo: self.current?.sNo,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.current?.additionalQuestion,
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
								if (close) {
									self.updateContentType(CONTENT_TYPE.LIST);
									self.updateCurrentItem(null);
								}

							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addAdditionalQuestion`,
							getRequestOptions("POST", {
								...data,
								courseId: self.current?.id,
								courseSNo: self.current?.sNo,
							})
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							self.updateContentType(CONTENT_TYPE.LIST);

							applySnapshot(
								self.current?.additionalQuestion,
								json
							);

							self.updateCurrentItem(null);

							snackbar.showMessage(
								"Record Successfully Created!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveDays: flow(function* saveDays(day, data, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/updateCourseDays`,
						getRequestOptions("POST", {
							...day,
							...data,
							courseId: self.current?.id,
							courseSNo: self.current?.sNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						} else {
							applySnapshot(day, json);
							// snackbar.showMessage(
							// 	"Record Successfully Updated!!",
							// 	null,
							// 	null,
							// 	{ severity: "success" }
							// );
						}
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			saveActivity: flow(function* saveActivity(
				activity,
				data,
				snackbar,
				parent,
				show,
				notification
			) {
				if (activity?.activityId) {
					try {
						const response = yield fetchApi(
							!parent
								? `${ROOT_URL}/api/Course/updateCourseActivity`
								: `${ROOT_URL}/api/Course/updateSessionSubActivity`,
							getRequestOptions(
								"POST",
								!parent
									? {
										...activity,
										...data,
										courseId: self.current.id,
										courseSNo: self.current.sNo,
										courseDaysId:
											self.currentDay?.courseDaysId,
										courseDaysSNo:
											self.currentDay?.courseDaysSNo,
									}
									: {
										...activity,
										...data,
										courseId: self.current.id,
										courseSNo: self.current.sNo,
										sessionId: parent?.sessionId,
										sessionSNo: parent?.sessionSNo,
									}
							)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 0) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(activity, json);
								if (notification) {
									snackbar.showMessage(
										"Record Successfully Updated!!",
										null,
										null,
										{ severity: "success" }
									);
								}
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							!parent
								? `${ROOT_URL}/api/Course/addCourseActivity`
								: `${ROOT_URL}/api/Course/addSessionSubActivity`,
							getRequestOptions(
								"POST",
								!parent
									? {
										...data,
										courseId: self.current.id,
										courseSNo: self.current.sNo,
										courseDaysId:
											self.currentDay?.courseDaysId,
										courseDaysSNo:
											self.currentDay?.courseDaysSNo,
									}
									: {
										...data,
										courseId: self.current.id,
										courseSNo: self.current.sNo,
										sessionId: parent?.sessionId,
										sessionSNo: parent?.sessionSNo,
									}
							)
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 400) {
								if (!parent) {
									const activity = courseRecursiveSearch(
										json.activityId,
										"activity",
										self.current?.courseDays
									);
									if (!activity) {
										self.currentDay?.courseActivity.push(
											json
										);
									}
									if (show) {
										self.updateCurrentTreeSelected(
											`activity#${json.activityId}`
										);
										self.updateCurrentTreeNode(
											`activity#${json.activityId}`
										);
										self.updateCurrentTreeParent(null);
										self.updateCurrentTreeExpanded([
											...self.currentTreeData.expanded,
											`day#${self.currentDay?.courseDaysId}`,
										]);
									}
								} else {
									const activity = courseRecursiveSearch(
										json.activityId,
										"activity",
										self.current?.courseDays
									);
									if (!activity) {
										parent.activity.push(json);
									}
									if (show) {
										self.updateCurrentTreeSelected(
											`activity#${json.activityId}`
										);
										self.updateCurrentTreeNode(
											`activity#${json.activityId}`
										);
										self.updateCurrentTreeParent(
											`session#${parent.sessionId}`
										);
										self.updateCurrentTreeExpanded([
											...self.currentTreeData.expanded,
											`day#${self.currentDay?.courseDaysId}`,
										]);
									}
								}
								snackbar.showMessage(
									"Record Successfully Created!!",
									null,
									null,
									{ severity: "success" }
								);
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveSession: flow(function* saveSession(
				session,
				data,
				snackbar,
				parent,
				show,
				notification
			) {
				if (session?.sessionId) {
					try {
						const response = yield fetchApi(
							!parent
								? `${ROOT_URL}/api/Course/updateCourseSession`
								: `${ROOT_URL}/api/Course/updateSubActivitySession`,
							getRequestOptions(
								"POST",
								!parent
									? {
										...session,
										...data,
										courseId: self.current.id,
										courseSNo: self.current.sNo,
										courseDaysId:
											self.currentDay?.courseDaysId,
										courseDaysSNo:
											self.currentDay?.courseDaysSNo,
									}
									: {
										...session,
										...data,
										courseId: self.current.id,
										courseSNo: self.current.sNo,
										activityId: parent.activityId,
										activitySNo: parent.activitySNo,
									}
							)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json?.status !== 0) {
								snackbar.showMessage(json?.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(session, json);
								if (notification) {
									snackbar.showMessage(
										"Record Successfully Updated!!",
										null,
										null,
										{ severity: "success" }
									);
								}
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							!parent
								? `${ROOT_URL}/api/Course/addCourseSession`
								: `${ROOT_URL}/api/Course/addSubActivitySession`,
							getRequestOptions(
								"POST",
								!parent
									? {
										...data,
										courseId: self.current.id,
										courseSNo: self.current.sNo,
										courseDaysId:
											self.currentDay?.courseDaysId,
										courseDaysSNo:
											self.currentDay?.courseDaysSNo,
									}
									: {
										...data,
										courseId: self.current.id,
										courseSNo: self.current.sNo,
										activityId: parent.activityId,
										activitySNo: parent.activitySNo,
									}
							)
						);

						const json = yield response.json();
						if (response.status === 200) {
							if (json.status !== 400) {
								if (!parent) {
									const session = courseRecursiveSearch(
										json.sessionId,
										"session",
										self.current?.courseDays
									);
									if (!session) {
										self.currentDay?.courseSession.push(
											json
										);
									}

									if (show) {
										self.updateCurrentTreeSelected(
											`session#${json.sessionId}`
										);
										self.updateCurrentTreeNode(
											`session#${json.sessionId}`
										);
										self.updateCurrentTreeParent(null);
										self.updateCurrentTreeExpanded([
											...self.currentTreeData.expanded,
											`day#${self.currentDay?.courseDaysId}`,
										]);
									}
								} else {
									const session = courseRecursiveSearch(
										json.sessionId,
										"session",
										self.current?.courseDays
									);
									if (!session) {
										parent.session.push(json);
									}

									if (show) {
										self.updateCurrentTreeSelected(
											`session#${json.sessionId}`
										);
										self.updateCurrentTreeNode(
											`session#${json.sessionId}`
										);
										self.updateCurrentTreeParent(
											`activity#${parent.activityId}`
										);
										self.updateCurrentTreeExpanded([
											...self.currentTreeData.expanded,
											`day#${self.currentDay?.courseDaysId}`,
										]);
									}
								}

								snackbar.showMessage(
									"Record Successfully Created!!",
									null,
									null,
									{ severity: "success" }
								);
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			remove: flow(function* remove(courseId, snackbar) {
				try {
					const course = self.items.find(
						(item) => item.id === courseId
					);

					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteCourseById`,
						getRequestOptions("POST", {
							id: course?.id,
							sNo: course?.sNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(course);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeSession: flow(function* removeSession(session, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteSessionById`,
						getRequestOptions("POST", {
							sessionId: session?.sessionId,
							sessionSNo: session?.sessionSNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						self.updateCurrentTreeSelected(null);
						self.updateCurrentTreeNode(null);
						self.updateCurrentTreeParent(null);
						destroy(session);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeSessionLink: flow(function* removeSessionLink(
				session,
				linkId,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteSessionLinkById`,
						getRequestOptions("POST", {
							sessionLinkId: linkId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(
							session.sessionLink.find(
								(link) => link.sessionLinkId === linkId
							)
						);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeCourseLink: flow(function* removeCourseLink(
				linkId,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteCourseLinkById`,
						getRequestOptions("POST", {
							courseLinkId: linkId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(
							self.current.courseLink.find(
								(link) => link.courseLinkId === linkId
							)
						);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			deleteCoursePreparatorySurvey: flow(
				function* deleteCoursePreparatorySurvey(payload, snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/DeleteCoursePreparatorySurvey`,
							getRequestOptions("POST", payload)
						);
						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							destroy(
								self.current.coursePreparatorySurveys.find(
									(link) => link.surveyId === payload.surveyId
								)
							);
							snackbar.showMessage(
								"Record Successfully Deleted!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),

			deleteCoursePostSurvey: flow(
				function* deleteCoursePostSurvey(payload, snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/DeleteCoursePostSurvey`,
							getRequestOptions("POST", payload)
						);
						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							destroy(
								self.current.coursePostSurveys.find(
									(link) => link.surveyId === payload.surveyId
								)
							);
							snackbar.showMessage(
								"Record Successfully Deleted!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),

			removeDay: flow(function* removeDay(day, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteCourseDaysById`,
						getRequestOptions("POST", {
							courseDaysId: day?.courseDaysId,
							courseDaysSNo: day?.courseDaysSNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						self.updateCurrentTreeSelected(null);
						self.updateCurrentTreeNode(null);
						self.updateCurrentTreeParent(null);
						destroy(day);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeDays: flow(function* removeDays(days, snackbar) {
				if (days.length > 0) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCourseDaysList`,
							getRequestOptions("POST", {
								deleteCourseDays: days,
							})
						);
						const json = yield response.json();
						if (response.status === 200 && json[0].status === 0) {
							self.updateCurrentTreeSelected(null);
							self.updateCurrentTreeNode(null);
							self.updateCurrentTreeParent(null);
							days.forEach((day) => destroy(day));
						} else {
							snackbar.showMessage(json[0].error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeActivity: flow(function* removeActivity(activity, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteActivityById`,
						getRequestOptions("POST", {
							activityId: activity?.activityId,
							activitySNo: activity?.activitySNo,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						self.updateCurrentTreeSelected(null);
						self.updateCurrentTreeNode(null);
						self.updateCurrentTreeParent(null);
						destroy(activity);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeFile: flow(function* removeFile(file, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteSessionUpload`,
						getRequestOptions("POST", {
							uploadId: file.uploadId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(file);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			removeAdditionalQuestionFile: flow(
				function* removeAdditionalQuestionFile(file, snackbar) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteAdditionalQuestionUpload`,
							getRequestOptions("POST", {
								uploadId: file.uploadId,
							})
						);
						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							destroy(file);
							snackbar.showMessage(
								"Record Successfully Deleted!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			),

			removeCourseFile: flow(function* removeCourseFile(file, snackbar) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteCourseUpload`,
						getRequestOptions("POST", {
							uploadId: file.uploadId,
						})
					);
					const json = yield response.json();
					if (response.status === 200 && json.status === 0) {
						destroy(file);
						snackbar.showMessage(
							"Record Successfully Deleted!!",
							null,
							null,
							{ severity: "success" }
						);
					} else {
						snackbar.showMessage(json.error, null, null, {
							severity: "error",
						});
					}
				} catch (e) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			addCoursePreparatorySurvey: flow(
				function* addCoursePreparatorySurvey(
					payload,
					callback = () => { }
				) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/AddUpdateCoursePreparatorySurvey`,
							getRequestOptions("POST", payload)
						);
						const json = yield response.json();

						if (response.status === 200) {
							if (
								self.current.coursePreparatorySurveys.some(
									(survey) =>
										survey.surveyId === payload.surveyId
								)
							) {
								applySnapshot(
									self.current.coursePreparatorySurveys.find(
										(survey) =>
											survey.surveyId === payload.surveyId
									),
									json
								);
							} else {
								self.current.coursePreparatorySurveys.push(
									json
								);
								callback(json);
							}
						} else {
							getEnv(self).notifier.enqueue(json?.error, "error");
						}
					} catch (e) {
						getEnv(self).notifier.enqueue(
							API_ERROR_MESSAGE,
							"error"
						);
					}
				}
			),
			addCoursePostSurvey: flow(function* addCoursePostSurvey(payload, callback = () => { }) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/AddCoursePostSurvey`,
						getRequestOptions("POST", payload)
					);
					const json = yield response.json();

					if (response.status === 200) {
						if (
							self.current.coursePostSurveys.some(
								(survey) =>
									survey.surveyId === payload.surveyId
							)
						) {
							applySnapshot(
								self.current.coursePostSurveys.find(
									(survey) =>
										survey.surveyId === payload.surveyId
								),
								json
							);
						} else {
							self.current.coursePostSurveys.push(
								json
							);
							callback(json);
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(
						API_ERROR_MESSAGE,
						"error"
					);
				}
			}),

			needAssistance: flow(function* needAssistance(payload) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/NeedAssistance`,
						getRequestOptions("POST", payload)
					);
					const json = yield response.json();

					if (response.status === 200) {
						getEnv(self).notifier.enqueue(
							"Request For Assistance Successfully Sent!!",
							"success"
						);
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			updateSessionName(name, session) {
				let courseSession = self.current?.courseSession.find(
					(cs) => cs?.id === session?.id
				);
				courseSession.name = name;
			},
			updateContentType(contentType) {
				self.contentType = contentType;
			},
			updateCurrentItem(id) {
				self.currentId = id;
			},
			updateCurrentDay(id) {
				self.currentDayId = id;
			},
			updateCurrentTreeSelected(selected) {
				self.currentTreeData.selected = selected;
			},
			updateCurrentTreeExpanded(expanded) {
				self.currentTreeData.expanded = expanded;
			},
			updateCurrentTreeNode(node) {
				self.currentTreeData.node = node;
			},
			updateCurrentTreeParent(parent) {
				self.currentTreeData.parent = parent;
			},
			clearSignature() {
				self.items.map((item) => {
					if (item.id === self.currentId && item.additionalQuestion) {
						item.additionalQuestion.signatureURL = null;
					}
					return item;
				});
			},
			clearCourse() {
				self.updateCurrentItem(null);
				self.updateCurrentDay(null);
				self.updateCurrentTreeSelected(null);
				self.updateCurrentTreeExpanded([]);
				self.updateCurrentTreeNode(null);
				self.updateCurrentTreeParent(null);
			},
		};
	});
