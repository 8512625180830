import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { JsonLoadingScreen } from "../components/utils";
import { useStore } from "../hooks";

const VerifyPermissionGrant = ({ children }) => {
	const { auth, coursePresenter } = useStore();
	const location = useLocation();
	const navigate = useNavigate();
	const [permission, setPermission] = useState(null);

	const reRouteChecks = async () => {
		let checkPresenter = false;
		
		if (
			!auth.details?.isLegalDocumentAcknowledged &&
			location.pathname !== "/account/acknowledge"
		) {
			navigate("/account/acknowledge");
		} else if (
			auth.details?.isNewUser &&
			location.pathname !== "/account/profile" &&
			location.pathname !== "/account/acknowledge"
		) {
			navigate("/account/profile");
		} else if (
			location.pathname === "/account/profile" ||
			location.pathname === "/account/notifications" ||
			location.pathname === "/account/settings" ||
			location.pathname === "/account/acknowledge" ||
			location.pathname === "/account/orders-history" ||
			location.pathname === "/manage/checkout" ||
			location.pathname === "/manage/order" ||
			location.pathname === "/zoom" ||
			location.pathname === "/manage/marketactivity-preview" ||
			location.pathname === "/manage/markettrack-preview" ||
			location.pathname === "/courses/view" ||
			location.pathname === "/survey/view" ||
			location.pathname === "/survey/preview"||
			location.pathname === "/manage/courses" ||
			location.pathname === "/tracks/view" ||
			location.pathname === "/manage/tracks/join"
		) {
			setPermission("true");
		} else {
			auth.isPermissionGrant(
				encodeURIComponent(location.pathname),
				(data) => {
					setPermission(`${data}`);
				}
			);
		}
	};

	useEffect(() => {
		reRouteChecks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	return (
		<>
			{permission === "true" && <>{children}</>}
			{permission === "false" && <Navigate to='/error/401' />}
			{permission !== "true" && permission !== "false" && (
				<JsonLoadingScreen type={"Permissions"} />
			)}
		</>
	);
};

const AuthGaurd = ({ children }) => {
	const { auth } = useStore();
	const navigate = useNavigate();

	useEffect(() => {
		if (!Boolean(auth.details)) {
			auth.refreshToken(navigate);
		}
	}, [auth, navigate]);

	return Boolean(auth.details) ? (
		<VerifyPermissionGrant>{children}</VerifyPermissionGrant>
	) : (
		<JsonLoadingScreen type={"Tokens"} />
	);
};

export default observer(AuthGaurd);
